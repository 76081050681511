import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faPuzzlePiece, faMapMarkedAlt, faGlobe, faArrowLeft, faQuestionCircle, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

const NotFound = () => {
    return (
        <main className="flex flex-col items-center justify-center min-h-85vh bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 p-6">
            <div className="text-center">
                <p className="text-4xl font-bold text-indigo-600 dark:text-indigo-400">404</p>
                <h1 className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl">This page does not exist</h1>
                <p className="mt-2 text-base">Sorry, we couldn’t find the page you’re looking for.</p>
            </div>
            <div className="mt-6">
                <h2 className="text-lg font-medium">Popular pages</h2>
                <ul role="list" className="mt-4 space-y-4">
                    <li className="flex items-center space-x-6 ml-[14px]">
                        <div className="relative">
                            <FontAwesomeIcon icon={faQuestionCircle} className="text-indigo-600 dark:text-indigo-400 absolute top-0 left-0" size="sm" />
                            <FontAwesomeIcon icon={faUser} className="text-indigo-600 dark:text-indigo-400 absolute bottom-0 right-0" size="sm" />
                        </div>




                        <div>
                            <h3 className="text-lg font-semibold">
                                <Link to="/quiz" className="text-gray-700 dark:text-gray-400 hover:underline">Geography quiz</Link>
                            </h3>
                            <p className="text-sm text-gray-500 dark:text-gray-400">Test your geography knowledge.</p>
                        </div>
                    </li>
                    <li className="flex items-center space-x-6 ml-[14px]">
                        <div className="relative">
                            <FontAwesomeIcon icon={faQuestionCircle} className="text-indigo-600 dark:text-indigo-400 absolute top-0 left-0" size="sm" />
                            <FontAwesomeIcon icon={faUsers} className="text-indigo-600 dark:text-indigo-400 absolute bottom-0 right-0" size="sm" />
                        </div>




                        <div>
                            <h3 className="text-lg font-semibold">
                                <Link to="/play" className="text-gray-700 dark:text-gray-400 hover:underline">Multiplayer quiz</Link>
                            </h3>
                            <p className="text-sm text-gray-500 dark:text-gray-400">Challenge your friends.</p>
                        </div>
                    </li>
                    <li className="flex items-center space-x-4">

                        <div className="relative">
                        <FontAwesomeIcon icon={faMapMarkedAlt} className="text-indigo-600 dark:text-indigo-400" size="lg" />
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold">
                            <Link to="/map" className="text-gray-700 dark:text-gray-400 hover:underline">Resource maps</Link>
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Explore interactive maps.</p>
                    </div>
                </li>
                    <li className="flex items-center space-x-4">

                        <div className="relative">
                        <FontAwesomeIcon icon={faMap} className="text-indigo-600 dark:text-indigo-400" size="lg" />
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold">
                            <Link to="/solve" className="text-gray-700 dark:text-gray-400 hover:underline">Solve location</Link>
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Guess the location from clues.</p>
                    </div>
                </li>

                </ul>
            </div>
            <div className="mt-8  flex justify-center">
                <Link to="/" className="flex items-center ml-[-15px] text-indigo-600 dark:text-indigo-400 font-semibold tracking-tight hover:text-blue-600 dark:hover:text-blue-300 group">
                    <FontAwesomeIcon className="mr-2 transform transition-transform duration-200 ease-in-out group-hover:-translate-x-2" icon={faArrowLeft} />
                    Back to home
                </Link>
            </div>
        </main>
    );
};

export default NotFound;
