import React, { useEffect } from 'react';
import data from '../data.json';
import {clearMapElements} from "../Map";

const MapStopSigns: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.s && countryData.s.length > 0) {
                    let stopSignWidth = 26;
                    let stopSignHeight = 26;
                    let gap = 5;
                    if (countryData.o === 'Europe') {
                        stopSignWidth = 15;
                        stopSignHeight = 15;
                        gap = 3;
                    }
                    const totalWidth = stopSignWidth * countryData.s.length + gap * (countryData.s.length - 1);
                    countryData.s.forEach((stopSign, index) => {
                        const stopSignImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                        stopSignImg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', stopSign);
                        stopSignImg.setAttribute('width', stopSignWidth.toString());
                        stopSignImg.setAttribute('height', stopSignHeight.toString());
                        const xPosition = path.getBBox().x + path.getBBox().width / 2 - totalWidth / 2 + index * (stopSignWidth + gap);
                        const yPosition = path.getBBox().y + path.getBBox().height / 2 - stopSignHeight / 2;
                        stopSignImg.setAttribute('x', xPosition.toString());
                        stopSignImg.setAttribute('y', yPosition.toString());
                        svg.appendChild(stopSignImg);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapStopSigns;
