import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapMarkerAlt,
    faClock,
    faBan,
    faMap,
    faGripLinesVertical,
    faQuestionCircle,
    faCheck,
    faTimes,
    faPlay,
    faRedo,
    faArrowLeft,
    faUsers,
    faHourglassHalf,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import QuizWorldMap from '../QuizWorldMap';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import { TwitchContext } from '../TwitchAuthManager';
import crosswalksData from '../data.json';
import '../Quiz.css';
import QuizWorldMapMobile from '../QuizWorldMapMobile';
import { Link } from 'react-router-dom';
const TOTAL_QUESTIONS = 10;

const countryNames = crosswalksData.reduce((acc: Record<string, string>, country) => {
    acc[country.i.toUpperCase()] = country.n;
    return acc;
}, {});

const flags = crosswalksData.reduce((acc: Record<string, string>, country) => {
    acc[country.i.toUpperCase()] = country.f;
    return acc;
}, {});

const QuizCrosswalks: React.FC = () => {
    const { twitchConnected, setLoggingIn } = useContext(TwitchContext);
    const [quizStarted, setQuizStarted] = useState(false);
    const [completeQuizStarted, setCompleteQuizStarted] = useState(false);
    const [retryQuiz, setRetryQuiz] = useState(false);
    const [currentRound, setCurrentRound] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [answers, setAnswers] = useState<Array<{ country: string | null, crosswalk: string | null }>>([]);
    const [currentCrosswalk, setCurrentCrosswalk] = useState<string | null>(null);
    const [currentCountry, setCurrentCountry] = useState<string | null>(null);
    const [quizFinished, setQuizFinished] = useState(false);
    const [isAnswering, setIsAnswering] = useState(false);
    const [crosswalkSequence, setCrosswalkSequence] = useState<string[]>([]);
    const [timeLeft, setTimeLeft] = useState(20);
    const [animationKey, setAnimationKey] = useState(0);
    const [showTwitchOptions, setShowTwitchOptions] = useState(false);
    const [connecting, setConnecting] = useState(false);

    const navigate = useNavigate();

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        if (twitchConnected) {
            setShowTwitchOptions(true);
        }
    }, [twitchConnected]);

    useEffect(() => {
        const totalCrosswalks = crosswalksData.reduce((acc, country) => acc + (country.cw ? 1 : 0), 0);
        const totalQuestions = completeQuizStarted ? totalCrosswalks : TOTAL_QUESTIONS;

        if ((quizStarted || completeQuizStarted) && currentRound < totalQuestions) {
            if (retryQuiz) {
                setCurrentCrosswalk(crosswalkSequence[currentRound]);
                setCurrentCountry(crosswalkSequence[currentRound]?.slice(3, 5).toUpperCase() || null);
            } else {
                selectRandomCrosswalk(completeQuizStarted);
            }
            setTimeLeft(20);
            setAnimationKey((prevKey) => prevKey + 1);
        } else if ((quizStarted || completeQuizStarted) && currentRound >= totalQuestions) {
            setQuizFinished(true);
            const quizType = completeQuizStarted ? 'cCrosswalk' : 'sCrosswalk';
            const quizResult = correctAnswersCount;
            submitQuizResult(quizType, quizResult); // Submit the result to Google Forms
        }
    }, [quizStarted, completeQuizStarted, currentRound]);

    useEffect(() => {
        const totalCrosswalks = crosswalksData.reduce((acc, country) => acc + (country.cw ? 1 : 0), 0);
        const totalQuestions = completeQuizStarted ? totalCrosswalks : TOTAL_QUESTIONS;

        if ((quizStarted || completeQuizStarted) && currentRound < totalQuestions) {
            if (timeLeft === 0) {
                if (selectedCountry) {
                    handleAnswer();
                } else {
                    setAnswers([...answers, { country: null, crosswalk: currentCrosswalk }]);
                    setCurrentRound(currentRound + 1);
                    setSelectedCountry(null);
                }
            }
            if (timeLeft > 0) {
                const timerId = setInterval(() => {
                    setTimeLeft(timeLeft - 1);
                }, 1000);
                return () => clearInterval(timerId);
            }
        }
    }, [timeLeft, quizStarted, completeQuizStarted]);

    const selectRandomCrosswalk = (forCompleteQuiz = false) => {
        let crosswalkFound = false;
        const usedCrosswalks = new Set(crosswalkSequence);
        const allCrosswalks = crosswalksData.flatMap(country => country.cw ? [{ crosswalk: country.cw, country: country.i.toUpperCase() }] : []);

        while (!crosswalkFound) {
            const randomIndex = Math.floor(Math.random() * allCrosswalks.length);
            const selectedCrosswalkData = allCrosswalks[randomIndex];

            if (!usedCrosswalks.has(selectedCrosswalkData.crosswalk)) {
                setCurrentCrosswalk(selectedCrosswalkData.crosswalk);
                setCurrentCountry(selectedCrosswalkData.country);
                setCrosswalkSequence((prev) => [...prev, selectedCrosswalkData.crosswalk]);
                crosswalkFound = true;
            }
        }
    };

    const handleStartQuiz = (isCompleteQuiz: boolean = false) => {
        window.scrollTo(0, 0);
        setQuizStarted(!isCompleteQuiz);
        setCompleteQuizStarted(isCompleteQuiz);
        setRetryQuiz(false);
        setCurrentRound(0);
        setSelectedCountry(null);
        setAnswers([]);
        setCrosswalkSequence([]);
        setQuizFinished(false);
        setIsAnswering(false);
    };

    const handleRetryQuiz = (isCompleteQuiz: boolean = false) => {
        window.scrollTo(0, 0);
        setQuizStarted(!isCompleteQuiz);
        setCompleteQuizStarted(isCompleteQuiz);
        setRetryQuiz(true);
        setCurrentRound(0);
        setSelectedCountry(null);
        setAnswers([]);
        setQuizFinished(false);
        setIsAnswering(false);
    };

    const handleSelectCountry = (country: string) => {
        setSelectedCountry(country.toUpperCase());
    };

    const handleAnswer = () => {
        if (isAnswering) return;
        setIsAnswering(true);
        setAnswers([...answers, { country: selectedCountry, crosswalk: currentCrosswalk }]);
        setCurrentRound(currentRound + 1);
        setSelectedCountry(null);
        setIsAnswering(false);
    };

    const handleTwitchStream = () => {
        setShowTwitchOptions((prevShowTwitchOptions) => !prevShowTwitchOptions);
    };

    const handleTwitchConnect = () => {
        setConnecting(true);
        setLoggingIn(true);
        localStorage.setItem('twitchRedirect', window.location.pathname);
        const twitchAuthUrl = `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=${process.env.REACT_APP_TWITCH_ID}&redirect_uri=https://geography.help/auth/twitch/callback&scope=openid chat:read`;
        window.location.href = twitchAuthUrl;
    };

    const handleStartTwitchQuiz = (quizType: 'standard' | 'complete') => {
        if (twitchConnected) {
            navigate(`/quiz/crosswalks/twitch?type=${quizType}`);
        }
    };

    const submitQuizResult = (quizType: string, quizResult: number) => {
        const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSd3huPDp8iyWwSjMn3-q4edrEEsgD0O1jfL-QnXta2Y9qRpmA/formResponse?usp=pp_url&entry.1562507564=${quizType}&entry.1128704523=${quizResult}`;

        fetch(formUrl, {
            method: 'POST',
            mode: 'no-cors'  // no-cors mode prevents any response from being returned
        }).then(() => {
            console.log('Quiz result submitted successfully.');
        }).catch(error => {
            console.error('Error submitting quiz result:', error);
        });
    };

    const correctAnswersCount = answers.filter(answer => {
        const correctCountryCode = crosswalksData.find(country => country.cw === answer.crosswalk)?.i.toUpperCase();
        return answer.country === correctCountryCode;
    }).length;

    const totalCrosswalks = crosswalksData.reduce((acc, country) => acc + (country.cw ? 1 : 0), 0);
    const totalQuestions = completeQuizStarted ? totalCrosswalks : TOTAL_QUESTIONS;

    const circleSize = 'w-4 h-4';
    const fontSize = 'text-[10px] mt-[-1px]';
    const gridCols = 'grid-cols-10';

    return (
        <>
            {!quizStarted && !completeQuizStarted && !quizFinished && (
                <div className="intro-container min-h-[60vh] p-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-center">
                    <div className="w-full max-w-4xl text-center">
                        <div className="mb-1 flex justify-start">
                            <Link to="/quiz" className="flex items-center text-gray-700 dark:text-gray-300 font-semibold tracking-tight hover:text-blue-600 dark:hover:text-blue-300 group">
                                <FontAwesomeIcon className="mr-2 transform transition-transform duration-200 ease-in-out group-hover:-translate-x-2" icon={faArrowLeft} />
                                Explore Quiz Library
                            </Link>
                        </div>
                        <h1 className="text-4xl sm:text-5xl font-extrabold  font-inter tracking-tight text-gray-900 dark:text-white mb-4 text-left">
                            Crosswalk Sign Quiz
                        </h1>
                        <div className="flex flex-wrap">
                            <div className="w-full sm:w-1/2 sm:pr-5 text-left">
                                <p className="mt-2 text-lg font-inter text-gray-700 dark:text-gray-300 mb-4">
                                    Welcome to the Crosswalk Signs Quiz! In each round, you'll see a crosswalk sign image. Your task is to select the country on the world map where this crosswalk sign is found. Good luck!</p>
                                <div className="flex justify-between font-inter text-gray-700 dark:text-gray-300">
                                    <div className="flex-1">
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faGripLinesVertical} /> 27 crosswalk signs</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 27 countries</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faUser} /> 10 attempts</p>
                                    </div>
                                    <div className="flex-1">
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} /> 10 questions</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited rounds</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</p>
                                    </div>
                                </div>
                                <div className="flex justify-between space-x-4 w-full sm:mt-5 mt-2">
                                    <button
                                        className="flex-grow bg-[#0f172a] text-white dark:bg-white dark:text-black hover:bg-transparent hover:text-black dark:hover:bg-transparent dark:hover:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center"
                                        onClick={() => handleStartQuiz(false)}
                                    >
                                        <FontAwesomeIcon icon={faPlay} className="mr-1  font-inter" /> Start Quiz
                                    </button>
                                    <button
                                        className="flex-grow text-black dark:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                        onClick={() => handleStartQuiz(true)}
                                    >
                                        <FontAwesomeIcon icon={faMap} className="mr-1  font-inter" /> Start Complete Quiz
                                    </button>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2 sm:pl-5 text-left sm:mt-0 mt-6">
                                <p className="mt-2 text-lg font-inter text-gray-700 dark:text-gray-300 mb-4">
                                    Play with friends! Enjoy multiplayer options and challenge your friends.
                                </p>
                                <div className="flex w-full mb-4 space-x-4">
                                    <button
                                        className="w-1/2 text-black dark:text-white font-bold pt-1 pb-2 px-2 md:pt-2 md:pb-3 md:px-4 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                        onClick={() => navigate('/play')}
                                    >
                                        <FontAwesomeIcon icon={faUsers} className="mr-1" /> Create a Room
                                        <div>
                                            <p className="text-xs">Invite your friends to join the quiz without an account</p>
                                        </div>
                                    </button>

                                    <button
                                        className={`w-1/2 font-bold pt-1 pb-2 px-1 md:pt-2 md:pb-3 md:px-4 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center ${
                                            showTwitchOptions
                                                ? 'dark:bg-white dark:text-black bg-[#0f172a] text-white'
                                                : 'hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black'
                                        }`}
                                        onClick={handleTwitchStream}
                                    >
                                        <FontAwesomeIcon icon={faTwitch} className="mr-1  font-inter text-lg" /> Twitch Stream
                                        <div>
                                            <p className="text-xs">Connect your Twitch account and play with your chat</p>
                                        </div>
                                    </button>

                                </div>
                                {showTwitchOptions && (
                                    <div className="w-full">
                                        {twitchConnected ? (
                                            <div className="flex justify-between space-x-4 w-full sm:mt-5 mt-2">
                                                <button
                                                    className="flex-grow text-green-600 font-bold py-2 md:py-3 rounded-full text-sm md:text-base border-2 border-solid border-green-600 text-center cursor-default"
                                                    disabled
                                                >
                                                    <FontAwesomeIcon icon={faTwitch} className="mr-2" />Twitch Account Connected<FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                </button>
                                            </div>
                                        ) : connecting ? (
                                            <div className="flex justify-between space-x-4 w-full sm:mt-5 mt-2">
                                                <button
                                                    className="flex-grow text-black dark:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                                    disabled
                                                >
                                                    <FontAwesomeIcon icon={faTwitch} className="mr-2" />Logging in...
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="flex justify-between space-x-4 w-full sm:mt-5 mt-2">
                                                <button
                                                    className="flex-grow text-black dark:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                                    onClick={handleTwitchConnect}
                                                >
                                                    <FontAwesomeIcon icon={faTwitch} className="mr-2" />Connect Twitch Account
                                                </button>
                                            </div>
                                        )}
                                        <div className="flex w-full  mt-5 space-x-4">
                                            <button
                                                className={`w-1/2 h-[52px] text-black dark:text-white font-bold pt-1 pb-1 px-2 md:pt-1 md:pb-2 md:px-4 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center ${!twitchConnected ? 'cursor-not-allowed opacity-50' : 'hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black'}`}
                                                onClick={() => handleStartTwitchQuiz('standard')}
                                                disabled={!twitchConnected}
                                            >
                                                <FontAwesomeIcon icon={faPlay} className="mr-1" /> Start Quiz
                                                <div>
                                                    <p className="text-xs mt-[-3px]">Identify 20 signs with chat</p>
                                                </div>
                                            </button>
                                            <button
                                                className={`w-1/2 h-[52px] text-black dark:text-white font-bold pt-1 pb-1 px-1 md:pt-1 md:pb-2 md:px-1 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center ${!twitchConnected ? 'cursor-not-allowed opacity-50' : 'hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black'}`}
                                                onClick={() => handleStartTwitchQuiz('complete')}
                                                disabled={!twitchConnected}
                                            >
                                                <FontAwesomeIcon icon={faMap} className="mr-1" /> Start Complete Quiz
                                                <div>
                                                    <p className="text-xs mt-[-3px]">Identify all signs with chat</p>
                                                </div>
                                            </button>
                                        </div>


                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {(quizStarted || completeQuizStarted) && !quizFinished && (
                <>
                    {isMobile ? (
                        <div className="quiz-container-mobile overflow-hidden bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-between md:hidden">
                            <div className="quiz-content-mobile w-full max-w-screen-2xl flex-grow flex flex-col justify-center items-center relative">
                                <div className="quiz-map w-full relative" style={{ maxWidth: '100%', maxHeight: '75vh', overflow: 'hidden' }}>
                                    <QuizWorldMapMobile onSelectCountry={handleSelectCountry} selectedCountry={selectedCountry} />

                                    {currentCrosswalk && (
                                        <div className="absolute bottom-0 left-0 mb-0 ml-2" style={{ padding: '10px', pointerEvents: 'none' }}>
                                            <img
                                                src={process.env.PUBLIC_URL + currentCrosswalk}
                                                alt="Crosswalk"
                                                className="rounded-lg"
                                                style={{ width: '200px', height: '100px', objectFit: 'contain', pointerEvents: 'none' }}
                                            />
                                        </div>


                                    )}
                                </div>
                            </div>
                            <div className="quiz-panel-mobile w-full p-2 z-50 bg-white dark:bg-[#121212]">

                                <div className="w-full relative flex justify-between pl-8 pr-8 mt-[-1px] h-[40px] items-center">
                                    <div className="text-left">
                                        {selectedCountry && (
                                            <>
                                                <p className="text-xs font-inter text-gray-500 font-medium">Selected country:</p>
                                                <p className="text-2xl mt-[-8px] tracking-tight font-inter font-bold bg-clip-text">{countryNames[selectedCountry]}</p>
                                            </>
                                        )}
                                    </div>

                                    {/* Centered Timer */}
                                    <div className="absolute left-1/2 transform -translate-x-1/2 text-lg font-bold font-inter flex items-center space-x-8">
                                        <div className="mr-5">
                                            <FontAwesomeIcon icon={faHourglassHalf} className="mr-1 text-lg" />
                                            <span className="inline-block w-[30px] text-center tabular-nums">{`00:${timeLeft.toString().padStart(2, '0')}`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center">
                                        <FontAwesomeIcon icon={faQuestionCircle} className="mr-1 text-lg" />
                                        <span className="inline-block font-inter font-bold text-lg text-center tabular-nums">{`${currentRound + 1} / ${totalQuestions}`}</span>
                                    </div>
                                </div>

                                <div className="flex justify-between pl-8 pr-8 space-x-4 w-full mt-2">
                                    <button
                                        className={`flex-grow font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid text-center
                                ${selectedCountry ?
                                            'bg-[#0f172a] dark:bg-white text-white dark:text-black border-[#0f172a] dark:border-white cursor-pointer' :
                                            'text-black dark:text-white border-[#0f172a] dark:border-white cursor-default'
                                        }`}
                                        onClick={handleAnswer}
                                        disabled={isAnswering || !selectedCountry}
                                    >
                                        <FontAwesomeIcon icon={faCheck} className="mr-1" /> {currentRound < totalQuestions - 1 ? 'Answer' : 'End Quiz'}
                                    </button>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <div className="quiz-container-computer hidden md:flex min-h-[86vh] p-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 items-center justify-center">
                            <div className="quiz-content w-full max-w-screen-2xl mx-auto flex flex-row justify-between items-start max-w-[1000px]">
                                <div className="quiz-map flex-grow p-2">
                                    <QuizWorldMap onSelectCountry={handleSelectCountry} selectedCountry={selectedCountry} />
                                </div>
                                <div className="quiz-panel-computer flex-shrink-0 w-[200px] p-2 flex flex-col items-start justify-center">
                                    {currentCrosswalk && (
                                        <div className="flex items-center h-[200px] justify-center">
                                            <img
                                                src={process.env.PUBLIC_URL + currentCrosswalk}
                                                alt="Crosswalk"
                                                className="w-full h-full object-contain rounded-lg mb-4"
                                            />
                                        </div>

                                    )}
                                    <div className="w-full mb-1 mt-1 text-left h-[80px]">
                                        {selectedCountry && (
                                            <>
                                                <p className="text-lg">Selected country:</p>
                                                <p className="text-3xl font-bold">{countryNames[selectedCountry]}</p>
                                            </>
                                        )}
                                    </div>
                                    <div className="flex justify-between space-x-4 w-full">
                                        <button
                                            className={`flex-grow text-black dark:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center ${!selectedCountry ? 'cursor-default' : 'hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black'}`}
                                            onClick={handleAnswer}
                                            disabled={isAnswering || !selectedCountry}
                                        >
                                            <FontAwesomeIcon icon={faCheck} className="mr-1" /> {currentRound < totalQuestions - 1 ? 'Answer' : 'End Quiz'}
                                        </button>
                                    </div>
                                    <div className="w-full pt-2 mt-1 text-center text-xl font-bold relative flex items-center justify-center">
                                        <svg key={animationKey} className="absolute inset-0 w-full h-16">
                                            <rect
                                                className={`timer-rect ${timeLeft <= 10 ? 'animate-pulse-red' : 'default-stroke'}`}
                                                x="2"
                                                y="6"
                                                width="180"
                                                height="30"
                                                rx="15"
                                                ry="15"
                                                pathLength="283"
                                                strokeDasharray="283"
                                                strokeDashoffset="50"
                                            />
                                        </svg>
                                        <span>{`00:${timeLeft.toString().padStart(2, '0')}`}</span>
                                    </div>
                                    <div className={`w-full mt-3 grid ${gridCols} gap-x-2 gap-y-1`}>
                                        {Array.from({ length: totalQuestions }, (_, i) => (
                                            <div
                                                key={i}
                                                className={`${circleSize} rounded-full border-2 flex items-center justify-center ${fontSize} ${i === currentRound ? 'animate-pulse' : ''} ${i < currentRound ? (answers[i]?.country ? 'border-[#0f172a] dark:border-white text-[#0f172a] dark:text-white' : 'border-gray-400 text-gray-400') : (i === currentRound ? 'border-[#0f172a] dark:border-white text-[#0f172a] dark:text-white animate-pulse' : 'border-gray-400 text-gray-400')}`}
                                            >
                                                {i + 1}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {quizFinished && (
                <div className="results-container min-h-[60vh] p-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-center">
                    <div className="w-full max-w-screen-lg mx-auto">
                        <div className="flex justify-between items-center mb-6">
                            <h1 className="text-4xl sm:text-5xl font-bold font-inter text-left">{completeQuizStarted ? 'Complete Quiz Results' : 'Results'}</h1>
                            <h1 className="text-4xl sm:text-5xl font-bold font-inter text-right">{correctAnswersCount} / {totalQuestions}</h1>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                            {answers.map((answer, index) => {
                                const correctCountryCode = crosswalksData.find(country => country.cw === answer.crosswalk)?.i.toUpperCase();
                                const isCorrect = answer.country === correctCountryCode;
                                const correctCountryFlag = flags[correctCountryCode || ''];
                                const userCountryFlag = answer.country ? flags[answer.country || ''] : null;

                                return (
                                    <div key={index} className={`p-2 rounded-3xl border ${isCorrect ? 'bg-green-200' : 'bg-red-200'} ${isCorrect ? 'border-green-400 dark:border-green-600' : 'border-red-400 dark:border-red-600'} bg-opacity-20`}>
                                        {answer.crosswalk && (
                                            <div className="flex items-center h-[120px] justify-center">
                                                <img
                                                    src={process.env.PUBLIC_URL + answer.crosswalk}
                                                    alt="Crosswalk"
                                                    className="w-full rounded-lg mb-2 w-full"
                                                    style={{ maxHeight: '100px', objectFit: 'contain' }}
                                                />
                                            </div>
                                        )}
                                        <div className="flex items-center justify-center">
                                            {correctCountryFlag && (
                                                <img
                                                    src={process.env.PUBLIC_URL + correctCountryFlag}
                                                    alt="Flag"
                                                    className="mr-1 rounded"
                                                    style={{ width: '18px', height: 'auto', maxHeight: '18px' }}
                                                />
                                            )}
                                            <p className="text-center font-bold">{countryNames[correctCountryCode || '']}</p>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            {userCountryFlag ? (
                                                <img
                                                    src={process.env.PUBLIC_URL + userCountryFlag}
                                                    alt="Flag"
                                                    className="mr-1 rounded"
                                                    style={{ width: '18px', height: 'auto', maxHeight: '18px' }}
                                                />
                                            ) : (
                                                <p className="mr-1"></p>
                                            )}
                                            <p className="text-center">
                                                {answer.country ? countryNames[answer.country] : 'None'} {isCorrect ? <FontAwesomeIcon className="text-green-500" icon={faCheck} /> : <FontAwesomeIcon className="text-red-500" icon={faTimes} />}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-[#0f172a] text-white dark:bg-white dark:text-black hover:bg-transparent hover:text-black dark:hover:bg-transparent dark:hover:text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center"
                                onClick={() => handleStartQuiz(completeQuizStarted)}
                            >
                                <FontAwesomeIcon icon={faPlay} className="mr-1" /> Start New {completeQuizStarted ? 'Complete Quiz' : 'Quiz'}
                            </button>
                            <button
                                className="text-black dark:text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                onClick={() => handleRetryQuiz(completeQuizStarted)}
                            >
                                <FontAwesomeIcon icon={faRedo} className="mr-1" /> Retry this {completeQuizStarted ? 'complete' : ''} quiz
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default QuizCrosswalks;
