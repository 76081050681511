import React, { useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDarkMode } from './DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { TwitchContext } from './TwitchAuthManager';
import { TwitchUserPopup } from './TwitchAuthManager';

const DarkModeToggle: React.FC = () => {
    const { isDarkMode, toggleDarkMode } = useDarkMode();

    return (
        <div
            role="checkbox"
            aria-checked={isDarkMode ? 'true' : 'false'}
            tabIndex={0}
            onKeyDown={({ key }) => key === 'Enter' && toggleDarkMode()}
            onClick={toggleDarkMode}
            className={`cursor-pointer w-11 h-5 bg-blue-600 rounded-full relative px-1.5 flex items-center ${
                isDarkMode ? '' : 'justify-end'
            }`}
        >
            <div
                className={`w-4 h-4 rounded-full absolute transform duration-200 ease-out bg-white left-0.5 ${
                    isDarkMode ? 'translate-x-6' : 'translate-x-0'
                }`}
            />
            {isDarkMode ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path
                        fillRule="evenodd"
                        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a 1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            )}
        </div>
    );
};

const Nav: React.FC = () => {
    const location = useLocation();
    const isActiveQuiz = location.pathname.startsWith('/quiz');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
    const { twitchUser } = useContext(TwitchContext);



    const toggleUserPopup = () => setIsUserPopupOpen(!isUserPopupOpen);

    return (
        <nav className="bg-white dark:bg-[#121212] relative">
            {/* For smaller screens */}
            <div className="relative top-0 left-0 pt-3 pb-1 pl-6 pr-4 lg:hidden mb-[-50px]">
                <div className="flex items-center justify-between  mb-2">
                    {/* Group image and GeographyHelp together */}
                    <div className="flex items-center select-none">
                        <img
                            src="/g.webp"
                            alt="Geography Icon"
                            className="w-6 h-6 mr-2 pointer-events-none"
                            draggable="false"
                        />
                        <div className="ml-2 text-2xl bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient">
                            <span className="font-inter font-extrabold">Geography</span>
                            <span className="font-inter font-thin ml-[1px]">Help</span>
                        </div>
                    </div>


                    {/* Dark mode toggle positioned on the far right */}
                    <div className="mr-3 transform scale-75">
                        <DarkModeToggle />
                    </div>
                </div>


                {/* Navigation buttons aligned in a single line under the "Geography Help" text */}
                <div className="navigation-buttons-for-smaller-screens pr-3 flex flex-wrap mt-2 w-full justify-between sm:space-x-8 md:w-auto md:justify-start">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        Home
                    </NavLink>

                    <NavLink
                        to="/map"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        Maps
                    </NavLink>

                    <NavLink
                        to="/quiz"
                        className={({ isActive }) =>
                            `nav-link ${isActive || isActiveQuiz ? 'active' : ''}`
                        }
                    >
                        Quiz
                    </NavLink>

                    <NavLink
                        to="/solve"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        Solve
                    </NavLink>

                    <NavLink
                        to="/about"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        About
                    </NavLink>
                </div>

            </div>


            <div className="container mx-auto flex items-center justify-between py-6 px-4 lg:px-8">
                {/* For larger screens */}
                <div className="hidden lg:flex flex-1 mt-[-10px] items-center justify-center">
                    <img
                        src="/g.webp"
                        alt="Geography Icon"
                        className="w-7 h-7 mr-2"
                    />
                    <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient">
                        <span className="font-inter font-extrabold">Geography</span>
                        <span className="font-inter font-thin ml-[1px]">Help</span>
                    </div>
                </div>
                <div className="hidden lg:flex space-x-8">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        Home
                    </NavLink>

                    <NavLink
                        to="/map"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        Maps
                    </NavLink>
                    <NavLink
                        to="/quiz"
                        className={({ isActive }) =>
                            `nav-link ${isActive || isActiveQuiz ? 'active' : ''}`
                        }
                    >
                        Quiz
                    </NavLink>
                    <NavLink
                        to="/solve"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        Solve
                    </NavLink>
                    {/*
                    <NavLink
                        to="/play"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        Multiplayer
                    </NavLink>
                    */}
                    <NavLink
                        to="/about"
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        About
                    </NavLink>

                </div>
                {/* Show DarkModeToggle only on larger screens */}
                <div className="hidden lg:flex flex-1 mt-[-10px] items-center justify-end lg:justify-center">
                    <DarkModeToggle />
                    {twitchUser && (
                        <div className="relative ml-4">
                            <FontAwesomeIcon
                                icon={faUser}
                                className="cursor-pointer text-xl"
                                onClick={toggleUserPopup}
                            />
                            {isUserPopupOpen && <TwitchUserPopup onClose={toggleUserPopup} />}
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Nav;
