import React, { useEffect } from 'react';
import data from '../data.json';
import {clearMapElements} from "../Map";

const MapRoadLines: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.rl && countryData.rl.length > 0) {
                    let roadLineWidth = 30;
                    let roadLineHeight = 20;
                    let gap = 2;
                    if (countryData.o === 'Europe') {
                        roadLineWidth = 20;
                        roadLineHeight = 10;
                        gap = 1;
                    }

                    const totalWidth = roadLineWidth * countryData.rl.length + gap * (countryData.rl.length - 1);
                    countryData.rl.forEach((roadLine, index) => {
                        const roadLineImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                        roadLineImg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', roadLine);
                        roadLineImg.setAttribute('width', roadLineWidth.toString());
                        roadLineImg.setAttribute('height', roadLineHeight.toString());
                        const xPosition = path.getBBox().x + path.getBBox().width / 2 - totalWidth / 2 + index * (roadLineWidth + gap);
                        const yPosition = path.getBBox().y + path.getBBox().height / 2 - roadLineHeight / 2;
                        roadLineImg.setAttribute('x', xPosition.toString());
                        roadLineImg.setAttribute('y', yPosition.toString());
                        svg.appendChild(roadLineImg);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapRoadLines;
