import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleHalfStroke,
    faCircleQuestion,
    faTape,
    faTruckPickup,
    faCarOn,
    faCircleNotch,
    faBroadcastTower,
    faArrowDown,
    faEyeSlash,
    faBan, faRedo, faTools,
} from '@fortawesome/free-solid-svg-icons';

import { faCircleDot, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import countryData from './data.json';

import './Solve.css';
import alphabetData from './solveAlphabet.json';

interface AlphabetData {
    i: string;
    a: string;
}

interface CountryData {
    i: string;
    f: string;
    n: string;
    cg: number[];
    cl: string[];
    rl: string[];
    p: string[];
    cw: string;
    b: string[];
    s: string[];
    am?: string[];
    h: string;
}

const metaTagConfig: { [key: string]: { background: string; icon: any; text: string } } = {
    bt: { background: '#2c3e50', icon: faTape, text: 'Black tape' },
    sn: { background: '#8e44ad', icon: faTruckPickup, text: 'Snorkel' },
    fcn: { background: '#c0392b', icon: faCarOn, text: 'Follow car (Nigeria)' },
    sr: { background: '#00BFFF', icon: faCircleNotch, text: 'Sky rifts' },
    fct: { background: '#c0392b', icon: faCarOn, text: 'Follow car (Tunisia)' },
    av: { background: '#d35400', icon: faBroadcastTower, text: 'Google car - antenna visible' },
    lc: { background: '#f39c12', icon: faArrowDown, text: 'Low placed camera' },
    fpa: { background: '#e759d6', icon: faCircleDot, text: 'Cam artifact - pink anomaly' },
    bb: { background: '#7f8c8d', icon: faEyeSlash, text: 'Abundance of blurred buildings' },
    na: { background: '#2c3e50', icon: faBan, text: 'No antenna' },
};

const roadImages = [
    { id: 'odyiw', src: '/rl/odyiw.webp', alt: 'Outer Yellow Inner White' },
    { id: 'owity', src: '/rl/owity.webp', alt: 'Outer White Inner Yellow' },
    { id: 'owiw', src: '/rl/owiw.webp', alt: 'Outer White Inner White' },
    { id: 'owiy', src: '/rl/owiy.webp', alt: 'Outer White Inner Yellow' },
    { id: 'oyiw', src: '/rl/oyiw.webp', alt: 'Outer Yellow Inner White' },
    { id: 'oriw', src: '/rl/RoadsOuterRedInnerWhite oriw no matches.webp', alt: 'Outer Red Inner White' },
    { id: 'oyiy', src: '/rl/RoadsOuterYellowInnerYellow oyiy no matches.webp', alt: 'Outer Yellow Inner Yellow' },
];

const Solve: React.FC = () => {
    const [countries, setCountries] = useState<CountryData[]>([]);
    const [visibleCountries, setVisibleCountries] = useState<CountryData[]>([]);
    const [activeGen, setActiveGen] = useState<number | null>(null);
    const [activeCarType, setActiveCarType] = useState<string | null>(null);
    const [activeHemisphere, setActiveHemisphere] = useState<string | null>(null);
    const [activeColors, setActiveColors] = useState<string[]>([]);
    const [activeRoadType, setActiveRoadType] = useState<string | null>(null);
    const [selectedMetaTags, setSelectedMetaTags] = useState<string[]>([]);
    const [activeScript, setActiveScript] = useState<string | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.title = 'Solve | GeographyHelp';
    }, []);

    useEffect(() => {
        setCountries(countryData);
        setVisibleCountries(countryData.slice(0, 10));
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const bottomReached = containerRef.current.getBoundingClientRect().bottom <= window.innerHeight;
                if (bottomReached && visibleCountries.length < countries.length && visibleCountries.length < 20) {
                    setVisibleCountries((prev) => countries.slice(0, Math.min(prev.length + 10, 20)));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [countries, visibleCountries]);


    const handleGenButtonClick = (gen: number) => {
        setActiveGen(activeGen === gen ? null : gen);
    };

    const handleCarTypeButtonClick = (type: string) => {
        setActiveCarType(activeCarType === type ? null : type);
    };

    const handleHemisphereButtonClick = (hemisphere: string) => {
        let hemisphereCode: string | null;
        if (hemisphere === 'Northern') {
            hemisphereCode = 'n';
        } else if (hemisphere === 'Southern') {
            hemisphereCode = 's';
        } else {
            hemisphereCode = null; // For "Uncertain"
        }
        setActiveHemisphere(activeHemisphere === hemisphereCode ? null : hemisphereCode);
    };


    const handleColorButtonClick = (color: string) => {
        if (activeColors.includes(color)) {
            setActiveColors(activeColors.filter((c) => c !== color));
        } else {
            setActiveColors([...activeColors, color]);
        }
    };

    const handleRoadTypeButtonClick = (roadType: string) => {
        setActiveRoadType(activeRoadType === roadType ? null : roadType);
    };

    const handleMetaTagButtonClick = (tag: string) => {
        if (selectedMetaTags.includes(tag)) {
            setSelectedMetaTags(selectedMetaTags.filter((t) => t !== tag));
        } else {
            setSelectedMetaTags([...selectedMetaTags, tag]);
        }
    };

    const handleScriptButtonClick = (script: string) => {
        setActiveScript(activeScript === script ? null : script);
    };

    const handleReset = () => {
        setActiveGen(null);
        setActiveCarType(null);
        setActiveHemisphere(null);
        setActiveColors([]);
        setActiveRoadType(null);
        setSelectedMetaTags([]);
        setActiveScript(null);
    };

    const hasSelectedThreeCategories = [
        activeGen,
        activeCarType,
        activeHemisphere,
        activeColors.length > 0 ? activeColors : null,
        activeRoadType,
        selectedMetaTags.length > 0 ? selectedMetaTags : null,
        activeScript,
    ].filter((item) => item !== null).length >= 3;
    const pointsConfig: { [key: string]: number } = {
        gen: 10,         // Default points for Camera Gen
        carType: 10,     // Default points for Car Type
        hemisphere: 20,  // Default points for Hemisphere
        color: 10,       // Default points for Bollard Color
        roadType: 10,    // Default points for Road Type
        metaTag: 10,     // Default points for Meta Tag
        script: 10,      // Default points for Alphabet
    };

    const calculatePercentage = (country: CountryData): number => {
        let points = 0;
        let maxPoints = 0;

        // Check for Camera Gen
        if (activeGen) {
            maxPoints += pointsConfig.gen;
            if (country.cg.includes(activeGen)) points += pointsConfig.gen;
        }

        // Check for Car Type
        if (activeCarType) {
            maxPoints += pointsConfig.carType;
            if (country.cl.includes(activeCarType)) points += pointsConfig.carType;
        }

// Check for Hemisphere
        if (activeHemisphere) {
            maxPoints += pointsConfig.hemisphere;
            if (country.h === activeHemisphere) points += pointsConfig.hemisphere;
        }


        // Check for Bollard Colors
        if (activeColors.length > 0) {
            maxPoints += pointsConfig.color;
            if (activeColors.some((color) => country.b.includes(color))) points += pointsConfig.color;
        }

        // Check for Road Type
        if (activeRoadType) {
            maxPoints += pointsConfig.roadType;
            if (country.rl.includes(activeRoadType)) points += pointsConfig.roadType;
        }

        // Check for Meta Tags
        if (selectedMetaTags.length > 0) {
            maxPoints += pointsConfig.metaTag;
            if (selectedMetaTags.some((tag) => country.am?.includes(tag))) points += pointsConfig.metaTag;
        }

        // Check for Alphabet
        if (activeScript) {
            maxPoints += pointsConfig.script;
            const countryAlphabet = (alphabetData as AlphabetData[]).find((entry) => entry.i === country.i)?.a;
            if (countryAlphabet === activeScript) points += pointsConfig.script;
        }

        const rawPercentage = (points / maxPoints) * 100;
        return Math.floor(rawPercentage);
    };

    const calculatePercentageColor = (percentage: number): string => {
        if (percentage >= 75) return '#00c853';
        if (percentage >= 50) return '#ffeb3b';
        if (percentage >= 25) return '#ff9800';
        return '#d50000';
    };

    const sortedCountries = countries
        .map((country) => ({
            ...country,
            percentage: calculatePercentage(country),
        }))
        .sort((a, b) => b.percentage - a.percentage);

    const renderGenButton = (gen: number) => {
        let fillColor = '';
        switch (gen) {
            case 1:
                fillColor = 'orangered';
                break;
            case 2:
                fillColor = 'orange';
                break;
            case 3:
                fillColor = 'yellowgreen';
                break;
            case 4:
                fillColor = 'lawngreen';
                break;
        }

        const isActive = activeGen === gen;

        return (
            <button
                key={gen}
                onClick={() => handleGenButtonClick(gen)}
                className={`pl-1 pr-1 h-[30px] font-bold font-inter rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid text-center mx-0.5 ${
                    isActive
                        ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-black'
                        : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                }`}
            >
                <svg width={18} height={18}>
                    <circle cx={9} cy={9} r={9} fill={fillColor} />
                    <text
                        x="50%"
                        y={9 + 5}
                        textAnchor="middle"
                        fontSize={15}
                        fill="black"
                        fontFamily="Inter, sans-serif"
                        fontWeight="bold"
                    >
                        {gen}
                    </text>
                </svg>
            </button>
        );
    };

    const renderCarTypeButton = (type: string) => {
        const isActive = activeCarType === type;

        if (type === 'Bars') {
            return (
                <button
                    key={type}
                    onClick={() => handleCarTypeButtonClick(type)}
                    className={`p-0.5 rounded-full transition-colors duration-300  border-2 border-solid ${
                        isActive
                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-black'
                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                    }`}
                >
                    <svg width={14} height={14}>
                        <rect
                            x={0}
                            y={4}
                            width={18}
                            height={7}
                            fill={isActive ? 'white' : 'black'}
                            className={isActive ? 'dark:fill-black' : 'dark:fill-white'}
                        />
                        <rect
                            x={5}
                            y={0}
                            width={2}
                            height={15}
                            fill={isActive ? 'white' : 'black'}
                            className={isActive ? 'dark:fill-black' : 'dark:fill-white'}
                        />
                        <rect
                            x={9}
                            y={0}
                            width={2}
                            height={15}
                            fill={isActive ? 'white' : 'black'}
                            className={isActive ? 'dark:fill-black' : 'dark:fill-white'}
                        />
                    </svg>
                </button>
            );
        } else {
            let fillColor = '';
            switch (type) {
                case 'b':
                    fillColor = 'black';
                    break;
                case 'bl':
                    fillColor = 'blue';
                    break;
                case 'w':
                    fillColor = 'white';
                    break;
                case 'r':
                    fillColor = 'red';
                    break;
                case 'gr':
                    fillColor = 'grey';
                    break;
                case 'bw':
                    return (
                        <button
                            key={type}
                            onClick={() => handleCarTypeButtonClick(type)}
                            className={`p=0.5 rounded-full transition-colors duration-300 border-2 border-solid ${
                                isActive
                                    ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-black'
                                    : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                            }`}
                        >
                            <svg width={16} height={16}>
                                <defs>
                                    <linearGradient id={`bwGradient${type}`} x1="0%" y1="0%" x2="100%">
                                        <stop offset="50%" style={{ stopColor: 'black', stopOpacity: 1 }} />
                                        <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 1 }} />
                                    </linearGradient>
                                </defs>
                                <circle
                                    cx={10}
                                    cy={10}
                                    r={8}
                                    fill={`url(#bwGradient${type})`}
                                    stroke={isActive ? 'white' : 'black'}
                                    className={isActive ? 'dark:stroke-black' : 'dark:stroke-white'}
                                    strokeWidth="1"
                                />
                            </svg>
                        </button>
                    );
                default:
                    fillColor = 'grey';
            }

            return (
                <button
                    key={type}
                    onClick={() => handleCarTypeButtonClick(type)}
                    className={`p-0.5 rounded-full transition-colors duration-300 border-2 border-solid ${
                        isActive
                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-black'
                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                    }`}
                >
                    <svg width={20} height={20}>
                        <circle
                            cx={10}
                            cy={10}
                            r={8}
                            fill={fillColor}
                            stroke={isActive ? 'white' : 'black'}
                            className={isActive ? 'dark:stroke-black' : 'dark:stroke-white'}
                            strokeWidth="1"
                        />
                    </svg>
                </button>
            );
        }
    };

    const renderRoadTypeButton = (road: { id: string; src: string; alt: string }) => {
        const isActive = activeRoadType === road.id;

        return (
            <button
                key={road.id}
                onClick={() => handleRoadTypeButtonClick(road.id)}
                className={`h-[30px] w-[60px] font-bold font-inter rounded-lg transition-colors duration-300 border-2 border-solid  ${
                    isActive
                        ? 'bg-[#0f172a] border-[#0f172a] dark:bg-white dark:border-black'
                        : 'bg-transparent border-transparent hover:border-[#0f172a] dark:hover:border-white'
                }`}
            >
                <img
                    src={road.src}
                    alt={road.alt}
                    style={{ height: '24px', width: 'auto', margin: 'auto' }}
                />
            </button>
        );
    };

    const renderHemisphereButton = (hemisphere: string, icon: JSX.Element) => {
        let hemisphereCode: string | null = null;
        if (hemisphere === 'Northern') {
            hemisphereCode = 'n';
        } else if (hemisphere === 'Southern') {
            hemisphereCode = 's';
        }

        const isActive = activeHemisphere === hemisphereCode;

        return (
            <button
                key={hemisphere}
                onClick={() => handleHemisphereButtonClick(hemisphere)}
                className={`px-1 py-0 text-center transition-colors rounded-full duration-300 border-2 border-solid ${
                    isActive
                        ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-black'
                        : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                }`}
            >
                <div className="flex items-center">
                    {icon}
                    <span className="ml-1 text-[13px] font-inter">{hemisphere}</span>
                </div>
            </button>
        );
    };


    const renderColorButton = (color: string, fillColor: string) => {
        const isActive = activeColors.includes(color);

        return (
            <button
                key={color}
                onClick={() => handleColorButtonClick(color)}
                className={`p-0.5  rounded-full transition-colors duration-300  border-2 border-solid ${
                    isActive
                        ? 'bg-[#0f172a]  border-[#0f172a] dark:border-black'
                        : 'bg-transparent border-transparent hover:border-[#0f172a] dark:hover:border-white'
                }`}
            >
                <svg width={20} height={20}>
                    <circle
                        cx={10}
                        cy={10}
                        r={8}
                        fill={fillColor}
                        stroke={isActive ? 'white' : 'black'}
                        className={isActive ? 'dark:stroke-black' : 'dark:stroke-white'}
                        strokeWidth="1"
                    />
                </svg>
            </button>
        );
    };

    const renderScriptButton = (script: string) => {
        const isActive = activeScript === script;

        return (
            <button
                key={script}
                onClick={() => handleScriptButtonClick(script)}
                className={`px-1 py-0 text-center text-[13px]   font-inter rounded-full transition-colors duration-300  border-2 border-solid  ${
                    isActive
                        ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-black'
                        : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                }`}
            >
                {script}
            </button>
        );
    };

    const renderMetaTagButton = (tag: string) => {
        const isActive = selectedMetaTags.includes(tag);
        const config = metaTagConfig[tag] || metaTagConfig['na'];

        return (
            <button
                key={tag}
                onClick={() => handleMetaTagButtonClick(tag)}
                className={`p-1 rounded-full transition-colors duration-300 border-2 border-solid  ${
                    isActive
                        ? 'bg-[#0f172a] dark:bg-white border-[#0f172a] dark:border-black'
                        : 'bg-transparent border-transparent hover:border-[#0f172a] dark:hover:border-white'
                }`}
            >
                <div
                    className="flex items-center justify-center w-[20px] h-[20px] rounded-full"
                    style={{ backgroundColor: config.background }}
                >
                    <FontAwesomeIcon
                        icon={config.icon}
                        className="text-white dark:text-black text-sm"
                    />
                </div>
            </button>
        );
    };

    const renderMetaTags = (tags: string[]) => {
        return (
            <div className="flex gap-1">
                {tags.map((tag, index) => {
                    const config = metaTagConfig[tag] || metaTagConfig['na'];
                    return (
                        <div key={index} className="relative group" style={{ width: '24px', height: '24px' }}>
                            <div
                                className="w-full h-full rounded-full flex items-center justify-center"
                                style={{ backgroundColor: config.background }}
                            >
                                <FontAwesomeIcon
                                    icon={config.icon}
                                    className="text-white dark:text-black text-xs"
                                />
                            </div>
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-600 whitespace-nowrap">
                                {config.text}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="min-h-[5vh] pl-0 pr-0 pb-8 bg-white dark:bg-[#121212] flex justify-center">
            <div className="max-w-screen-xl w-full" ref={containerRef}>
                <div className="flex justify-center items-center">
                    <div className="bg-transparent m-6 w-full">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            {/* Camera gen */}
                            <div className="flex flex-col items-start justify-start h-auto w-full bg-[#ECEEF1] dark:bg-[#1e1e1e] rounded-3xl px-4 py-3">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <label className="options-label font-medium text-lg text-black dark:text-white">Camera gen</label>
                                </div>
                                <div className="flex flex-wrap space-x-2">
                                    {[1, 2, 3, 4].map((gen) => renderGenButton(gen))}
                                </div>
                            </div>

                            {/* Hemisphere */}
                            <div className="flex flex-col items-start justify-start h-auto w-full bg-[#ECEEF1] dark:bg-[#1e1e1e] rounded-3xl pr-3 pl-4 py-3">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <label className="options-label font-medium text-lg text-black dark:text-white">Hemisphere</label>
                                </div>
                                <div className="flex flex-wrap gap-1 text-[13px]">
                                    {[
                                        { hemisphere: 'Southern', icon: <FontAwesomeIcon icon={faCircleHalfStroke} style={{ transform: 'rotate(90deg)' }} /> },
                                        { hemisphere: 'Northern', icon: <FontAwesomeIcon icon={faCircleHalfStroke} style={{ transform: 'rotate(270deg)' }} /> },
                                        { hemisphere: 'Uncertain', icon: <FontAwesomeIcon icon={faCircleQuestion} /> },
                                    ].map((item) => renderHemisphereButton(item.hemisphere, item.icon))}
                                </div>
                            </div>

                            {/* Car type / color */}
                            <div className="flex flex-col items-start justify-start h-auto w-full bg-[#ECEEF1] dark:bg-[#1e1e1e] rounded-3xl px-4 py-3">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <label className="options-label font-medium text-lg text-black dark:text-white">Car type / color</label>
                                </div>
                                <div className="flex flex-wrap gap-1">
                                    {['b', 'bl', 'w', 'r', 'gr', 'bw', 'Bars'].map((type) => renderCarTypeButton(type))}
                                </div>
                            </div>

                            {/* Bollard colors */}
                            <div className="flex flex-col items-start justify-start h-auto w-full bg-[#ECEEF1] dark:bg-[#1e1e1e] rounded-3xl px-4 py-3">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faSquareCheck} className="mr-2" />
                                    <label className="options-label font-medium text-lg text-black dark:text-white">Bollard colors</label>
                                </div>
                                <div className="flex flex-wrap gap-1">
                                    {['Red', 'White', 'Yellow', 'Black', 'Grey'].map((color) =>
                                        renderColorButton(color, color.toLowerCase())
                                    )}
                                </div>
                            </div>

                            {/* Alphabet */}
                            <div className="flex flex-col items-start justify-start h-auto w-full bg-[#ECEEF1] dark:bg-[#1e1e1e] rounded-3xl px-4 py-3">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <label className="options-label font-medium text-lg text-black dark:text-white">Alphabet</label>
                                </div>
                                <div className="flex flex-wrap gap-1">
                                    {['Latin', 'Spain/Portuguese', 'Cyrillic', 'Asian', 'Other'].map((script) => renderScriptButton(script))}
                                </div>
                            </div>

                            {/* Road lines */}
                            <div className="flex flex-col items-start justify-start h-auto w-full bg-[#ECEEF1] dark:bg-[#1e1e1e] rounded-3xl px-4 py-3">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <label className="options-label font-medium text-lg text-black dark:text-white">Road lines</label>
                                </div>
                                <div className="flex flex-wrap gap-1">
                                    {roadImages.map((road) => renderRoadTypeButton(road))}
                                </div>
                            </div>

                            {/* Meta tags */}
                            <div className="flex flex-col items-start justify-start h-auto w-full bg-[#ECEEF1] dark:bg-[#1e1e1e] rounded-3xl px-4 py-3">
                                <div className="flex items-center mb-2">
                                    <FontAwesomeIcon icon={faSquareCheck} className="mr-2" />
                                    <label className="options-label font-medium text-lg text-black dark:text-white">Meta tags</label>
                                </div>
                                <div className="flex flex-wrap gap-2">
                                    {Object.keys(metaTagConfig).map((tag) => renderMetaTagButton(tag))}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap justify-start mt-4 items-center space-y-4 md:space-y-0">
                            {/* First div with buttons and selection options */}
                            <div className="flex items-center space-x-6 md:mr-6">
                                <button
                                    onClick={handleReset}
                                    className="text-md bg-[#ECEEF1] dark:bg-[#1e1e1e] text-black dark:text-white py-1.5 px-4 rounded-full
            hover:bg-[#f5f7f9] dark:hover:bg-[#2b2b2b] flex items-center"
                                >
                                    <FontAwesomeIcon icon={faRedo} className="mr-2" />
                                    Reset
                                </button>

                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faSquareCheck} className="mr-2" />
                                    <span className="font-inter text-[13px] sm:text-[16px] text-gray-600 dark:text-gray-400">Select multiple</span>
                                </div>

                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faCircleDot} className="mr-2" />
                                    <span className="font-inter text-[13px] sm:text-[16px] text-gray-600 dark:text-gray-400">Select one</span>
                                </div>
                            </div>

                            {/* Second div with feature development notice */}
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faTools} className="mr-2" />
                                <span className="font-inter text-[12px] sm:text-[16px] text-gray-600 dark:text-gray-400">
            Feature in development. New rules will improve precision.
        </span>
                            </div>
                        </div>




                    </div>
                </div>
                {!hasSelectedThreeCategories && (
                    <div className="outer-container">
                        <div className="btn-shine-container">
                            <div className="btn-shine">
                                Select at least three options to use Solve.
                            </div>
                        </div>
                    </div>
                )}



                {hasSelectedThreeCategories && (
                    <div className="rounded-3xl ">

                        <div className="w-full mt-4 overflow-x-auto md:overflow-x-hidden rounded-3xl">
                        <table className="w-full bg-white dark:bg-[#1a1a1a] rounded-3xl text-gray-900 dark:text-gray-100 border-collapse">
                            <thead>
                            <tr>
                                <th className="p-2 text-left max-w-[50px] w-[50px] whitespace-nowrap overflow-hidden sticky left-0 bg-white dark:bg-[#1a1a1a]"></th>
                                <th className="p-2 text-left max-w-[160px] w-[160px] whitespace-nowrap overflow-hidden sticky left-0 bg-white dark:bg-[#1a1a1a]">Country</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Roads</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Plates</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Stop Sign</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Crosswalk</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Bollards</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Car Types</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Camera Gen</th>
                                <th className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a]">Meta Tags</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedCountries.slice(0, visibleCountries.length).map((country, countryIndex) => (
                                <tr key={countryIndex} className="hover:bg-gray-100 dark:hover:bg-[#222222] group">
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e] max-w-[60px] w-[60px] sticky left-0 bg-white dark:bg-[#1a1a1a] text-center group-hover:bg-gray-100 dark:group-hover:bg-[#222222]">
                                        <div className="percentage-container">
                                            <svg className="percentage-svg" viewBox="0 0 38 38">
                                                <path
                                                    className="text-gray-300 dark:text-gray-700"
                                                    strokeWidth="5"
                                                    strokeDasharray="100, 100"
                                                    strokeLinecap="round"
                                                    d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                                                    fill="none"
                                                    stroke="currentColor"
                                                />
                                                <path
                                                    className="percentage-path transition-all duration-500"
                                                    strokeWidth="5"
                                                    strokeDasharray={`${country.percentage}, 100`}
                                                    strokeLinecap="round"
                                                    d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                                                    fill="none"
                                                    stroke={calculatePercentageColor(country.percentage)}
                                                />
                                            </svg>
                                            <span className="mt-[-2px] absolute inset-0 flex items-center justify-center percentage-text font-inter text-black dark:text-white">
          {country.percentage}
        </span>
                                        </div>
                                    </td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e] max-w-[160px] w-[160px] sticky left-0 bg-white dark:bg-[#1a1a1a] group-hover:bg-gray-100 dark:group-hover:bg-[#222222]">
                                        <div className="flex items-center">
                                            {country.f && <img src={country.f} className="w-8 h-auto rounded-md mr-2" alt="flag" />}
                                            <span className="letters whitespace-nowrap overflow-hidden">{country.n}</span>
                                        </div>
                                    </td>

                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]"><CarouselColumn images={country.rl || []} /></td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]"><CarouselColumn images={country.p || []} /></td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]"><CarouselColumn images={country.s || []} /></td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]">{country.cw && <img src={country.cw} className="h-8 w-auto rounded-md" alt="crosswalk sign" />}</td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]">
                                        <div className="flex flex-wrap gap-1">
                                            {country.b.map((url, i) => (
                                                <div key={i} className="relative">
                                                    <img src={url} className="h-12 w-auto rounded-md" alt="bollard" />
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]">{renderCarTypes(country.cl, activeCarType)}</td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]">{renderCameraGens(country.cg)}</td>
                                    <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]">{renderMetaTags(country.am || [])}</td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const renderCameraGens = (cameraGens: number[]) => {
    const dotSize = 18;
    const gap = 3;

    const allGens = [1, 2, 3, 4];
    return (
        <div className="flex items-center" style={{ width: 'fit-content', paddingRight: 0 }}>
            {allGens.map((gen, index) => {
                const isPresent = cameraGens.includes(gen);
                let fillColor = isPresent ? 'grey' : 'transparent';
                if (isPresent) {
                    switch (gen) {
                        case 1:
                            fillColor = 'orangered';
                            break;
                        case 2:
                            fillColor = 'orange';
                            break;
                        case 3:
                            fillColor = 'yellowgreen';
                            break;
                        case 4:
                            fillColor = 'lawngreen';
                            break;
                    }
                }

                return (
                    <div key={gen} style={{ marginRight: index < allGens.length - 1 ? gap : 0 }}>
                        <svg width={dotSize} height={dotSize}>
                            <circle cx={dotSize / 2} cy={dotSize / 2} r={dotSize / 2} fill={fillColor} />
                            {isPresent && (
                                <text
                                    x="50%"
                                    y={dotSize / 2 + 5}
                                    textAnchor="middle"
                                    fontSize={dotSize / 1.2}
                                    fill="black"
                                    fontFamily="Inter, sans-serif"
                                    fontWeight="bold"
                                >
                                    {gen}
                                </text>
                            )}
                        </svg>
                    </div>
                );
            })}
        </div>
    );
};

const renderCarTypes = (carTypes: string[], activeCarType: string | null) => {
    const dotSize = 16;
    const gap = 3;

    return (
        <div className="flex">
            {carTypes.map((type, index) => {
                if (type === 'Bars') {
                    return (
                        <svg key={index} width={dotSize * 1.8} height={dotSize * 1.5} style={{ margin: '0 2px', overflow: 'visible' }}>
                            <rect
                                x={dotSize * 0.2}
                                y={dotSize / 2 - dotSize / 6}
                                width={dotSize * 1.2}
                                height={dotSize / 3}
                                fill="black"
                                className="dark:fill-white"
                            />
                            <rect
                                x={dotSize * 0.6}
                                y={0}
                                width={dotSize / 12}
                                height={dotSize}
                                fill="black"
                                className="dark:fill-white"
                            />
                            <rect
                                x={dotSize * 0.9}
                                y={0}
                                width={dotSize / 12}
                                height={dotSize}
                                fill="black"
                                className="dark:fill-white"
                            />
                        </svg>
                    );
                } else {
                    let fillColor = 'grey';
                    switch (type) {
                        case 'b':
                            fillColor = 'black';
                            break;
                        case 'bl':
                            fillColor = 'blue';
                            break;
                        case 'w':
                            fillColor = 'white';
                            break;
                        case 'r':
                            fillColor = 'red';
                            break;
                        case 'gr':
                            fillColor = 'grey';
                            break;
                        case 'bw':
                            return (
                                <svg key={index} width={dotSize + 4} height={dotSize + 4} style={{ margin: '0 2px', overflow: 'visible' }}>
                                    <defs>
                                        <linearGradient id={`bwGradient${index}`} x1="0%" y1="0%" x2="100%">
                                            <stop offset="50%" style={{ stopColor: 'black', stopOpacity: 1 }} />
                                            <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 1 }} />
                                        </linearGradient>
                                    </defs>
                                    <circle
                                        cx={dotSize / 2 + 2}
                                        cy={dotSize / 2 + 2}
                                        r={dotSize / 2 - 1}
                                        fill={`url(#bwGradient${index})`}
                                    />
                                </svg>
                            );
                        default:
                            fillColor = 'grey';
                    }
                    return (
                        <svg key={index} width={dotSize + 4} height={dotSize + 4} style={{ margin: '0 2px', overflow: 'visible' }}>
                            <circle
                                cx={dotSize / 2 + 2}
                                cy={dotSize / 2 + 2}
                                r={dotSize / 2 - 1}
                                fill={fillColor}
                            />
                        </svg>
                    );
                }
            })}
        </div>
    );
};

const CarouselColumn: React.FC<{ images: string[] }> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState<'down' | 'up'>('down');

    useEffect(() => {
        if (images.length > 1) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => {
                    if (direction === 'down') {
                        if (prevIndex === images.length - 1) {
                            setDirection('up');
                            return prevIndex - 1;
                        }
                        return prevIndex + 1;
                    } else {
                        if (prevIndex === 0) {
                            setDirection('down');
                            return prevIndex + 1;
                        }
                        return prevIndex - 1;
                    }
                });
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [images, direction]);

    if (images.length === 0) return null;
    if (images.length === 1) return <img src={images[0]} className="h-8 w-auto rounded-md" alt="item" />;

    return (
        <div className="carousel-container">
            <div
                className="carousel-inner"
                style={{
                    transform: `translateY(-${currentIndex * (32 + 5)}px)`,
                    transition: 'transform 0.5s ease',
                }}
            >
                {images.map((image, index) => (
                    <div key={index} className="carousel-item">
                        <img src={image} className="rounded-md" alt={`item-${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Solve;