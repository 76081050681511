import React, { useState, Suspense } from 'react';
import { useDarkMode } from './DarkModeContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';
const LazyFeedbackDialog = React.lazy(() => import('./FeedbackDialog'));
const Footer: React.FC = () => {
    const { isDarkMode } = useDarkMode();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [feedbackType, setFeedbackType] = useState<'Report data issue' | 'Contribute new data' | 'Request new feature' | 'Other'>('Report data issue');
    const handleOpenDialog = (type: 'Report data issue' | 'Contribute new data' | 'Request new feature' | 'Other') => {
        setFeedbackType(type);
        setDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    return (
        <footer className={`py-8 ${isDarkMode ? 'bg-[#121212] text-gray-100' : 'bg-white text-gray-900'} font-inter`}>
            <div className="max-w-7xl mx-auto px-6">
                {/* Main Layout: flex for large screens */}
                <div className="flex flex-col lg:flex-row justify-between">
                    {/* Logo Section */}
                    <div className="flex items-start mb-10 lg:mb-0 lg:w-1/3">
                        <div className="flex items-center text-[28px] font-bold bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent animate-gradient select-none">
                            <img src="/g.webp" alt="Geography Icon" className="w-8 h-8 mr-2" />
                            <span className="font-inter font-extrabold">Geography</span>
                            <span className="font-inter font-thin ml-[1px]">Help</span>
                        </div>
                    </div>

                    {/* Links Section */}
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 lg:ml-10 gap-8">
                        <div>
                            <h3 className="text-sm font-semibold mb-4">About</h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link
                                        to="/about"
                                        className="text-gray-700 dark:text-gray-400 hover:underline"
                                        onClick={() => window.scrollTo({ top: 0, behavior: 'auto' })}
                                    >
                                        GeographyHelp
                                    </Link>
                                </li>
                                <li><Link to="/about#status" className="text-gray-700 dark:text-gray-400 hover:underline">Development status</Link></li>
                                <li><Link to="/about#highlights" className="text-gray-700 dark:text-gray-400 hover:underline">Project highlights</Link></li>
                                <li><a href="https://buymeacoffee.com/geographyhelp" target="_blank" rel="noopener noreferrer" className="text-gray-700 dark:text-gray-400 hover:underline">Buy Me a Coffee</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-sm font-semibold mb-4">Features</h3>
                            <ul className="space-y-2">
                                <li><Link to="/quiz" className="text-gray-700 dark:text-gray-400 hover:underline">Geography quiz</Link></li>
                                <li><Link to="/play" className="text-gray-700 dark:text-gray-400 hover:underline">Multiplayer quiz</Link></li>
                                <li><Link to="/map" className="text-gray-700 dark:text-gray-400 hover:underline">Resource maps</Link></li>
                                <li><Link to="/solve" className="text-gray-700 dark:text-gray-400 hover:underline">Solve location</Link></li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-sm font-semibold mb-4">Contact</h3>
                            <ul className="space-y-2">
                                <li><button className="text-gray-700 dark:text-gray-400 hover:underline" onClick={() => handleOpenDialog('Report data issue')}>Report data issue</button></li>
                                <li><button className="text-gray-700 dark:text-gray-400 hover:underline" onClick={() => handleOpenDialog('Contribute new data')}>Contribute new data</button></li>
                                <li><button className="text-gray-700 dark:text-gray-400 hover:underline" onClick={() => handleOpenDialog('Request new feature')}>Request new feature</button></li>
                                <li className="text-gray-700 dark:text-gray-400">info@geography.help</li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-sm font-semibold mb-4">Legal</h3>
                            <ul className="space-y-2">
                                <li><Link to="/privacy-policy" className="text-gray-700 dark:text-gray-400 hover:underline">Privacy Policy</Link></li>
                                <li><Link to="/terms-of-service" className="text-gray-700 dark:text-gray-400 hover:underline">Terms of Service</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-10 border-t border-gray-300 dark:border-gray-800 pt-6 text-sm text-gray-400 dark:text-gray-700 flex justify-between items-center">
                    <p>© 2024 GeographyHelp. All rights reserved.</p>
                    <a href="https://discord.gg/7r3XnkFACB" className="text-gray-400 dark:text-gray-700 hover:text-gray-800 dark:hover:text-gray-300">
                        <FontAwesomeIcon icon={faDiscord} className="text-2xl" />
                    </a>
                </div>
            </div>
            {dialogOpen && (
                <div style={{ height: '0px', overflow: 'hidden' }}>
                    <Suspense fallback={<div style={{ height: '0px' }}></div>}>
                        <LazyFeedbackDialog isOpen={dialogOpen} onClose={handleCloseDialog} selectedFeedbackType={feedbackType} />
                    </Suspense>
                </div>
            )}
        </footer>
    );
};

export default Footer;
