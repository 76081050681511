import React, { useEffect } from 'react';
import data from '../data.json';
import { clearMapElements } from '../Map';

const MapFlags: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();

            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.f) {
                    const flagImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                    flagImg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', countryData.f);

                    let flagWidth = 40;
                    let flagHeight = 30;
                    if (countryData.o === 'Europe') {
                        flagWidth = 24;
                        flagHeight = 16;
                    }
                    flagImg.setAttribute('width', flagWidth.toString());
                    flagImg.setAttribute('height', flagHeight.toString());
                    flagImg.setAttribute('x', (path.getBBox().x + path.getBBox().width / 2 - flagWidth / 2).toString());
                    flagImg.setAttribute('y', (path.getBBox().y + path.getBBox().height / 2 - flagHeight / 2).toString());
                    svg.appendChild(flagImg);
                }
            });
        }
    }, []);

    return null;
};

export default MapFlags;
