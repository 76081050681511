import React, { useEffect } from 'react';

const PrivacyPolicy: React.FC = () => {
    useEffect(() => {
        document.title = "Privacy Policy | GeographyHelp";
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8 font-inter">
                <div className="relative mx-auto max-w-[37.5rem] pt-5 text-center pb-10 font-inter">
                    <h1 className="text-4xl font-extrabold tracking-tight text-slate-900 dark:text-white sm:text-5xl">
                        Privacy Policy
                    </h1>
                    <p className="mt-4 text-base leading-7 text-slate-600 dark:text-slate-400 font-inter">
                        Last updated on August 14, 2024
                    </p>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8 font-inter pb-10">
                <div className="mx-auto max-w-[40rem] prose-sm prose prose-slate dark:prose-invert prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600 font-inter">
                    <p className="font-inter">
                        This privacy policy ("Policy") describes how Geography Help ("we", "us", "our")
                        collects, protects, and uses information provided by users ("User", "you", "your")
                        when using our website (geography.help).
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Collection of Personal Information</h2>
                    <p className="font-inter">
                        Our website does not collect any personal information, except for information knowingly provided through
                        the contact form, such as your email address. This information is used solely to respond to your inquiry.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Twitch Integration</h2>
                    <p className="font-inter">
                        For Twitch chat-integrated quizzes, users can connect their Twitch account to participate. This connection
                        allows users to submit guesses via Twitch chat while streaming. However, no login data or personal information
                        is collected by our website. The login data is stored locally in the user's browser, and users can disconnect
                        their Twitch account at any time.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Collection of Non-Personal Information</h2>
                    <p className="font-inter">
                        When you visit the Website, our servers automatically record information that your browser sends,
                        such as your IP address, browser type and version, operating system, and country of origin.
                        This data is anonymized and does not include personal information.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Quizzes and Data Collection</h2>
                    <p className="font-inter">
                        Our website offers various quizzes. When participating in a quiz, the following non-personal data is collected:
                    </p>
                    <ul className="font-inter list-disc pl-5">
                        <li>Quiz type (classic or Twitch, complete or standard)</li>
                        <li>Quiz result (score or outcome)</li>
                        <li>Number of attempts made</li>
                    </ul>
                    <p className="font-inter">
                        This data is used to generate anonymous statistics for improving the user experience on our website and
                        does not include any personally identifiable information.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Use of Quiz Data for Public Statistics</h2>
                    <p className="font-inter">
                        The number of quiz attempts is publicly displayed on our website in the form of charts, which
                        show the overall number of attempts across the different quizzes. These statistics are updated
                        in real-time and are publicly available, but they do not include any personal or identifiable data.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Analytics and Tracking</h2>
                    <p className="font-inter">
                        We use Umami Analytics to track the number of visits to our site and individual subpages.
                        Umami is a privacy-focused, cookie-less analytics tool that collects the following non-personal data:
                    </p>
                    <ul className="font-inter list-disc pl-5">
                        <li>Page views</li>
                        <li>Visits to subpages</li>
                        <li>Country of origin of the visit (based on anonymized IP address)</li>
                    </ul>
                    <p className="font-inter">
                        Since Umami does not use cookies or track personal data, no consent banner is required.
                        You can find this information in our privacy policy linked in the footer.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Use of Contact Form Information</h2>
                    <p className="font-inter">
                        When you submit information through the contact form, the data is sent directly to our email address
                        at info@geography.help. The information you provide is used only to respond to your message and is
                        not shared with any third parties, used for marketing, newsletters, or any other purpose.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Data Retention</h2>
                    <p className="font-inter">
                        Your data is retained only as long as necessary to respond to your inquiry and for the context of
                        the ongoing communication. Afterward, it will not be stored unless required by law.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Information Security</h2>
                    <p className="font-inter">
                        We secure information you provide on computer servers in a controlled, secure environment,
                        protected from unauthorized access, use, or disclosure. However, no data transmission over the
                        Internet can be guaranteed to be 100% secure.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Legal Disclosure</h2>
                    <p className="font-inter">
                        We may disclose your information if required by law or if we believe that such action is necessary
                        to comply with legal obligations or to protect our rights.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Changes and Amendments</h2>
                    <p className="font-inter">
                        We reserve the right to modify this privacy policy at any time. Continued use of the Website after
                        any such changes shall constitute your consent to such changes.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Your Rights</h2>
                    <p className="font-inter">
                        You have the right to request access to the data you submitted, correct any inaccuracies, or request its deletion.
                        For any questions, concerns, or to exercise these rights, please contact us at info@geography.help.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Contact Us</h2>
                    <p className="font-inter">
                        If you have any questions about this Policy, please contact us by email at info@geography.help.
                    </p>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
