import React, { useEffect } from 'react';
import data from '../data.json';
import './MapGameCategories.css';
import { clearMapElements } from '../Map';

const categoryColors: { [key: string]: { background: string; color: string } } = {
    br: { background: 'rgba(255, 165, 0, 0.5)', color: '#8B0000' },
    cs: { background: 'rgba(255, 255, 0, 0.5)', color: '#8B8000' },
    other: { background: 'rgba(128, 128, 128, 0.5)', color: '#2F4F4F' }
};

const MapGameCategories: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();

            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.c && countryData.c.length > 0) {
                    const isEurope = countryData.o === 'Europe';
                    const badgeWidth = isEurope ? 14 : 26;
                    const badgeHeight = isEurope ? 11 : 16;
                    const fontSize = isEurope ? 7 : 14;
                    const gap = isEurope ? 2 : 3;

                    countryData.c.forEach((category, index) => {
                        const colors = categoryColors[category] || categoryColors['other'];
                        const badge = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

                        const totalWidth = badgeWidth * countryData.c.length + gap * (countryData.c.length - 1);
                        const badgeX = path.getBBox().x + path.getBBox().width / 2 - totalWidth / 2 + index * (badgeWidth + gap);
                        const badgeY = path.getBBox().y + path.getBBox().height / 2 - badgeHeight / 2;

                        badge.classList.add('game-category-badge');
                        badge.setAttribute('x', badgeX.toString());
                        badge.setAttribute('y', badgeY.toString());
                        badge.setAttribute('width', badgeWidth.toString());
                        badge.setAttribute('height', badgeHeight.toString());
                        badge.setAttribute('rx', (badgeHeight / 2).toString());
                        badge.setAttribute('ry', (badgeHeight / 2).toString());
                        badge.setAttribute('fill', colors.background);

                        text.classList.add('game-category-text');
                        text.setAttribute('x', (badgeX + badgeWidth / 2).toString());
                        text.setAttribute('y', (badgeY + badgeHeight / 2 + (isEurope ? 2 : 5)).toString());
                        text.setAttribute('text-anchor', 'middle');
                        text.setAttribute('font-size', fontSize.toString());
                        text.setAttribute('fill', colors.color);
                        text.style.fontFamily = 'Inter, sans-serif';

                        text.textContent = category.toUpperCase();

                        svg.appendChild(badge);
                        svg.appendChild(text);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapGameCategories;
