import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import Home from './Home';
import { DarkModeProvider, useDarkMode } from './DarkModeContext';
import Quiz from './Quiz';
import Play from './Play';
import NotFound from './NotFound';
import Solve from './Solve';
import About from './About';
import QuizBollards from './quiz/QuizBollards';
import QuizPlates from './quiz/QuizPlates';
import QuizTwitchBollards from './quiz/QuizTwitchBollards';
import QuizTwitchFlags from './quiz/QuizTwitchFlags';
import QuizTwitchPlates from './quiz/QuizTwitchPlates';
import QuizTwitchCrosswalks from './quiz/QuizTwitchCrosswalks';
import QuizTwitchLanguageUse from './quiz/QuizTwitchLanguageUse';
import QuizTwitchSpecialLetters from './quiz/QuizTwitchSpecialLetters';
import QuizTwitchUniqueLetters from './quiz/QuizTwitchUniqueLetters';
import QuizFlags from './quiz/QuizFlags';
import QuizLanguageUse from './quiz/QuizLanguageUse';
import QuizRoadLines from './quiz/QuizRoadLines';
import Map from './Map';
import { TwitchProvider, TwitchCallback, TwitchUserPopup } from './TwitchAuthManager';
import QuizCrosswalks from './quiz/QuizCrosswalks';
import QuizUniqueLetters from './quiz/QuizUniqueLetters';
import QuizCar from './quiz/QuizCar';
import QuizCameraGens from './quiz/QuizCameraGens';
import QuizStopSigns from './quiz/QuizStopSigns';
import QuizSpecialLetters from './quiz/QuizSpecialLetters';
import QuizMetaTags from './quiz/QuizMetaTags';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

const ThemedApp: React.FC = () => {
    const { isDarkMode } = useDarkMode();
    return (
        <div className={`App ${isDarkMode ? 'dark bg-[#121212] text-gray-100' : 'bg-white text-gray-900'} flex flex-col min-h-screen`}>
            <Nav />
            <div className="flex-grow">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/auth/twitch/callback" element={<TwitchCallback />} />
                    <Route path="/solve" element={<Solve />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/quiz" element={<Quiz />} />
                    <Route path="/quiz/bollards" element={<QuizBollards />} />
                    <Route path="/quiz/plates" element={<QuizPlates />} />
                    <Route path="/quiz/crosswalk-signs" element={<QuizCrosswalks />} />
                    <Route path="/quiz/unique-letters" element={<QuizUniqueLetters />} />
                    <Route path="/quiz/flags" element={<QuizFlags />} />
                    <Route path="/quiz/language-use" element={<QuizLanguageUse />} />
                    <Route path="/quiz/camera-gens" element={<QuizCameraGens />} />
                    <Route path="/quiz/car" element={<QuizCar />} />
                    <Route path="/quiz/stop-signs" element={<QuizStopSigns />} />
                    <Route path="/quiz/road-lines" element={<QuizRoadLines />} />
                    <Route path="/quiz/special-letters" element={<QuizSpecialLetters />} />
                    <Route path="/quiz/meta-tags" element={<QuizMetaTags />} />
                    <Route path="/quiz/bollards/twitch" element={<QuizTwitchBollards />} />
                    <Route path="/quiz/flags/twitch" element={<QuizTwitchFlags />} />
                    <Route path="/quiz/plates/twitch" element={<QuizTwitchPlates />} />
                    <Route path="/quiz/crosswalks/twitch" element={<QuizTwitchCrosswalks />} />
                    <Route path="/quiz/languageuse/twitch" element={<QuizTwitchLanguageUse />} />
                    <Route path="/quiz/specialletters/twitch" element={<QuizTwitchSpecialLetters />} />
                    <Route path="/quiz/uniqueletters/twitch" element={<QuizTwitchUniqueLetters />} />
                    <Route path="/play" element={<Play />} />
                    <Route path="/map" element={<Navigate to="/map/flags" />} />
                    <Route path="/map/flags" element={<Map defaultCategory="Flags" />} />
                    <Route path="/map/bollards" element={<Map defaultCategory="Bollards" />} />
                    <Route path="/map/plates" element={<Map defaultCategory="Plates" />} />
                    <Route path="/map/roadlines" element={<Map defaultCategory="RoadLines" />} />
                    <Route path="/map/stopsigns" element={<Map defaultCategory="StopSigns" />} />
                    <Route path="/map/crosswalksigns" element={<Map defaultCategory="CrosswalkSigns" />} />
                    <Route path="/map/cars" element={<Map defaultCategory="Cars" />} />
                    <Route path="/map/cameragen" element={<Map defaultCategory="CameraGen" />} />
                    <Route path="/map/specialletters" element={<Map defaultCategory="SpecialLetters" />} />
                    <Route path="/map/uniqueletters" element={<Map defaultCategory="UniqueLetters" />} />
                    <Route path="/map/drivingside" element={<Map defaultCategory="DrivingSide" />} />
                    <Route path="/map/domainsuffix" element={<Map defaultCategory="DomainSuffix" />} />
                    <Route path="/map/name" element={<Map defaultCategory="Name" />} />
                    <Route path="/map/languagegroup" element={<Map defaultCategory="LanguageGroup" />} />
                    <Route path="/map/languages" element={<Map defaultCategory="Languages" />} />
                    <Route path="/map/phonecode" element={<Map defaultCategory="PhoneCode" />} />
                    <Route path="/map/currency" element={<Map defaultCategory="Currency" />} />
                    <Route path="/map/gamecategories" element={<Map defaultCategory="GameCategories" />} />
                    <Route path="/map/metatags" element={<Map defaultCategory="MetaTags" />} />
                    <Route path="/map/capitalcities" element={<Map defaultCategory="CapitalCities" />} />
                    <Route path="/map/languageuseexamples" element={<Map defaultCategory="LanguageUseExamples" />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
};

function App() {
    return (
        <DarkModeProvider>
            <TwitchProvider>
                <Router>
                    <ThemedApp />
                </Router>
            </TwitchProvider>
        </DarkModeProvider>
    );
}

export default App;
