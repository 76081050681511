import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface ChartDataItem {
    label: string;
    value: number;
}

const PieChart: React.FC = () => {
    const [chartData, setChartData] = useState<any | null>(null);
    const labelThreshold = 7;

    const fetchData = async () => {
        const apiKey = 'AIzaSyAZP3J8j8TyPLP0zwS5Yb_5fRRrzq_fqDg';
        const spreadsheetId = '1m1_Rl8Ns4EfvXW0O8_7JbwOWTvrHZkrgCD7A9n1p2O8';
        const range = `'Form responses 1'!E:F`;

        try {
            const response = await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`
            );

            const data: ChartDataItem[] = response.data.values.slice(1)
                .filter((row: string[]) => row[0] && row[0] !== 'undefined' && Number(row[1]) > 0)
                .map((row: string[]) => ({
                    label: row[0],
                    value: Number(row[1]),
                }));

            if (data.length > 0) {
                setChartData({
                    labels: data.map((item) => item.label),
                    datasets: [
                        {
                            label: 'Quiz Attempts',
                            data: data.map((item) => item.value),
                            backgroundColor: [
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56',
                                '#4BC0C0',
                                '#9966FF',
                                '#FF9F40',
                            ],
                            borderColor: 'transparent', // Makes the borders transparent
                            hoverBorderColor: 'transparent', // Makes the hover borders transparent
                            hoverOffset: 4,
                        },
                    ],
                });
            }
        } catch (error) {
            console.error('Error fetching data from Google Sheets:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data only once when the component mounts
    }, []); // Empty dependency array means this effect runs only once

    return (
        <div className="relative font-inter w-[176px] sm:w-[208px] h-[176px] sm:h-[208px] mx-auto dark:bg-[#121212] bg-white group">
            {chartData ? (
                <Pie
                    data={chartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                color: '#000000',
                                anchor: 'end',
                                align: 'start',
                                formatter: (value: number, context: any) => {
                                    return value >= labelThreshold ? `${context.chart.data.labels[context.dataIndex]}: ${value}` : '';
                                },
                                font: {
                                    family: 'Inter',
                                    size: 12,
                                    weight: 'bold',
                                },
                            },
                            tooltip: {
                                callbacks: {
                                    label: (tooltipItem: any) => {
                                        return `${tooltipItem.label}: ${tooltipItem.raw} attempts`;
                                    },
                                },
                            },
                        },
                    }}
                />
            ) : null}
            <div className="absolute top-2 right-2 flex items-center">
                <div className="relative  animate-pulse">
                    <div className="w-3 h-3 bg-red-500 rounded-full "></div>
                    <span className="absolute font-inter font-bold left-4 top-[-2px] text-xs text-gray-900 dark:text-white   duration-300">
                        LIVE
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PieChart;
