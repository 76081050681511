import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faLock,
    faUserFriends,
    faStar,
    faUsers,
    faCopy,
    faSpinner,
    faCircleXmark,
    faFrown
} from '@fortawesome/free-solid-svg-icons';
import { db } from './firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import ReCAPTCHA from 'react-google-recaptcha';

const Play: React.FC = () => {
    const [roomID, setRoomID] = useState('');
    const [username, setUsername] = useState('');
    const [visibility, setVisibility] = useState<string | null>(null);
    const [playerLimit, setPlayerLimit] = useState<number | null>(null);
    const [currentRoom, setCurrentRoom] = useState<string | null>(null);
    const [isCreator, setIsCreator] = useState<boolean>(false);
    const [players, setPlayers] = useState<string[]>([]);
    const [loading, setLoading] = useState({ join: false, create: false, startQuiz: false });
    const [error, setError] = useState<string | null>(null);
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const recaptchaRef = React.createRef<ReCAPTCHA>();
    const [publicRooms, setPublicRooms] = useState<any[]>([]);

    const [selectedOption, setSelectedOption] = useState<string>('Voting');
    const [selectedQuiz, setSelectedQuiz] = useState<string>('Bollards');
    const [selectedQuestions, setSelectedQuestions] = useState<number | null>(10);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        document.title = "Group Quiz | GeographyHelp";
    }, []);


    useEffect(() => {
        const roomIDFromLink = searchParams.get('linkid');
        if (roomIDFromLink) {
            setRoomID(roomIDFromLink);
        }
    }, [searchParams]);

    useEffect(() => {
        if (currentRoom) {
            const roomRef = doc(db, "rooms", currentRoom);
            const unsubscribe = onSnapshot(roomRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    setPlayers(data.users || []);
                }
            });
            return () => unsubscribe();
        }
    }, [currentRoom]);

    useEffect(() => {
        if (username && visibility && playerLimit && recaptchaRef.current) {
            recaptchaRef.current.execute();
        }
    }, [username, visibility, playerLimit]);

    const handleRoomIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRoomID(e.target.value);
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handleVisibilityChange = (type: string) => {
        setVisibility(type);
    };

    const handlePlayerLimitChange = (limit: number) => {
        setPlayerLimit(limit);
    };

    const handleCreateRoom = async () => {
        setLoading({ ...loading, create: true });
        setError(null);

        if (!recaptchaToken && recaptchaRef.current) {
            recaptchaRef.current.execute(); // Ensure reCAPTCHA is executed
        } else {
            // Your existing logic to create the room
        }
    };

    const onRecaptchaChange = async (token: string | null) => {
        if (token) {
            setRecaptchaToken(token);

            // Send the token to your serverless function
            if (visibility && playerLimit && username) {
                try {
                    const response = await fetch('/.netlify/functions/createRoom', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ username, token }),
                    });

                    const result = await response.json();
                    if (response.ok) {
                        setCurrentRoom(result.roomId);
                        setIsCreator(true);
                        setPlayers([username]);
                    } else {
                        setError(result.error || 'An error occurred.');
                    }
                } catch (error) {
                    setError('Failed to create room.');
                } finally {
                    setLoading({ ...loading, create: false });
                }
            } else {
                setLoading({ ...loading, create: false });
                setError('All fields are required.');
            }
        }
    };


    const handleCopyLink = () => {
        const link = `${window.location.origin}/play?linkid=${currentRoom}`;
        navigator.clipboard.writeText(link);
        alert('Room link copied to clipboard!');
    };

    const handleJoinRoom = async () => {
        setLoading({ ...loading, join: true });
        setError(null);
        if (roomID && username) {
            try {
                const response = await fetch('/.netlify/functions/joinRoom', {
                    method: 'POST',
                    body: JSON.stringify({ roomId: roomID, username }),
                });
                const result = await response.json();
                if (response.ok) {
                    setCurrentRoom(roomID);
                    setIsCreator(false);
                } else {
                    setError(result.error || 'An error occurred.');
                }
            } catch (error) {
                setError('Failed to join room.');
            } finally {
                setLoading({ ...loading, join: false });
            }
        } else {
            setLoading({ ...loading, join: false });
            setError('Room ID and Username are required.');
        }
    };

    const startQuiz = async () => {
        setLoading({ ...loading, startQuiz: true });
        // Logic to start the quiz can go here
        // ...
        setLoading({ ...loading, startQuiz: false });
    };

    return (
        <div className="min-h-[58vh] pt-10 pr-8 pl-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-center">
            {!currentRoom ? (
                <div className="intro-container w-full max-w-5xl text-center">
                    <div className="w-full text-center mb-6">
                        <p className="text-md animate-pulse font-bold text-red-500 flex justify-center items-center">
                            <FontAwesomeIcon icon={faFrown} className="mr-2 text-lg nimate-pulse text-red-500" />
                            This feature is currently not available
                        </p>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-1/2 sm:pr-20 text-left">
                            <h2 className="text-2xl font-inter font-bold mb-4">Join a room</h2>
                            <div className="flex justify-between space-x-4 w-full sm:mt-5 mt-2">
                                <input
                                    type="text"
                                    placeholder="Your Username"
                                    value={username}
                                    onChange={handleUsernameChange}
                                    className="flex-grow p-2 font-inter rounded-full bg-gray-100 dark:bg-[#2a2a2a] text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="flex justify-between space-x-2 w-full sm:mt-5 mt-2">
                                <input
                                    type="text"
                                    placeholder="Room ID"
                                    value={roomID}
                                    onChange={handleRoomIDChange}
                                    className="flex-grow p-2 font-inter rounded-full bg-gray-100 dark:bg-[#2a2a2a] text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <button
                                    onClick={handleJoinRoom}
                                    className="py-1 px-5  dark:hover:text-white font-inter hover:text-black dark:bg-white dark:text-black  bg-[#0f172a] text-white dark:hover:bg-transparent hover:bg-transparent rounded-full transition-colors duration-300 border-2 border-solid border-[#0f172a] dark:border-white flex items-center justify-center whitespace-nowrap"
                                    disabled={loading.join}
                                >
                                    {loading.join ? (
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                            <span className="sm:text-md text-sm">Joining...</span>
                                        </>
                                    ) : (
                                        'Join Room'
                                    )}
                                </button>
                            </div>

                            <div className="min-h-[20px] text-red-500 text-sm mt-2">
                                {error && <p>{error}</p>}
                            </div>

                            {publicRooms.length === 0 ? (
                                <p className="items-center">
                                    <FontAwesomeIcon icon={faCircleXmark} className="text-md text-gray-400 dark:text-gray-600"/>
                                    <span className="ml-[7px] text-md font-inter text-gray-400 dark:text-gray-600 mt-[-6px]">No public rooms available, you can join private rooms.</span>
                                </p>
                            ) : (
                                <p className="font-inter">Active public rooms should be listed here</p>
                            )}

                        </div>

                        <div className="w-full sm:w-1/2 sm:pl-20 text-left sm:mt-0 mt-6">
                            <h2 className="text-2xl font-bold font-inter mb-4">Create a new room</h2>
                            <input
                                type="text"
                                placeholder="Your Username"
                                value={username}
                                onChange={handleUsernameChange}
                                className="w-full p-2 font-inter rounded-full bg-gray-100 dark:bg-[#2a2a2a] text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex flex-col justify-between">
                                    <label className="block font-inter text-left mt-1 md:mt-5 mb-1">Visibility</label>
                                    <div className="w-full mb-1 flex-1">
                                        <button
                                            onClick={() => handleVisibilityChange('Public')}
                                            className={`w-full h-[28px]  font-inter px-2 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                                visibility === 'Public'
                                                    ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black'
                                                    : 'text-black dark:text-white'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={faGlobe} className="mr-1" /> Public
                                        </button>
                                    </div>
                                    <div className="w-full flex-1">
                                        <button
                                            onClick={() => handleVisibilityChange('Unlisted')}
                                            className={`w-full h-[28px]  font-inter px-2 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                                visibility === 'Unlisted'
                                                    ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black'
                                                    : 'text-black dark:text-white'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={faLock} className="mr-1" /> Private
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-col justify-between ">
                                    <label className="block font-inter text-left mt-1 md:mt-5 mb-1">Limit</label>
                                    <div className="w-full mb-1 flex space-x-1 flex-1">
                                        <button
                                            onClick={() => handlePlayerLimitChange(2)}
                                            className={`w-1/2 h-[28px] font-inter rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                                playerLimit === 2
                                                    ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black'
                                                    : 'text-black dark:text-white'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={faUserFriends} className="mr-1 text-md" /> 2
                                        </button>
                                        <button
                                            onClick={() => handlePlayerLimitChange(5)}
                                            className={`w-1/2 h-[28px]  font-inter rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                                playerLimit === 5
                                                    ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black'
                                                    : 'text-black dark:text-white'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={faUsers} className="mr-1 text-md" /> 5
                                        </button>
                                    </div>
                                    <div className="w-full flex space-x-1 flex-1">
                                        <button
                                            onClick={() => handlePlayerLimitChange(10)}
                                            className={`w-1/2 h-[28px]  font-inter rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                                playerLimit === 10
                                                    ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black'
                                                    : 'text-black dark:text-white'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={faUsers} className="mr-1 text-md" /> 10
                                        </button>
                                        <button
                                            onClick={() => handlePlayerLimitChange(20)}
                                            className={`w-1/2 h-[28px] font-inter rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                                playerLimit === 20
                                                    ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black'
                                                    : 'text-black dark:text-white'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={faUsers} className="mr-1 text-md" /><FontAwesomeIcon icon={faUsers} className="ml-[-12px] text-md" /> 20
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center">



                                <div className="mb-6">
                                    <button
                                        onClick={handleCreateRoom}
                                        className={`mt-4 text-md py-2 px-6  dark:hover:text-white font-inter hover:text-black dark:bg-white dark:text-black  bg-[#0f172a] text-white dark:hover:bg-transparent hover:bg-transparent   rounded-full transition-colors duration-300 border-2 border-solid border-[#0f172a] dark:border-white ${
                                            !visibility || !playerLimit || !username ? 'cursor-not-allowed' : ''
                                        }`}
                                        disabled={loading.create || !visibility || !playerLimit || !username}
                                    >
                                        {loading.create ? (
                                            <>
                                                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                                Creating...
                                            </>
                                        ) : (
                                            'Create New Room'
                                        )}
                                    </button>
                                </div>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LfFPyYqAAAAAHBDP7lSkxYb3YAoFL64_iBJ8-Gz"
                                    size="invisible"
                                    badge="inline"
                                    onChange={onRecaptchaChange}
                                />
                            </div>

                            <div className="min-h-[20px] text-red-500 text-sm mt-1">
                                {error && <p>{error}</p>}
                            </div>
                            <div className="flex items-start mt-1 mb-4">
                                <FontAwesomeIcon icon={faTwitch} className="text-gray-400 dark:text-gray-600 text-[36px] mr-1" />
                                <span className="ml-2 text-md font-inter text-gray-400 dark:text-gray-600 mt-[-6px]">
                                    If you're a streamer, choose a{" "}
                                    <a href="/quiz" className="text-blue-500 no-underline">
                                        quiz
                                    </a>, then select the "Twitch Stream" option to connect your account.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="room-lobby flex flex-col p-4">
                    <div className="w-full mb-4 text-center">
                        <h1 className="text-4xl font-bold font-inter mb-2">Room Lobby</h1>
                        <p className="text-xl font-inter">Room ID: {currentRoom}</p>
                        <button
                            onClick={handleCopyLink}
                            className="mt-2 py-2 px-4 bg-gray-200 text-black rounded-full  flex items-center justify-center space-x-2"
                        >
                            <FontAwesomeIcon icon={faCopy} className="text-lg" />
                            <span>Copy Link</span>
                        </button>
                    </div>
                    <div className="room-lobby-content flex flex-wrap">
                        {isCreator ? (
                            <div className="w-full sm:w-1/2 p-4">
                                <h2 className="text-2xl font-bold font-inter mb-4">Creator Options</h2>
                                <div className="flex items-center mb-4 space-x-3">
                                    <button
                                        className={`w-1/2  font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                            selectedOption === 'Voting'
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedOption('Voting')}
                                    >
                                        Voting
                                    </button>
                                    <button
                                        className={`w-1/2  font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover.border-white text-center ${
                                            selectedOption === 'I Decide'
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedOption('I Decide')}
                                    >
                                        I Decide
                                    </button>
                                </div>
                                <h3 className="text-xl font-bold mb-2">Choose Quiz</h3>
                                <div className="flex space-x-3 mb-4">
                                    <button
                                        className={`w-1/3  font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover:border-white text-center ${
                                            selectedQuiz === 'Bollards'
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedQuiz('Bollards')}
                                    >
                                        Bollards
                                    </button>
                                    <button
                                        className={`w-1/3  font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover.border-white text-center ${
                                            selectedQuiz === 'License Plates'
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedQuiz('License Plates')}
                                    >
                                        License Plates
                                    </button>
                                    <button
                                        className={`w-1/3  font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover.border-white text-center ${
                                            selectedQuiz === 'Flags'
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedQuiz('Flags')}
                                    >
                                        Flags
                                    </button>
                                </div>
                                <h3 className="text-xl font-bold mb-2">Number of Questions</h3>
                                <div className="flex space-x-3 mb-4">
                                    <button
                                        className={`w-1/4 font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover.border-white text-center ${
                                            selectedQuestions === 10
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedQuestions(10)}
                                    >
                                        10
                                    </button>
                                    <button
                                        className={`w-1/4 font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover:border-[#0f172a] dark:hover.border-white text-center ${
                                            selectedQuestions === 20
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedQuestions(20)}
                                    >
                                        20
                                    </button>
                                    <button
                                        className={`w-1/4 font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover.border-[#0f172a] dark:hover.border-white text-center ${
                                            selectedQuestions === 30
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark:text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedQuestions(30)}
                                    >
                                        30
                                    </button>
                                    <button
                                        className={`w-1/4 font-inter pt-1 px-2 pb-1 rounded-3xl transition-colors duration-300 text-sm md:text-base border-2 border-transparent border-solid hover.border-[#0f172a] dark:hover.border-white text-center ${
                                            selectedQuestions === null
                                                ? 'bg-[#0f172a] text-white dark:bg.white dark.text-black'
                                                : 'text-black dark:text-white'
                                        }`}
                                        onClick={() => setSelectedQuestions(null)}
                                    >
                                        All
                                    </button>
                                </div>
                                <button
                                    onClick={startQuiz}
                                    className={`mt-4 py-2 px-6 hover:bg-[#0f172a] font-inter hover:text-white dark:bg.white dark.text-black bg-transparent text-black dark:hover.bg.transparent dark:hover.text.white  rounded-full transition-colors duration-300 border-2 border-solid border-[#0f172a] dark:border-white ${
                                        loading.startQuiz ? 'cursor-not-allowed opacity-50' : ''
                                    }`}
                                    disabled={loading.startQuiz}
                                >
                                    {loading.startQuiz ? (
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                            Starting Quiz...
                                        </>
                                    ) : (
                                        'Start Quiz'
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div className="room-lobby-user w-full sm:w-1/2 p-4">
                                <h2 className="text-2xl  font-inter mb-4">Players in the room: {players.length}</h2>
                                <div className="max-h-[500px] overflow-y-auto border border-gray-300 rounded-md p-4">
                                    {players.length ? (
                                        players.map((player, index) => (
                                            <p key={index} className="text-lg font-inter mb-2">
                                                {player} {isCreator && player === username && <FontAwesomeIcon icon={faStar} className="text-yellow-500 ml-2" />}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No players yet.</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Play;
