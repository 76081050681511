// AboutSubpagesList.tsx
import React from 'react';
import { Dialog } from '@hilla/react-components/Dialog.js';
import { Button } from '@hilla/react-components/Button.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMap, faQuestionCircle, faBookOpen, faTimes} from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from '@fortawesome/free-brands-svg-icons'; // Importing Twitch icon from brand icons

interface AboutSubpagesListProps {
    isOpen: boolean;
    onClose: () => void;
}

const AboutSubpagesList: React.FC<AboutSubpagesListProps> = ({ isOpen, onClose }) => {
    const mainSubpages = ['/', '/map', '/quiz', '/play', '/solve', '/about', '/privacy-policy', '/terms-of-service'];
    const mapSubpages = [
        '/map/flags',
        '/map/bollards',
        '/map/plates',
        '/map/roadlines',
        '/map/stopsigns',
        '/map/crosswalksigns',
        '/map/cars',
        '/map/cameragen',
        '/map/specialletters',
        '/map/uniqueletters',
        '/map/drivingside',
        '/map/domainsuffix',
        '/map/name',
        '/map/languagegroup',
        '/map/languages',
        '/map/phonecode',
        '/map/currency',
        '/map/gamecategories',
        '/map/metatags',
        '/map/capitalcities',
        '/map/languageuseexamples',
    ];
    const quizSubpages = [
        '/quiz',
        '/quiz/bollards',
        '/quiz/plates',
        '/quiz/crosswalk-signs',
        '/quiz/unique-letters',
        '/quiz/flags',
        '/quiz/language-use',
        '/quiz/camera-gens',
        '/quiz/car',
        '/quiz/stop-signs',
        '/quiz/road-lines',
        '/quiz/special-letters',
        '/quiz/meta-tags',
    ];
    const twitchQuizSubpages = [
        '/quiz/bollards/twitch?type=standard',
        '/quiz/bollards/twitch?type=complete',
        '/quiz/flags/twitch?type=standard',
        '/quiz/flags/twitch?type=complete',
        '/quiz/plates/twitch?type=standard',
        '/quiz/plates/twitch?type=complete',
        '/quiz/crosswalks/twitch?type=standard',
        '/quiz/crosswalks/twitch?type=complete',
        '/quiz/languageuse/twitch?type=standard',
        '/quiz/languageuse/twitch?type=complete',
        '/quiz/specialletters/twitch?type=standard',
        '/quiz/specialletters/twitch?type=complete',
        '/quiz/uniqueletters/twitch?type=standard',
        '/quiz/uniqueletters/twitch?type=complete',
    ];

    // Calculate total number of links
    const totalLinks = mainSubpages.length + mapSubpages.length + quizSubpages.length + twitchQuizSubpages.length;

    const getFullUrl = (path: string) => `https://geography.help${path}`;

    return (
        <Dialog opened={isOpen} onOpenedChanged={(e) => !e.detail.value && onClose()}>
            <div className="p-6">
                {/* Display total number of links */}
                <h3 className="text-2xl font-bold text-black">
                    Available Subpages ({totalLinks})
                </h3>

                {/* Flow content naturally into columns */}
                <div className="columns-1 sm:columns-2 lg:columns-3 gap-6 mt-4">
                    {/* Main Subpages */}
                    <div className="font-bold mt-2">
                        <FontAwesomeIcon icon={faBookOpen} className="mr-2" /> Main Subpages
                    </div>
                    {mainSubpages.map((path, idx) => (
                        <div key={idx}>
                            <a href={getFullUrl(path)} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-[14px] hover:underline">
                                {getFullUrl(path)}
                            </a>
                        </div>
                    ))}

                    {/* Map Subpages */}
                    <div className="font-bold mt-4">
                        <FontAwesomeIcon icon={faMap} className="mr-2" /> Maps
                    </div>
                    {mapSubpages.map((path, idx) => (
                        <div key={idx}>
                            <a href={getFullUrl(path)} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-[14px] hover:underline">
                                {getFullUrl(path)}
                            </a>
                        </div>
                    ))}

                    {/* Quiz Subpages */}
                    <div className="font-bold mt-4">
                        <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" /> Quizzes (complete & standard)
                    </div>
                    {quizSubpages.map((path, idx) => (
                        <div key={idx}>
                            <a href={getFullUrl(path)} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-[14px] hover:underline">
                                {getFullUrl(path)}
                            </a>
                        </div>
                    ))}

                    {/* Twitch Quiz Subpages */}
                    <div className="font-bold mt-4">
                        <FontAwesomeIcon icon={faTwitch} className="mr-2" /> Twitch chat integrated Quizzes
                    </div>
                    {twitchQuizSubpages.map((path, idx) => (
                        <div key={idx}>
                            <a href={getFullUrl(path)} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-[12px] sm:text-[14px] hover:underline">
                                {getFullUrl(path)}
                            </a>
                        </div>
                    ))}
                </div>

                {/* Exit button */}
                <Button theme="primary" className="mt-6" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" /> Close
                </Button>
            </div>
        </Dialog>
    );
};

export default AboutSubpagesList;
