// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA8RHwRQHRNL4-sq-Y0nrHb9hFfgfnttPk",
    authDomain: "geographyhelp-f2e67.firebaseapp.com",
    projectId: "geographyhelp-f2e67",
    storageBucket: "geographyhelp-f2e67.appspot.com",
    messagingSenderId: "20607247568",
    appId: "1:20607247568:web:b4e253b326aec447ab6ad5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
