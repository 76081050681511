import React, { useEffect } from 'react';
import data from '../data.json';
import { clearMapElements } from '../Map';

const MapCars: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.cl && countryData.cl.length > 0) {
                    const isEurope = countryData.o === 'Europe';
                    const dotSize = isEurope ? 8 : 15;
                    const gap = isEurope ? 2 : 4;

                    const totalWidth = dotSize * countryData.cl.length + gap * (countryData.cl.length - 1);
                    countryData.cl.forEach((color, index) => {
                        const xPosition = path.getBBox().x + path.getBBox().width / 2 - totalWidth / 2 + index * (dotSize + gap);
                        const yPosition = path.getBBox().y + path.getBBox().height / 2;

                        if (color === 'Bars') {
                            const barGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                            barGroup.classList.add('car-color');

                            const barWidth = dotSize * 1.2;
                            const barHeight = dotSize / 4;
                            const verticalBarWidth = dotSize / 10;
                            const verticalBarHeight = dotSize;

                            const bar = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                            bar.setAttribute('x', (xPosition - barWidth / 2).toString());
                            bar.setAttribute('y', (yPosition - barHeight / 2).toString());
                            bar.setAttribute('width', barWidth.toString());
                            bar.setAttribute('height', barHeight.toString());
                            bar.setAttribute('fill', 'black');

                            const barLine1 = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                            barLine1.setAttribute('x', (xPosition - barWidth / 6 - verticalBarWidth / 2).toString());
                            barLine1.setAttribute('y', (yPosition - verticalBarHeight / 2).toString());
                            barLine1.setAttribute('width', verticalBarWidth.toString());
                            barLine1.setAttribute('height', verticalBarHeight.toString());
                            barLine1.setAttribute('fill', 'black');

                            const barLine2 = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                            barLine2.setAttribute('x', (xPosition + barWidth / 6 - verticalBarWidth / 2).toString());
                            barLine2.setAttribute('y', (yPosition - verticalBarHeight / 2).toString());
                            barLine2.setAttribute('width', verticalBarWidth.toString());
                            barLine2.setAttribute('height', verticalBarHeight.toString());
                            barLine2.setAttribute('fill', 'black');

                            barGroup.appendChild(bar);
                            barGroup.appendChild(barLine1);
                            barGroup.appendChild(barLine2);
                            svg.appendChild(barGroup);
                        } else {
                            const dot = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                            dot.classList.add('car-color');
                            dot.setAttribute('cx', xPosition.toString());
                            dot.setAttribute('cy', yPosition.toString());
                            dot.setAttribute('r', (dotSize / 2).toString());

                            switch (color) {
                                case 'b':
                                    dot.setAttribute('fill', 'black');
                                    break;
                                case 'bl':
                                    dot.setAttribute('fill', 'blue');
                                    break;
                                case 'w':
                                    dot.setAttribute('fill', 'white');
                                    break;
                                case 'r':
                                    dot.setAttribute('fill', 'red');
                                    break;
                                case 'gr':
                                    dot.setAttribute('fill', 'grey');
                                    break;
                                case 'bw':
                                    dot.remove();
                                    const halfCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                                    halfCircle.classList.add('car-color');
                                    halfCircle.setAttribute('cx', xPosition.toString());
                                    halfCircle.setAttribute('cy', yPosition.toString());
                                    halfCircle.setAttribute('r', (dotSize / 2).toString());
                                    halfCircle.setAttribute('fill', 'url(#bwGradient)');
                                    svg.appendChild(halfCircle);

                                    const defs = svg.querySelector('defs') || document.createElementNS('http://www.w3.org/2000/svg', 'defs');
                                    if (!defs.parentNode) {
                                        svg.insertBefore(defs, svg.firstChild);
                                    }

                                    if (!document.getElementById('bwGradient')) {
                                        const gradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
                                        gradient.id = 'bwGradient';
                                        gradient.setAttribute('x1', '0%');
                                        gradient.setAttribute('x2', '100%');
                                        gradient.setAttribute('y1', '0%');
                                        gradient.setAttribute('y2', '0%');

                                        const stop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
                                        stop1.setAttribute('offset', '50%');
                                        stop1.setAttribute('style', 'stop-color:black;stop-opacity:1');

                                        const stop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
                                        stop2.setAttribute('offset', '50%');
                                        stop2.setAttribute('style', 'stop-color:white;stop-opacity:1');

                                        gradient.appendChild(stop1);
                                        gradient.appendChild(stop2);
                                        defs.appendChild(gradient);
                                    }
                                    break;
                                default:
                                    dot.setAttribute('fill', 'grey');
                                    break;
                            }

                            svg.appendChild(dot);
                        }
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapCars;
