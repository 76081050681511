import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
    faMapMarkerAlt,
    faClock,
    faBan,
    faMap,
    faQuestionCircle,
    faCheck,
    faTimes,
    faPlay,
    faRedo,
    faArrowLeft,
    faTags,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import QuizWorldMapAlt from '../QuizWorldMapAlt';
import { useNavigate } from 'react-router-dom';
import countryData from '../data.json';
import '../Quiz.css';

const TOTAL_QUESTIONS = 10;

const countryNames = countryData.reduce((acc: Record<string, string>, country) => {
    acc[country.i.toUpperCase()] = country.n;
    return acc;
}, {});

const flags = countryData.reduce((acc: Record<string, string>, country) => {
    acc[country.i.toUpperCase()] = country.f;
    return acc;
}, {});

const countriesWithMetaTags = countryData.filter(country => country.am && country.am.length > 0);

const metaTagConfig: { [key: string]: { background: string; icon: any; text: string } } = {
    bt: { background: '#2c3e50', icon: faTags, text: 'Black tape' },
    sn: { background: '#8e44ad', icon: faTags, text: 'Snorkel' },
    fcn: { background: '#c0392b', icon: faTags, text: 'Follow car (Nigeria)' },
    sr: { background: '#00BFFF', icon: faTags, text: 'Sky rifts' },
    fct: { background: '#c0392b', icon: faTags, text: 'Follow car (Tunisia)' },
    av: { background: '#d35400', icon: faTags, text: 'Google car - antenna visible' },
    lc: { background: '#f39c12', icon: faTags, text: 'Low placed camera' },
    fpa: { background: '#e759d6', icon: faTags, text: 'Cam artifact - pink anomaly' },
    bb: { background: '#7f8c8d', icon: faTags, text: 'Abundance of blurred buildings' },
    na: { background: '#2c3e50', icon: faBan, text: 'No antenna' },
};

const QuizMetaTags: React.FC = () => {
    const [quizStarted, setQuizStarted] = useState(false);
    const [completeQuizStarted, setCompleteQuizStarted] = useState(false);
    const [retryQuiz, setRetryQuiz] = useState(false);
    const [currentRound, setCurrentRound] = useState(0);
    const [selectedMetaTags, setSelectedMetaTags] = useState<string[]>([]);
    const [answers, setAnswers] = useState<Array<{ country: string | null, selectedMetaTags: string[], correctMetaTags: string[] }>>([]);
    const [currentCountry, setCurrentCountry] = useState<string | null>(null);
    const [quizFinished, setQuizFinished] = useState(false);
    const [isAnswering, setIsAnswering] = useState(false);
    const [timeLeft, setTimeLeft] = useState(20);
    const [animationKey, setAnimationKey] = useState(0);

    const navigate = useNavigate();

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const totalQuestions = completeQuizStarted ? countriesWithMetaTags.length : TOTAL_QUESTIONS;

        if ((quizStarted || completeQuizStarted) && currentRound < totalQuestions) {
            if (retryQuiz) {
                const currentCountryData = countriesWithMetaTags[currentRound];
                setCurrentCountry(currentCountryData.i.toUpperCase());
                setSelectedMetaTags([]);
            } else {
                selectRandomCountry(completeQuizStarted);
            }
            setTimeLeft(20);
            setAnimationKey((prevKey) => prevKey + 1);
        } else if ((quizStarted || completeQuizStarted) && currentRound >= totalQuestions) {
            setQuizFinished(true);
        }
    }, [quizStarted, completeQuizStarted, currentRound]);

    useEffect(() => {
        const totalQuestions = completeQuizStarted ? countriesWithMetaTags.length : TOTAL_QUESTIONS;

        if ((quizStarted || completeQuizStarted) && currentRound < totalQuestions) {
            if (timeLeft === 0) {
                if (selectedMetaTags.length > 0) {
                    handleAnswer();
                } else {
                    const correctMetaTags = countriesWithMetaTags.find(country => country.i.toUpperCase() === currentCountry)?.am || [];
                    setAnswers([...answers, { country: currentCountry, selectedMetaTags: [], correctMetaTags }]);
                    setCurrentRound(currentRound + 1);
                }
            }
            if (timeLeft > 0) {
                const timerId = setInterval(() => {
                    setTimeLeft(timeLeft - 1);
                }, 1000);
                return () => clearInterval(timerId);
            }
        }
    }, [timeLeft, quizStarted, completeQuizStarted]);

    const selectRandomCountry = (forCompleteQuiz = false) => {
        let countryFound = false;
        const usedCountries = answers.map(answer => answer.country);

        while (!countryFound) {
            const randomIndex = Math.floor(Math.random() * countriesWithMetaTags.length);
            const selectedCountryData = countriesWithMetaTags[randomIndex];

            if (!usedCountries.includes(selectedCountryData.i.toUpperCase())) {
                setCurrentCountry(selectedCountryData.i.toUpperCase());
                setSelectedMetaTags([]);
                countryFound = true;
            }
        }
    };

    const handleStartQuiz = (isCompleteQuiz: boolean = false) => {
        window.scrollTo(0, 0);
        setQuizStarted(!isCompleteQuiz);
        setCompleteQuizStarted(isCompleteQuiz);
        setRetryQuiz(false);
        setCurrentRound(0);
        setSelectedMetaTags([]);
        setAnswers([]);
        setQuizFinished(false);
        setIsAnswering(false);
    };

    const handleRetryQuiz = (isCompleteQuiz: boolean = false) => {
        window.scrollTo(0, 0);
        setQuizStarted(!isCompleteQuiz);
        setCompleteQuizStarted(isCompleteQuiz);
        setRetryQuiz(true);
        setCurrentRound(0);
        setSelectedMetaTags([]);
        setAnswers([]);
        setQuizFinished(false);
        setIsAnswering(false);
    };

    const handleMetaTagButtonClick = (tag: string) => {
        setSelectedMetaTags((prev) => {
            if (prev.includes(tag)) {
                return prev.filter((t) => t !== tag);
            } else if (prev.length < 3) {
                return [...prev, tag];
            }
            return prev;
        });
    };

    const submitQuizResult = (quizType: string, quizResult: number) => {
        const formUrl = quizType === 'cMeta'
            ? `https://docs.google.com/forms/d/e/1FAIpQLSd3huPDp8iyWwSjMn3-q4edrEEsgD0O1jfL-QnXta2Y9qRpmA/formResponse?entry.1562507564=cMeta&entry.1128704523=${quizResult}`
            : `https://docs.google.com/forms/d/e/1FAIpQLSd3huPDp8iyWwSjMn3-q4edrEEsgD0O1jfL-QnXta2Y9qRpmA/formResponse?entry.1562507564=sMeta&entry.1128704523=${quizResult}`;

        fetch(formUrl, {
            method: 'POST',
            mode: 'no-cors'
        }).then(() => {
            console.log('Quiz result submitted successfully.');
        }).catch(error => {
            console.error('Error submitting quiz result:', error);
        });
    };

    const handleAnswer = () => {
        if (isAnswering) return;
        setIsAnswering(true);

        const correctMetaTags = countriesWithMetaTags.find(country => country.i.toUpperCase() === currentCountry)?.am || [];
        setAnswers([...answers, { country: currentCountry, selectedMetaTags, correctMetaTags }]);
        setCurrentRound(currentRound + 1);
        setSelectedMetaTags([]);
        setIsAnswering(false);

        // If it's the last question, submit the result
        if (currentRound >= TOTAL_QUESTIONS - 1) {
            const quizType = completeQuizStarted ? 'cMeta' : 'sMeta';
            const quizResult = correctAnswersCount;

            // Submit the result to Google Forms
            submitQuizResult(quizType, quizResult);

            // End the quiz
            setQuizFinished(true);
        }
    };

    const correctAnswersCount = answers.filter(answer => {
        const correctMetaTags = countriesWithMetaTags.find(country => country.i.toUpperCase() === answer.country)?.am || [];
        return JSON.stringify(answer.selectedMetaTags.sort()) === JSON.stringify(correctMetaTags.sort());
    }).length;

    const totalQuestions = completeQuizStarted ? countriesWithMetaTags.length : TOTAL_QUESTIONS;

    const renderMetaTagButton = (tag: string) => {
        const isActive = selectedMetaTags.includes(tag);
        const config = metaTagConfig[tag] || metaTagConfig['na'];

        return (
            <button
                key={tag}
                onClick={() => handleMetaTagButtonClick(tag)}
                className={`w-[40px] h-[40px] flex items-center justify-center font-bold font-inter rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid ${
                    isActive
                        ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-black'
                        : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                }`}
            >
                <div
                    className="w-[24px] h-[24px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: config.background }}
                >
                    <FontAwesomeIcon
                        icon={config.icon}
                        className="text-white text-xs"
                    />
                </div>
            </button>
        );
    };

    return (
        <>
            {!quizStarted && !completeQuizStarted && !quizFinished && (
                <div className="intro-container min-h-[60vh] p-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-center">
                    <div className="w-full max-w-4xl text-center">

                        <div className="flex justify-center">
                            <div className="w-full sm:w-1/2 text-left">
                                <div className="mb-1 flex justify-start">
                                    <Link to="/quiz" className="flex items-center text-gray-700 dark:text-gray-300 font-semibold tracking-tight hover:text-blue-600 dark:hover:text-blue-300 group">
                                        <FontAwesomeIcon className="mr-2 transform transition-transform duration-200 ease-in-out group-hover:-translate-x-2" icon={faArrowLeft} />
                                        Explore Quiz Library
                                    </Link>
                                </div>
                                <h1 className="text-4xl sm:text-5xl font-extrabold font-inter tracking-tight text-gray-900 dark:text-white mb-4 text-left">
                                    Meta Tags Quiz
                                </h1>
                                <p className="mt-2 text-lg font-inter text-gray-700 dark:text-gray-300 mb-4">
                                    Welcome to the Meta Tags Quiz! In each round, you'll see a highlighted country. Your task is to select the correct meta tags that apply to that country. Good luck!
                                </p>
                                <div className="flex justify-between font-inter text-gray-700 dark:text-gray-300">
                                    <div className="flex-1">
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faTags} /> 9 meta tags</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 19 countries</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faUser} /> 10 attempts</p>
                                    </div>
                                    <div className="flex-1">
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} /> {TOTAL_QUESTIONS} questions</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited rounds</p>
                                        <p className="mb-2"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</p>
                                    </div>
                                </div>
                                <div className="flex justify-between space-x-4 w-full sm:mt-5 mt-2">
                                    <button
                                        className="flex-grow bg-[#0f172a] text-white dark:bg-white dark:text-black hover:bg-transparent hover:text-black dark:hover:bg-transparent dark:hover:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center"
                                        onClick={() => handleStartQuiz(false)}
                                    >
                                        <FontAwesomeIcon icon={faPlay} className="mr-1" /> Start Quiz
                                    </button>
                                    <button
                                        className="flex-grow text-black dark:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                        onClick={() => handleStartQuiz(true)}
                                    >
                                        <FontAwesomeIcon icon={faMap} className="mr-1" /> Start Complete Quiz
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {(quizStarted || completeQuizStarted) && !quizFinished && (
                <>
                    {isMobile ? (
                        <div className="quiz-container-mobile overflow-hidden bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-between md:hidden">
                            <div className="quiz-content-mobile w-full max-w-screen-2xl flex-grow flex flex-col justify-center items-center relative">
                                <div className="quiz-map w-full relative" style={{ maxWidth: '100%', maxHeight: '75vh', overflow: 'hidden' }}>
                                    <QuizWorldMapAlt selectedCountry={currentCountry} />

                                    {currentCountry && (
                                        <div
                                            className="absolute bottom-0 left-0 mb-0 ml-2 p-2 bg-white dark:bg-[#121212] rounded-lg"
                                            style={{ maxWidth: '50px', pointerEvents: 'none' }}
                                        >
                                            <img
                                                src={process.env.PUBLIC_URL + flags[currentCountry]}
                                                alt={`${countryNames[currentCountry]} flag`}
                                                className="w-full h-auto"
                                                style={{ maxHeight: '50px', borderRadius: '4px' }}
                                            />
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className="quiz-panel-mobile w-full p-2 z-50 bg-white dark:bg-[#121212]">

                                <div className="w-full relative flex justify-between pl-8 pr-8 mt-[-1px] h-[40px] items-center">
                                    <div className="text-left">
                                        {currentCountry && (
                                            <>
                                                <p className="text-xl tracking-tight font-inter font-bold bg-clip-text">{countryNames[currentCountry]}</p>
                                            </>
                                        )}
                                    </div>

                                    <div className="absolute left-1/2 transform -translate-x-1/2 text-lg font-bold font-inter flex items-center space-x-8">
                                        <div className="mr-5">
                                            <FontAwesomeIcon icon={faClock} className="mr-1 text-lg" />
                                            <span className="inline-block w-[30px] text-center tabular-nums">{`00:${timeLeft.toString().padStart(2, '0')}`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center">
                                        <FontAwesomeIcon icon={faQuestionCircle} className="mr-1 text-lg" />
                                        <span className="inline-block font-inter font-bold text-lg text-center tabular-nums">{`${currentRound + 1} / ${totalQuestions}`}</span>
                                    </div>
                                </div>

                                <div className="flex gap-2 w-full pl-8 pr-8 mt-2">
                                    {['bt', 'sn', 'fcn', 'sr', 'fct', 'av', 'lc', 'fpa', 'bb'].map((tag) => renderMetaTagButton(tag))}
                                </div>

                                <div className="flex justify-between pl-8 pr-8 space-x-4 w-full mt-4">
                                    <button
                                        className={`flex-grow font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid text-center ${
                                            selectedMetaTags.length > 0 ?
                                                'bg-[#0f172a] dark:bg-white text-white dark:text-black border-[#0f172a] dark:border-white cursor-pointer' :
                                                'text-black dark:text-white border-[#0f172a] dark:border-white cursor-default'
                                        }`}
                                        onClick={handleAnswer}
                                        disabled={isAnswering || selectedMetaTags.length === 0}
                                    >
                                        <FontAwesomeIcon icon={faCheck} className="mr-1" /> {currentRound < totalQuestions - 1 ? 'Answer' : 'End Quiz'}
                                    </button>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <div className="quiz-container-computer hidden md:flex min-h-[86vh] p-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 items-center justify-center">
                            <div className="quiz-content w-full max-w-screen-2xl mx-auto flex flex-row justify-between items-start max-w-[1000px]">
                                <div className="quiz-map flex-grow p-2">
                                    <QuizWorldMapAlt selectedCountry={currentCountry} />
                                </div>
                                <div className="quiz-panel-computer flex-shrink-0 w-[200px] p-2 flex flex-col items-start justify-center">
                                    <div className="w-full mb-1 mt-1 text-left h-[80px]">
                                        {currentCountry && (
                                            <>
                                                <img
                                                    src={process.env.PUBLIC_URL + flags[currentCountry]}
                                                    alt={`${countryNames[currentCountry]} flag`}
                                                    className="w-12 h-auto mb-2"
                                                />
                                                <p className="text-3xl font-bold">{countryNames[currentCountry]}</p>
                                            </>
                                        )}
                                    </div>

                                    <div className="flex flex-wrap gap-x-2 gap-y-2 w-full mt-2">
                                        <div className="grid grid-cols-4 gap-2">
                                            {['bt', 'sn', 'fcn', 'sr'].map((tag) => renderMetaTagButton(tag))}
                                        </div>
                                        <div className="grid grid-cols-4 gap-2">
                                            {['fct', 'av', 'lc', 'fpa'].map((tag) => renderMetaTagButton(tag))}
                                        </div>
                                        <div className="grid grid-cols-3 gap-1">
                                            {['bb'].map((tag) => renderMetaTagButton(tag))}
                                        </div>
                                    </div>

                                    <div className="flex justify-between space-x-4 w-full mt-2">
                                        <button
                                            className={`flex-grow text-black dark:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center ${selectedMetaTags.length === 0 ? 'cursor-default' : 'hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black'}`}
                                            onClick={handleAnswer}
                                            disabled={isAnswering || selectedMetaTags.length === 0}
                                        >
                                            <FontAwesomeIcon icon={faCheck} className="mr-1" /> {currentRound < totalQuestions - 1 ? 'Answer' : 'End Quiz'}
                                        </button>
                                    </div>
                                    <div className="w-full pt-2 mt-1 text-center text-xl font-bold relative flex items-center justify-center">
                                        <svg key={animationKey} className="absolute inset-0 w-full h-16">
                                            <rect
                                                className={`timer-rect ${timeLeft <= 10 ? 'animate-pulse-red' : 'default-stroke'}`}
                                                x="2"
                                                y="6"
                                                width="180"
                                                height="30"
                                                rx="15"
                                                ry="15"
                                                pathLength="283"
                                                strokeDasharray="283"
                                                strokeDashoffset="50"
                                            />
                                        </svg>
                                        <span>{`00:${timeLeft.toString().padStart(2, '0')}`}</span>
                                    </div>
                                    <div className={`w-full mt-3 grid grid-cols-10 gap-x-2 gap-y-1`}>
                                        {Array.from({ length: totalQuestions }, (_, i) => (
                                            <div
                                                key={i}
                                                className={`w-4 h-4 rounded-full border-2 flex items-center justify-center text-[10px] mt-[-1px] ${i === currentRound ? 'animate-pulse' : ''} ${i < currentRound ? (answers[i]?.country ? 'border-[#0f172a] dark:border-white text-[#0f172a] dark:text-white' : 'border-gray-400 text-gray-400') : (i === currentRound ? 'border-[#0f172a] dark:border-white text-[#0f172a] dark:text-white animate-pulse' : 'border-gray-400 text-gray-400')}`}
                                            >
                                                {i + 1}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {quizFinished && (
                <div className="results-container min-h-[55vh] p-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-center">
                    <div className="w-full max-w-screen-lg mx-auto">
                        <div className="flex justify-between items-center mb-6">
                            <h1 className="text-4xl sm:text-5xl font-bold font-inter text-left">{completeQuizStarted ? 'Complete Quiz Results' : 'Results'}</h1>
                            <h1 className="text-4xl sm:text-5xl font-bold font-inter text-right">{correctAnswersCount} / {totalQuestions}</h1>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                            {answers.map((answer, index) => {
                                const isCorrect = JSON.stringify(answer.selectedMetaTags.sort()) === JSON.stringify(answer.correctMetaTags.sort());
                                const correctCountryFlag = flags[answer.country || ''];

                                return (
                                    <div key={index} className={`pl-2 pr-2 pt-2 pb-3 rounded-3xl border ${isCorrect ? 'bg-green-200' : 'bg-red-200'} ${isCorrect ? 'border-green-400 dark:border-green-600' : 'border-red-400 dark:border-red-600'} bg-opacity-20`}>
                                        <div className="flex items-center justify-center">
                                            {correctCountryFlag && (
                                                <img
                                                    src={process.env.PUBLIC_URL + correctCountryFlag}
                                                    alt="Flag"
                                                    className="mr-1 rounded"
                                                    style={{ width: '18px', height: 'auto', maxHeight: '18px' }}
                                                />
                                            )}
                                            <p className="text-center font-bold">{countryNames[answer.country || '']}</p>
                                        </div>

                                        <div className="flex justify-center mt-1 mb-2">
                                            <div className="text-center">{answer.correctMetaTags.map(tag => renderMetaTagButton(tag))}</div>
                                        </div>
                                        <div className="flex justify-center translate-x-[10px]">
                                            <div className="flex items-center justify-center">
                                                {answer.selectedMetaTags.map(tag => renderMetaTagButton(tag))}
                                                {isCorrect ? (
                                                    <FontAwesomeIcon className="text-green-500 ml-2" icon={faCheck} />
                                                ) : (
                                                    <FontAwesomeIcon className="text-red-500 ml-2" icon={faTimes} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-[#0f172a] text-white dark:bg-white dark:text-black hover:bg-transparent hover:text-black dark:hover:bg-transparent dark:hover:text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center"
                                onClick={() => handleStartQuiz(completeQuizStarted)}
                            >
                                <FontAwesomeIcon icon={faPlay} className="mr-1" /> Start New {completeQuizStarted ? 'Complete Quiz' : 'Quiz'}
                            </button>
                            <button
                                className="text-black dark:text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                onClick={() => handleRetryQuiz(completeQuizStarted)}
                            >
                                <FontAwesomeIcon icon={faRedo} className="mr-1" /> Retry this {completeQuizStarted ? 'complete' : ''} quiz
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default QuizMetaTags;
