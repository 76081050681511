import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapMarkerAlt,
    faGripLinesVertical,
    faFlag,
    faCar,
    faCamera,
    faRoad,
    faStop,
    faWalking,
    faTruckPickup,
    faClock,
    faBan,
    faLanguage, faTags,
    faRotateRight, faUser, faUsers, faQuestionCircle, faArrowPointer, faMousePointer
} from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';

import './Quiz.css';

const Quiz: React.FC = () => {
    const quizzes = [
        { name: "Flags", path: "/quiz/flags", colorClass: 'color-2', icon: faFlag },
        { name: "Bollards", path: "/quiz/bollards", colorClass: 'color-11', icon: faGripLinesVertical },
        { name: "License plates", path: "/quiz/plates", colorClass: 'color-10', icon: faCar },
        { name: "Road lines", path: "/quiz/road-lines", colorClass: 'color-4', icon: faRoad },
        { name: "Stop signs", path: "/quiz/stop-signs", colorClass: 'color-5', icon: faStop },
        { name: "Crosswalk sign", path: "/quiz/crosswalk-signs", colorClass: 'color-6', icon: faWalking },
        { name: "Google Car", path: "/quiz/car", colorClass: 'color-9', icon: faTruckPickup },
        { name: "Camera Gen", path: "/quiz/camera-gens", colorClass: 'color-1', icon: faCamera },
        { name: "Special letters", path: "/quiz/special-letters", colorClass: 'color-3', icon: 'Ø' },
        { name: "Unique letters", path: "/quiz/unique-letters", colorClass: 'color-7', icon: 'Ş' },
        { name: "Language Use", path: "/quiz/language-use", colorClass: 'color-3', icon: faLanguage },
        { name: "Meta Tags", path: "/quiz/meta-tags", colorClass: 'color-7', icon: faTags },
    ];

    const [hoveredQuiz, setHoveredQuiz] = useState<string | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to store the timeout ID

    useEffect(() => {
        document.title = "Quiz Library | GeographyHelp";
    }, []);

    const handleMouseEnter = (quizName: string) => {
        // Clear any existing timeout to avoid unwanted resetting to null
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setHoveredQuiz(quizName);
    };

    const handleMouseLeave = () => {
        // Set a delay before clearing the hoveredQuiz
        timeoutRef.current = setTimeout(() => {
            setHoveredQuiz(null);
        }, 300); // 0.5-second delay
    };

    return (
        <div className="min-h-[59vh] flex items-center justify-center">
            <div className="flex-grow pr-6 pl-6 pt-2 pb-1 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100">
                <div className="mx-auto max-w-4xl text-center mb-6 md:mb-8">
                    <h1 className="text-2xl sm:text-5xl font-bold font-inter tracking-tight text-gray-900 dark:text-white">
                        GeographyHelp Quiz Library
                    </h1>
                    <p className="mt-2 text-md sm:text-xl font-inter text-gray-700 dark:text-gray-300 block md:hidden">
                        Dive into quizzes that help you master country traits and improve your GeoGuessr skills.
                    </p>
                </div>

                <div className="max-w-4xl mx-auto mb-8 mt-[-20px] md:block hidden">
                    <div className="details">
                        {hoveredQuiz === null && (
                            <div className="flex flex-col items-center justify-center min-h-[85px]">
                                <p className="text-md sm:text-xl font-inter text-gray-700 dark:text-gray-300 text-center">
                                    Dive into quizzes that help you master country traits and improve your GeoGuessr skills.
                                </p>
                                <div className="text-sm mt-[2px] text-gray-600 dark:text-gray-400 text-center animate-pulse">
                                    <FontAwesomeIcon icon={faMousePointer} className="mr-[0px]" />
                                    <p className="mb-2">Hover over a quiz to see more details.</p>
                                </div>
                            </div>
                        )}
                        {hoveredQuiz === "Flags" && (
                            <>
                                <h2 className="text-xl font-inter font-bold">Flags</h2>
                                <div className="mt-[2px]  text-gray-700 dark:text-gray-300">
                                    <p className="font-inter mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faFlag} />79 flags</span>
                                    </p>
                                    <p className="font-inter mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} />79 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1 text-[#6441a5] dark:text-[#b9a3e3] animate-pulse"><FontAwesomeIcon className="mr-1" icon={faTwitch} />Twitch Integration</span>
                                    </p>
                                </div>
                                <div className="mt-[0px]  text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Bollards" && (
                            <>
                                <h2 className="text-xl font-bold">Bollards</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faGripLinesVertical} /> 99 bollards</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 44 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1 text-[#6441a5] dark:text-[#b9a3e3] animate-pulse"><FontAwesomeIcon className="mr-1" icon={faTwitch} />Twitch Integration</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "License plates" && (
                            <>
                                <h2 className="text-xl font-bold">License plates</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faCar} /> 87 plates</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 79 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1 text-[#6441a5] dark:text-[#b9a3e3] animate-pulse"><FontAwesomeIcon className="mr-1" icon={faTwitch} />Twitch Integration</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Road lines" && (
                            <>
                                <h2 className="text-xl font-bold">Road lines</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faRoad} /> 5 road lines</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 79 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Stop signs" && (
                            <>
                                <h2 className="text-xl font-bold">Stop signs</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faStop} /> 19 stop signs</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 79 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select stop signs</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Crosswalk sign" && (
                            <>
                                <h2 className="text-xl font-bold">Crosswalk sign</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faWalking} /> 27 crosswalk signs</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 27 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1 text-[#6441a5] dark:text-[#b9a3e3] animate-pulse"><FontAwesomeIcon className="mr-1" icon={faTwitch} />Twitch Integration</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Google Car" && (
                            <>
                                <h2 className="text-xl font-bold">Google Car</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faTruckPickup} /> 7 car types</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 78 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select car types</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Camera Gen" && (
                            <>
                                <h2 className="text-xl font-bold">Camera Gen</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faCamera} /> 4 cam generations</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 79 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select cam generations</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Special letters" && (
                            <>
                                <h2 className="text-xl font-bold">Special letters</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><span className="mr-1 font-bold">Ø</span> 101 letters</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 36 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1 text-[#6441a5] dark:text-[#b9a3e3] animate-pulse"><FontAwesomeIcon className="mr-1" icon={faTwitch} />Twitch Integration</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Unique letters" && (
                            <>
                                <h2 className="text-xl font-bold">Unique letters</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><span className="mr-1 font-bold">Ş</span> 53 letters</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 16 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1 text-[#6441a5] dark:text-[#b9a3e3] animate-pulse"><FontAwesomeIcon className="mr-1" icon={faTwitch} />Twitch Integration</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Language Use" && (
                            <>
                                <h2 className="text-xl font-bold">Language Use</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faLanguage} /> 49 language expressions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 49 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1 text-[#6441a5] dark:text-[#b9a3e3] animate-pulse"><FontAwesomeIcon className="mr-1" icon={faTwitch} />Twitch Integration</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select country</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {hoveredQuiz === "Meta Tags" && (
                            <>
                                <h2 className="text-xl font-bold">Meta Tags</h2>
                                <div className="mt-[2px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faTags} /> 9 meta tags</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} /> 19 countries</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faUser} />10 attempts</span>
                                    </p>
                                </div>
                                <div className="mt-[0px] text-gray-700 dark:text-gray-300">
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faArrowPointer} />Select meta tags</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faQuestionCircle} />10 questions</span>
                                    </p>
                                    <p className="mb-2 font-inter">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faClock} /> Time-limited</span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="pr-1"><FontAwesomeIcon className="mr-1" icon={faBan} /> No going back</span>
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="max-w-4xl mx-auto grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-4">
                    {quizzes.map((quiz, index) => (
                        <Link
                            to={quiz.path}
                            key={index}
                            onMouseEnter={() => handleMouseEnter(quiz.name)}
                            onMouseLeave={handleMouseLeave}
                            className={`relative p-4 rounded-lg shadow-lg transform transition-transform duration-200 font-inter text-lg text-center btn-hover ${quiz.colorClass} dark:text-[#121212] text-white`}
                        >
                            <div className="background bg-[#121212] dark:bg-white"></div>
                            <span className="label">{quiz.name}</span>
                            {typeof quiz.icon === 'string' ? (
                                <span className="icon">{quiz.icon}</span>
                            ) : (
                                <FontAwesomeIcon icon={quiz.icon} className="icon" />
                            )}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Quiz;
