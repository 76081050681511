import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlag,
    faMapMarkerAlt,
    faCar,
    faRoad,
    faSign,
    faWalking,
    faTruck,
    faCamera,
    faArrowsAltH,
    faGlobe,
    faLanguage,
    faPhone,
    faMoneyBill,
    faGamepad,
    faA,
    faGripLinesVertical,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import MapWorldMap from './MapWorldMap';
import MapFlags from './map/MapFlags';
import MapBollards from './map/MapBollards';
import MapPlates from './map/MapPlates';
import MapRoadLines from './map/MapRoadLines';
import MapStopSigns from './map/MapStopSigns';
import MapCrosswalkSigns from './map/MapCrosswalkSigns';
import MapCars from './map/MapCars';
import MapCameraGen from './map/MapCameraGen';
import MapSpecialLetters from './map/MapSpecialLetters';
import MapUniqueLetters from './map/MapUniqueLetters';
import MapDrivingSide from './map/MapDrivingSide';
import MapDomainSuffix from './map/MapDomainSuffix';
import MapName from './map/MapName';
import MapLanguageGroup from './map/MapLanguageGroup';
import MapLanguages from './map/MapLanguages';
import MapPhoneCode from './map/MapPhoneCode';
import MapCurrency from './map/MapCurrency';
import MapGameCategories from './map/MapGameCategories';
import MapMetaTags from './map/MapMetaTags';
import MapCapitalCities from './map/MapCapitalCities';
import MapLanguageUseExamples from './map/MapLanguageUseExamples';

export const clearMapElements = () => {
    const svg = document.getElementById('world-map');
    if (svg) {
        Array.from(svg.querySelectorAll(
            'image, .car-color, .flags, .camera-gen, .bollard, .plate, .road-line, .stop-sign, .crosswalk-sign, .car, .special-letter, .unique-letter, .driving-side, .domain-suffix, .name, .language-group, .languages, .phone-code, .currency, .game-category, .languages-badge, .languages-badge-text, .language-group-badge, .language-group-text, .game-category-badge, .game-category-text, .meta-tag-rect, .meta-tag-foreignObject, .meta-tag-div, .language-example-rect, .language-example-foreignObject, .language-example-div, .map-element' // Add .map-element
        )).forEach(el => el.remove());
    }
};

const Map: React.FC<{ defaultCategory?: string }> = ({ defaultCategory }) => {
    const [selectedCategory, setSelectedCategory] = useState<string | null>(defaultCategory || null);
    const [lockZoomPan, setLockZoomPan] = useState<boolean>(false);
    const [darkThemeMap, setDarkThemeMap] = useState<boolean>(false);
    const [highlightStreetView, setHighlightStreetView] = useState<boolean>(true);

    const navigate = useNavigate();
    const location = useLocation();

    // Effect to lock zoom and pan on smaller devices
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setLockZoomPan(true); // Lock zoom and pan on small screens
            } else {
                setLockZoomPan(false); // Unlock on larger screens
            }
        };

        handleResize(); // Check on initial load
        window.addEventListener('resize', handleResize); // Listen for window resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on unmount
        };
    }, []);

    useEffect(() => {
        const path = location.pathname;
        if (path.includes('flags')) {
            setSelectedCategory('Flags');
        } else if (path.includes('bollards')) {
            setSelectedCategory('Bollards');
        } else if (path.includes('plates')) {
            setSelectedCategory('Plates');
        } else if (path.includes('roadlines')) {
            setSelectedCategory('RoadLines');
        } else if (path.includes('stopsigns')) {
            setSelectedCategory('StopSigns');
        } else if (path.includes('crosswalksigns')) {
            setSelectedCategory('CrosswalkSigns');
        } else if (path.includes('cars')) {
            setSelectedCategory('Cars');
        } else if (path.includes('cameragen')) {
            setSelectedCategory('CameraGen');
        } else if (path.includes('specialletters')) {
            setSelectedCategory('SpecialLetters');
        } else if (path.includes('uniqueletters')) {
            setSelectedCategory('UniqueLetters');
        } else if (path.includes('drivingside')) {
            setSelectedCategory('DrivingSide');
        } else if (path.includes('domainsuffix')) {
            setSelectedCategory('DomainSuffix');
        } else if (path.includes('name')) {
            setSelectedCategory('Name');
        } else if (path.includes('languagegroup')) {
            setSelectedCategory('LanguageGroup');
        } else if (path.includes('languages')) {
            setSelectedCategory('Languages');
        } else if (path.includes('phonecode')) {
            setSelectedCategory('PhoneCode');
        } else if (path.includes('currency')) {
            setSelectedCategory('Currency');
        } else if (path.includes('gamecategories')) {
            setSelectedCategory('GameCategories');
        } else if (path.includes('metatags')) {
            setSelectedCategory('MetaTags');
        } else if (path.includes('capitalcities')) {
            setSelectedCategory('CapitalCities');
        } else if (path.includes('languageuseexamples')) {
            setSelectedCategory('LanguageUseExamples');
        }
    }, [location.pathname]);

    useEffect(() => {
        switch (selectedCategory) {
            case 'Flags':
                document.title = 'Flags map | GeographyHelp';
                break;
            case 'Bollards':
                document.title = 'Bollards map | GeographyHelp';
                break;
            case 'Plates':
                document.title = 'Plates map | GeographyHelp';
                break;
            case 'RoadLines':
                document.title = 'Road lines map | GeographyHelp';
                break;
            case 'StopSigns':
                document.title = 'Stop signs map | GeographyHelp';
                break;
            case 'CrosswalkSigns':
                document.title = 'Crosswalk signs map | GeographyHelp';
                break;
            case 'Cars':
                document.title = 'Cars map | GeographyHelp';
                break;
            case 'CameraGen':
                document.title = 'Camera gen map | GeographyHelp';
                break;
            case 'SpecialLetters':
                document.title = 'Special letters map | GeographyHelp';
                break;
            case 'UniqueLetters':
                document.title = 'Unique letters map | GeographyHelp';
                break;
            case 'DrivingSide':
                document.title = 'Driving side map | GeographyHelp';
                break;
            case 'DomainSuffix':
                document.title = 'Domain suffix map | GeographyHelp';
                break;
            case 'Name':
                document.title = 'Name map | GeographyHelp';
                break;
            case 'LanguageGroup':
                document.title = 'Language group map | GeographyHelp';
                break;
            case 'Languages':
                document.title = 'Languages map | GeographyHelp';
                break;
            case 'PhoneCode':
                document.title = 'Phone code map | GeographyHelp';
                break;
            case 'Currency':
                document.title = 'Currency map | GeographyHelp';
                break;
            case 'GameCategories':
                document.title = 'Game categories map | GeographyHelp';
                break;
            case 'MetaTags':
                document.title = 'Meta tags map | GeographyHelp';
                break;
            case 'CapitalCities':
                document.title = 'Capital cities map | GeographyHelp';
                break;
            case 'LanguageUseExamples':
                document.title = 'Language use map | GeographyHelp';
                break;
            default:
                document.title = 'GeographyHelp';
                break;
        }
    }, [selectedCategory]);

    const handleButtonClick = (action: string) => {
        setSelectedCategory(action);
        navigate(`/map/${action.toLowerCase()}`);
    };

    const buttonClassNames = (category: string) =>
        `text-black font-inter dark:text-white font-bold  pt-1 px-2 pb-1  rounded-full transition-colors duration-300 text-sm border-2 border-solid border-transparent dark:border-transparent ${
            selectedCategory === category
                ? '!bg-[#0f172a] dark:!bg-white !text-white dark:!text-[#121212]'
                : 'hover:border-[#0f172a] dark:hover:border-white hover:bg-transparent dark:hover:bg-transparent'
        }`;

    return (
        <div className="min-h-[86vh] mb-2 p-2 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-start">
            <div className="w-full max-w-screen-xl mx-auto flex flex-col justify-start items-center">
                <div className="flex-grow w-full relative">
                    <MapWorldMap lockZoomPan={lockZoomPan} darkThemeMap={darkThemeMap} highlightStreetView={highlightStreetView} />
                    {selectedCategory === 'Flags' && <MapFlags />}
                    {selectedCategory === 'Bollards' && <MapBollards />}
                    {selectedCategory === 'Plates' && <MapPlates />}
                    {selectedCategory === 'RoadLines' && <MapRoadLines />}
                    {selectedCategory === 'StopSigns' && <MapStopSigns />}
                    {selectedCategory === 'CrosswalkSigns' && <MapCrosswalkSigns />}
                    {selectedCategory === 'Cars' && <MapCars />}
                    {selectedCategory === 'CameraGen' && <MapCameraGen />}
                    {selectedCategory === 'SpecialLetters' && <MapSpecialLetters />}
                    {selectedCategory === 'UniqueLetters' && <MapUniqueLetters />}
                    {selectedCategory === 'DrivingSide' && <MapDrivingSide />}
                    {selectedCategory === 'DomainSuffix' && <MapDomainSuffix />}
                    {selectedCategory === 'Name' && <MapName />}
                    {selectedCategory === 'LanguageGroup' && <MapLanguageGroup />}
                    {selectedCategory === 'Languages' && <MapLanguages />}
                    {selectedCategory === 'PhoneCode' && <MapPhoneCode />}
                    {selectedCategory === 'Currency' && <MapCurrency />}
                    {selectedCategory === 'GameCategories' && <MapGameCategories />}
                    {selectedCategory === 'MetaTags' && <MapMetaTags />}
                    {selectedCategory === 'CapitalCities' && <MapCapitalCities />}
                    {selectedCategory === 'LanguageUseExamples' && <MapLanguageUseExamples />}
                </div>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-1.5 w-full mt-3">
                    <button className={buttonClassNames('Name')} onClick={() => handleButtonClick('Name')}>
                        <FontAwesomeIcon icon={faA} className="mr-1" /> Name
                    </button>
                    <button className={buttonClassNames('Flags')} onClick={() => handleButtonClick('Flags')}>
                        <FontAwesomeIcon icon={faFlag} className="mr-1" /> Flags
                    </button>
                    <button className={buttonClassNames('Bollards')} onClick={() => handleButtonClick('Bollards')}>
                        <FontAwesomeIcon icon={faGripLinesVertical} className="mr-1" /> Bollards
                    </button>
                    <button className={buttonClassNames('Plates')} onClick={() => handleButtonClick('Plates')}>
                        <FontAwesomeIcon icon={faCar} className="mr-1" /> License Plates
                    </button>
                    <button className={buttonClassNames('RoadLines')} onClick={() => handleButtonClick('RoadLines')}>
                        <FontAwesomeIcon icon={faRoad} className="mr-1" /> Road Lines
                    </button>
                    <button className={buttonClassNames('StopSigns')} onClick={() => handleButtonClick('StopSigns')}>
                        <FontAwesomeIcon icon={faSign} className="mr-1" /> Stop Signs
                    </button>
                    <button className={buttonClassNames('CrosswalkSigns')} onClick={() => handleButtonClick('CrosswalkSigns')}>
                        <FontAwesomeIcon icon={faWalking} className="mr-1" /> Crosswalk Sign
                    </button>
                    <button className={buttonClassNames('Cars')} onClick={() => handleButtonClick('Cars')}>
                        <FontAwesomeIcon icon={faTruck} className="mr-1" /> Google Car
                    </button>
                    <button className={buttonClassNames('CameraGen')} onClick={() => handleButtonClick('CameraGen')}>
                        <FontAwesomeIcon icon={faCamera} className="mr-1" /> Camera Gen
                    </button>
                    <button className={buttonClassNames('DrivingSide')} onClick={() => handleButtonClick('DrivingSide')}>
                        <FontAwesomeIcon icon={faArrowsAltH} className="mr-1" /> Driving Side
                    </button>
                    <button className={buttonClassNames('DomainSuffix')} onClick={() => handleButtonClick('DomainSuffix')}>
                        <FontAwesomeIcon icon={faGlobe} className="mr-1" /> Domain Suffix
                    </button>
                    <button className={buttonClassNames('PhoneCode')} onClick={() => handleButtonClick('PhoneCode')}>
                        <FontAwesomeIcon icon={faPhone} className="mr-1" /> Phone Code
                    </button>
                    <button className={buttonClassNames('LanguageGroup')} onClick={() => handleButtonClick('LanguageGroup')}>
                        <FontAwesomeIcon icon={faLanguage} className="mr-1" /> Language Group
                    </button>
                    <button className={buttonClassNames('Languages')} onClick={() => handleButtonClick('Languages')}>
                        <FontAwesomeIcon icon={faLanguage} className="mr-1" /> Languages
                    </button>
                    <button className={buttonClassNames('LanguageUseExamples')} onClick={() => handleButtonClick('LanguageUseExamples')}>
                        <FontAwesomeIcon icon={faLanguage} className="mr-1" /> Language Use
                    </button>
                    <button className={buttonClassNames('SpecialLetters')} onClick={() => handleButtonClick('SpecialLetters')}>
                        Ø Special Letters
                    </button>
                    <button className={buttonClassNames('UniqueLetters')} onClick={() => handleButtonClick('UniqueLetters')}>
                        Ş Unique Letters
                    </button>
                    <button className={buttonClassNames('Currency')} onClick={() => handleButtonClick('Currency')}>
                        <FontAwesomeIcon icon={faMoneyBill} className="mr-1" /> Currency
                    </button>
                    <button className={buttonClassNames('GameCategories')} onClick={() => handleButtonClick('GameCategories')}>
                        <FontAwesomeIcon icon={faGamepad} className="mr-1" /> Game Categories
                    </button>
                    <button className={buttonClassNames('MetaTags')} onClick={() => handleButtonClick('MetaTags')}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" /> Meta Tags
                    </button>
                    <button className={buttonClassNames('CapitalCities')} onClick={() => handleButtonClick('CapitalCities')}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" /> Capital Cities
                    </button>

                </div>
                <div className="w-full max-w-2xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-2 items-center justify-items-center mt-5">
                    <div className="flex items-center">
                        <label className="mr-2">Lock zoom & pan</label>
                        <div
                            role="checkbox"
                            aria-checked={lockZoomPan ? 'true' : 'false'}
                            tabIndex={0}
                            onKeyDown={({ key }) => key === 'Enter' && setLockZoomPan(!lockZoomPan)}
                            onClick={() => setLockZoomPan(!lockZoomPan)}
                            className={`cursor-pointer w-11 h-5 bg-blue-600 rounded-full relative px-1.5 flex items-center ${lockZoomPan ? '' : 'justify-end'}`}
                        >
                            <div
                                className={`w-4 h-4 rounded-full absolute transform duration-200 ease-out bg-white left-0.5 ${lockZoomPan ? 'translate-x-6' : 'translate-x-0'}`}
                            />
                            {lockZoomPan ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 448 512" fill="currentColor">
                                    <path d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 576 512" fill="currentColor">
                                    <path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80l0 48c0 17.7 14.3 32 32 32s32-14.3 32-32l0-48C576 64.5 511.5 0 432 0S288 64.5 288 144l0 48L64 192c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-192c0-35.3-28.7-64-64-64l-32 0 0-48z"/>
                                </svg>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <label className="mr-2">Use dark map</label>
                        <div
                            role="checkbox"
                            aria-checked={darkThemeMap ? 'true' : 'false'}
                            tabIndex={0}
                            onKeyDown={({ key }) => key === 'Enter' && setDarkThemeMap(!darkThemeMap)}
                            onClick={() => setDarkThemeMap(!darkThemeMap)}
                            className={`cursor-pointer w-11 h-5 bg-blue-600 rounded-full relative px-1.5 flex items-center ${darkThemeMap ? '' : 'justify-end'}`}
                        >
                            <div
                                className={`w-4 h-4 rounded-full absolute transform duration-200 ease-out bg-white left-0.5 ${darkThemeMap ? 'translate-x-6' : 'translate-x-0'}`}
                            />
                            {darkThemeMap ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a 1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <label className="mr-2">Highlight coverage</label>
                        <div
                            role="checkbox"
                            aria-checked={highlightStreetView ? 'true' : 'false'}
                            tabIndex={0}
                            onKeyDown={({ key }) => key === 'Enter' && setHighlightStreetView(!highlightStreetView)}
                            onClick={() => setHighlightStreetView(!highlightStreetView)}
                            className={`cursor-pointer w-11 h-5 bg-blue-600 rounded-full relative px-1.5 flex items-center ${highlightStreetView ? '' : 'justify-end'}`}
                        >
                            <div
                                className={`w-4 h-4 rounded-full absolute transform duration-200 ease-out bg-white left-0.5 ${highlightStreetView ? 'translate-x-6' : 'translate-x-0'}`}
                            />
                            {highlightStreetView ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 448 512" fill="currentColor">
                                    <path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 448 512" fill="currentColor">
                                    <path d="M384 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l320 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32z"/>
                                </svg>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Map;
