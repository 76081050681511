import React, { useEffect } from 'react';
import data from '../data.json';
import './MapDomainSuffix.css';
import { clearMapElements } from '../Map';

const MapDomainSuffix: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.dm) {
                    const isEurope = countryData.o === 'Europe';
                    const fontSize = isEurope ? 12 : 18;

                    const xPosition = path.getBBox().x + path.getBBox().width / 2;
                    const yPosition = path.getBBox().y + path.getBBox().height / 2;

                    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                    text.classList.add('domain-suffix');
                    text.setAttribute('x', xPosition.toString());
                    text.setAttribute('y', yPosition.toString());
                    text.setAttribute('text-anchor', 'middle');
                    text.setAttribute('font-size', fontSize.toString());
                    text.setAttribute('fill', 'red');
                    text.style.fontFamily = 'Inter, sans-serif';

                    text.textContent = countryData.dm;

                    svg.appendChild(text);
                }
            });
        }
    }, []);

    return null;
};

export default MapDomainSuffix;
