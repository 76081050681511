import React, { useEffect, useRef, useState, Suspense } from 'react';
import { SideNav } from '@hilla/react-components/SideNav.js';
import { SideNavItem } from '@hilla/react-components/SideNavItem.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faMousePointer, faGripLinesVertical, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {faTape, faCar, faBroadcastTower, faCircleDot, faBan, faArrowsUpDownLeftRight, faQuestionCircle, faEyeSlash, faArrowDown, faTruckPickup, faCircleNotch, faCarOn} from '@fortawesome/free-solid-svg-icons';
import { faCheck, faT, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import countryData from './data.json';
import coverageData from './coverage.json';
import './App.css';
import leData from './le.json';
const LazyFeedbackDialog = React.lazy(() => import('./FeedbackDialog'));
interface CountryData {
    i: string;
    f: string;
    n: string;
    d: string;
    svc?: string;
    dm: string;
    cu: string;
    nu: string;
    cg: number[];
    cl: string[];
    rl: string[];
    p: string[];
    cw: string;
    b: string[];
    s: string[];
    o: string;
    sl: string[];
    ul: string[];
    cc: string[];
    lg: string | string[];
    l: string[];
    le?: string;
    c: string[];
    am?: string[];
}


const continents = [
    { name: 'Africa', isDefault: true },
    { name: 'Asia', isDefault: true },
    { name: 'Europe', isDefault: true },
    { name: 'North America', isDefault: true },
    { name: 'South America', isDefault: false },
    { name: 'Australia and Oceania', isDefault: false }
];

const columns = [
    { key: 'd', l: 'Driving side', s: 'Driving', isMeta: false, isDefault: true },
    { key: 'dm', l: 'Domain', s: 'Domain', isMeta: false, isDefault: true },
    { key: 'rl', l: 'Roads', s: 'Roads\u2003', isMeta: false, isDefault: true },
    { key: 'p', l: 'License plates', s: 'License Plates\u2001\u2003\u2003', isMeta: false, isDefault: true },
    { key: 'cg', l: 'Camera gen', s: 'Cam', isMeta: true, isDefault: true },
    { key: 'cl', l: 'Car', s: 'Car', isMeta: true, isDefault: true },
    { key: 'sl', l: 'Special letters', s: 'Special Letters', isMeta: false, isDefault: true },
    { key: 'ul', l: 'Unique letters', s: 'Unique Letters', isMeta: false, isDefault: false },
    { key: 'le', l: 'Language example', s: 'Language Use', isMeta: false, isDefault: true },
    { key: 'cw', l: 'Crosswalk sign', s: 'Crosswalk', isMeta: false, isDefault: true },
    { key: 'b', l: 'Bollards', s: 'Bollards', isMeta: false, isDefault: true },
    { key: 'svc', l: 'Street View Coverage', s: 'Coverage', isMeta: true, isDefault: false },
    { key: 's', l: 'Stop sign', s: 'Stop', isMeta: false, isDefault: true },
    { key: 'nu', l: 'Phone code', s: 'Phone', isMeta: false, isDefault: true },
    { key: 'cu', l: 'Currency', s: 'Currency', isMeta: false, isDefault: true },
    { key: 'c', l: 'Game modes', s: 'Modes', isMeta: true, isDefault: false },
    { key: 'am', l: 'Meta tags', s: 'Meta', isMeta: true, isDefault: false },
    { key: 'cc', l: 'Capital city', s: 'CAPITAL', isMeta: false, isDefault: false },
    { key: 'lg', l: 'Language groups', s: 'GROUP', isMeta: false, isDefault: false },
    { key: 'l', l: 'Languages', s: 'LANGUAGES', isMeta: false, isDefault: false },
];


const defaultColumns = columns.filter(col => col.isDefault);
const metaColumns = columns.filter(col => col.isMeta);
const allColumns = columns;



const metaTagConfig: { [key: string]: { background: string; icon: IconDefinition; text: string } } = {
    bt: { background: '#2c3e50', icon: faTape, text: 'Black tape' },
    sn: { background: '#8e44ad', icon: faTruckPickup, text: 'Snorkel' },
    fcn: { background: '#c0392b', icon: faCarOn, text: 'Follow car (Nigeria)' },
    sr: { background: '#00BFFF', icon: faCircleNotch, text: 'Sky rifts' },
    fct: { background: '#c0392b', icon: faCarOn, text: 'Follow car (Tunisia)' },
    av: { background: '#d35400', icon: faBroadcastTower, text: 'Google car - antenna visible' },
    lc: { background: '#f39c12', icon: faArrowDown, text: 'Low placed camera' },
    fpa: { background: '#e759d6', icon: faCircleDot, text: 'Cam artifact - pink anomaly' },
    bb: { background: '#7f8c8d', icon: faEyeSlash, text: 'Abundance of blurred buildings' },
    na: { background: '#2c3e50', icon: faBan, text: 'No antenna' },
    lav: { background: '#ea1212', icon: faBroadcastTower, text: 'Long antenna visible' },
    wbc: { background: '#f2f3f5', icon: faCar, text: 'Google car - white back' },
    other: { background: '#34495e', icon: faQuestionCircle, text: 'Other' }
};

const ScrollingText: React.FC<{ text: string; className?: string }> = ({ text, className }) => {
    return (
        <div className={`scrolling-container relative overflow-hidden ${className}`}>
            <div className="scrolling-text inline-block whitespace-nowrap">
                {text}
            </div>
            <div className="fade fade-left"></div>
            <div className="fade fade-right"></div>
        </div>
    );
};

const renderGameModes = (modes: string[]) => {
    return (
        <div className="flex flex-col items-start gap-1">
            {modes.map((mode, index) => (
                <span
                    key={index}
                    className={`inline-flex items-center text-[10px] justify-center font-inter font-medium py-[0px] px-[6px] rounded-full ${
                        mode === 'br'
                            ? 'bg-orange-500 bg-opacity-70 text-orange-800'
                            : 'bg-yellow-500 bg-opacity-70 text-yellow-800'
                    }`}
                >
{mode === 'br' ? 'BR' : 'CS'}
</span>
            ))}
        </div>
    );
};

const TextCarousel: React.FC<{ items: string | string[]; className?: string; colorMap?: Record<string, string> }> = ({ items, className, colorMap }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState<'down' | 'up'>('down');
    const itemsArray = Array.isArray(items) ? items : [items];

    useEffect(() => {
        if (itemsArray.length > 1) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => {
                    if (direction === 'down') {
                        if (prevIndex === itemsArray.length - 1) {
                            setDirection('up');
                            return prevIndex - 1;
                        }
                        return prevIndex + 1;
                    } else {
                        if (prevIndex === 0) {
                            setDirection('down');
                            return prevIndex + 1;
                        }
                        return prevIndex - 1;
                    }
                });
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [itemsArray, direction]);

    if (itemsArray.length === 0) return null;
    if (itemsArray.length === 1) return <div className={`${className} text-carousel-item`} style={{ color: colorMap ? colorMap[itemsArray[0]] : undefined }}>{itemsArray[0]}</div>;

    return (
        <div className="text-carousel-container">
            <div
                className="text-carousel-inner"
                style={{
                    transform: `translateY(-${currentIndex * 20}px)`,
                    transition: 'transform 0.5s ease'
                }}
            >
                {itemsArray.map((item, index) => (
                    <div key={index} className={`${className} text-carousel-item`} style={{ color: colorMap ? colorMap[item] : undefined }}>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

const CarouselColumn: React.FC<{ images: string[], blurPlates: boolean }> = ({ images, blurPlates }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState<'down' | 'up'>('down');

    useEffect(() => {
        if (images.length > 1) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => {
                    if (direction === 'down') {
                        if (prevIndex === images.length - 1) {
                            setDirection('up');
                            return prevIndex - 1;
                        }
                        return prevIndex + 1;
                    } else {
                        if (prevIndex === 0) {
                            setDirection('down');
                            return prevIndex + 1;
                        }
                        return prevIndex - 1;
                    }
                });
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [images, direction]);

    if (images.length === 0) return null;
    if (images.length === 1) return (
        <img
            src={images[0]}
            className={`h-8 w-auto rounded-md ${blurPlates ? 'blur-md' : ''}`}
            alt="item"
        />
    );

    return (
        <div className="carousel-container">
            <div
                className="carousel-inner"
                style={{
                    transform: `translateY(-${currentIndex * (32 + 5)}px)`,
                    transition: 'transform 0.5s ease'
                }}
            >
                {images.map((image, index) => (
                    <div key={index} className="carousel-item">
                        <img
                            src={image}
                            className={`rounded-md ${blurPlates ? 'blur-md' : ''}`}
                            alt={`item-${index}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};



const Home: React.FC = () => {
    const defaultColumns = columns.filter(col => col.isDefault);
    const [countries, setCountries] = useState<CountryData[]>([]);
    const [filteredCountries, setFilteredCountries] = useState<CountryData[]>([]);
    const [hoveredBollard, setHoveredBollard] = useState<string | null>(null);
    const [selectedContinents, setSelectedContinents] = useState<string[]>(
        continents.filter(continent => continent.isDefault).map(continent => continent.name)
    );

    const [selectedColumns, setSelectedColumns] = useState<string[]>(
        defaultColumns.map(col => col.key)
    );


    const nonDefaultColumns = columns.filter(col => !col.isDefault);
    const metaColumns = columns.filter(col => col.isMeta);
    const nonMetaColumns = columns.filter(col => !col.isMeta);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [movableColumns, setMovableColumns] = useState<boolean>(false);
    const [columnOrder, setColumnOrder] = useState<string[]>(columns.map(col => col.key));
    const [draggingColumn, setDraggingColumn] = useState<string | null>(null);
    const [groupBy, setGroupBy] = useState<string | null>(null);
    const isLargeScreen = () => window.innerWidth >= 768;

    const tableRef = useRef<HTMLTableElement>(null);
    const headerRef = useRef<HTMLTableSectionElement>(null);
    const stickyHeaderRef = useRef<HTMLDivElement>(null);
    const [columnWidths, setColumnWidths] = useState<Record<string, string>>({});

    const orderedSelectedColumns = columnOrder.filter(colKey => selectedColumns.includes(colKey));
    const [blurPlates, setBlurPlates] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [feedbackType, setFeedbackType] = useState<'Report data issue' | 'Contribute new data' | 'Request new feature' | 'Other'>('Report data issue');
    const handleOpenDialog = (type: 'Report data issue' | 'Contribute new data' | 'Request new feature' | 'Other') => {
        setFeedbackType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const isMetaActive = () => {
        const metaKeys = metaColumns.map(col => col.key);
        return (
            selectedColumns.length === metaKeys.length &&
            selectedColumns.every(key => metaKeys.includes(key))
        );
    };
    const toggleMetaColumns = () => {
        const metaKeys = metaColumns.map(col => col.key);
        if (selectedColumns.length === metaKeys.length && selectedColumns.every(key => metaKeys.includes(key))) {
            setSelectedColumns([]);
        } else {
            setSelectedColumns(metaKeys);
        }
    };
    const isHideMetaActive = () => {
        const nonMetaKeys = nonMetaColumns.map(col => col.key);
        const metaKeys = metaColumns.map(col => col.key);

        return (
            selectedColumns.length === nonMetaKeys.length &&
            selectedColumns.every(key => nonMetaKeys.includes(key)) &&
            selectedColumns.every(key => !metaKeys.includes(key))
        );
    };

    const isDefaultActive = () => {
        const defaultKeys = defaultColumns.map(col => col.key);
        return (
            selectedColumns.length === defaultKeys.length &&
            selectedColumns.every(key => defaultKeys.includes(key))
        );
    };
    const toggleDefaultColumns = () => {
        const defaultKeys = defaultColumns.map(col => col.key);
        setSelectedColumns(defaultKeys);
    };const toggleHideMetaColumns = () => {
        const nonMetaKeys = nonMetaColumns.map(col => col.key);
        setSelectedColumns(nonMetaKeys);
    };
    useEffect(() => {
        document.title = "GeographyHelp";
    }, []);
    useEffect(() => {
        setColumnWidths({
            d: '60px',
            dm: '60px',
            p: '200px',
            rl: '80px',
            ul: '170px',
            sl: '170px',
            le: '170px',
            cw: '90px',
            s: '60px',
            nu: '60px',
            cu: '75px',
        });
    }, []);
    const renderMetaTags = (tags: string[]) => {
        return (
            <div className="flex gap-1">
                {tags.map((tag, index) => {
                    const config = metaTagConfig[tag] || metaTagConfig.other;
                    return (
                        <div
                            key={index}
                            className="relative group"
                            style={{ width: '24px', height: '24px' }}
                        >
                            <div
                                className="w-full h-full rounded-full flex items-center justify-center"
                                style={{ backgroundColor: config.background }}
                            >
                                <FontAwesomeIcon
                                    icon={config.icon}
                                    className="text-white dark:text-black text-xs"
                                />
                            </div>
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-600 whitespace-nowrap">
                                {config.text}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    const languageColors: Record<string, string> = {
        English: '#4169E1',
        Spanish: '#FF6347',
        Portuguese: '#3CB371',
        French: '#FFD700',
    };
    const languageGroupColors: Record<string, string> = {
        Latin: '#ff6384',
        Cyrillic: '#36a2eb',
        Hebrew: '#ffce56',
        Arabic: '#4bc0c0',
        Kana: '#9966ff',
        Kanji: '#ff9f40',
        Thai: '#ffcd56',
        Khmer: '#c9cbcf',
        Sinhala: '#e74c3c',
        Tamil: '#3498db',
        Bengali: '#2ecc71',
        Tibetan: '#9b59b6',
        Greek: '#f1c40f',
        other: '#7f8c8d',
    };
    useEffect(() => {
        const coverageMap = coverageData.countries.reduce((map, country) => {
            map[country.i] = country.cov;
            return map;
        }, {} as Record<string, string>);

        const filteredCountries = countryData.filter(country =>
            selectedContinents.includes(country.o)
        ).map(country => ({
            ...country,
            lg: Array.isArray(country.lg) ? country.lg : [country.lg],
            svc: coverageMap[country.i] || 'n'
        }));

        setCountries(filteredCountries);
        setFilteredCountries(filteredCountries);
    }, [selectedContinents]);




    useEffect(() => {
        const filtered = countries.filter(
            country =>
                selectedContinents.includes(country.o) &&
                country.n.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredCountries(filtered);
    }, [selectedContinents, searchQuery, countries]);


    const enableMovableColumns = () => {
        setMovableColumns(true);
    };
    const handleMouseEnter = (url: string) => {
        setTimeout(() => {
            setHoveredBollard(url);
        }, 800);
    };

    const handleMouseLeave = () => {
        setHoveredBollard(null);
    };

    const toggleContinent = (continentName: string) => {
        setSelectedContinents(prev =>
            prev.includes(continentName) ? prev.filter(c => c !== continentName) : [...prev, continentName]
        );
    };


    const toggleColumn = (columnKey: string) => {
        const newSelectedColumns = selectedColumns.includes(columnKey)
            ? selectedColumns.filter(key => key !== columnKey)
            : [...selectedColumns, columnKey];
        setSelectedColumns(newSelectedColumns);
    };


    const getDrivingSideBadge = (side: string) => {
        if (side === 'r') {
            return (
                <span
                    className="badge text-blue-400 dark:text-blue-800 bg-blue-400 dark:bg-blue-800 font-bold font-inter dark:bg-opacity-20 bg-opacity-20 rounded-lg px-1.5 py-0.5 text-xs"
                >
Right
</span>
            );
        } else if (side === 'l') {
            return (
                <span
                    className="badge font-bold font-inter text-red-400 bg-red-400 dark:text-red-700 dark:bg-red-700 dark:bg-opacity-20 bg-opacity-20 rounded-lg px-1.5 py-0.5 text-xs"
                >
Left
</span>
            );
        }
        return null;
    };

    const renderCurrency = (currency: string) => {
        const match = currency.match(/(.*)\((.*)\)/);
        if (match) {
            const [_, outside, inside] = match;
            return (
                <div className="max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="font-bold text-lg">{inside}</div>
                    <div className="text-[9px] mt-[-3px]">{outside}</div>
                </div>
            );
        }
        return <div>{currency}</div>;
    };

    const renderLanguageExample = (countryId: string) => {
        const languageExample = leData.find((entry) => entry.i === countryId)?.le;

        if (languageExample) {
            return (
                <ScrollingText text={languageExample} className="letters" />
            );
        }

        return <span>N/A</span>;
    };

    const renderCarTypes = (carTypes: string[]) => {
        const dotSize = 12;
        const gap = 3;
        const outlineColor = 'currentColor';

        return (
            <div className="flex">
                {carTypes.map((type, index) => {
                    if (type === 'Bars') {
                        return (
                            <svg key={index} width={dotSize * 1.8} height={dotSize * 1.5} style={{ margin: '0 2px', overflow: 'visible' }}>
                                <rect x={dotSize * 0.2} y={dotSize / 2 - dotSize / 6} width={dotSize * 1.2} height={dotSize / 3} fill="orange" />
                                <rect x={dotSize * 0.6} y={0} width={dotSize / 12} height={dotSize} fill="orange" />
                                <rect x={dotSize * 0.9} y={0} width={dotSize / 12} height={dotSize} fill="orange" />
                            </svg>
                        );
                    } else {
                        let fillColor = 'grey';
                        switch (type) {
                            case 'b':
                                fillColor = 'black';
                                break;
                            case 'bl':
                                fillColor = 'blue';
                                break;
                            case 'w':
                                fillColor = 'white';
                                break;
                            case 'r':
                                fillColor = 'red';
                                break;
                            case 'gr':
                                fillColor = 'grey';
                                break;
                            case 'bw':
                                return (
                                    <svg key={index} width={dotSize} height={dotSize} style={{ margin: '0 2px', overflow: 'visible' }}>
                                        <defs>
                                            <linearGradient id={`bwGradient${index}`} x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="50%" style={{ stopColor: 'black', stopOpacity: 1 }} />
                                                <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 1 }} />
                                            </linearGradient>
                                        </defs>
                                        <circle cx={dotSize / 2} cy={dotSize / 2} r={dotSize / 2 - 1} fill={`url(#bwGradient${index})`} stroke={outlineColor} strokeWidth="1" />
                                    </svg>
                                );
                            default:
                                fillColor = 'grey';
                        }
                        return (
                            <svg key={index} width={dotSize} height={dotSize} style={{ margin: '0 2px', overflow: 'visible' }}>
                                <circle cx={dotSize / 2} cy={dotSize / 2} r={dotSize / 2 - 1} fill={fillColor} stroke={outlineColor} strokeWidth="1" />
                            </svg>
                        );
                    }
                })}
            </div>
        );
    };
    const reportDataIssue = () => handleOpenDialog('Report data issue');
    const requestNewFeature = () => handleOpenDialog('Request new feature');
    const contributeNewData = () => handleOpenDialog('Contribute new data');
    const renderCameraGens = (cameraGens: number[]) => {
        const dotSize = 18;
        const gap = 3;

        const allGens = [1, 2, 3, 4];
        return (
            <div className="flex items-center" style={{ width: 'fit-content', paddingRight: 0 }}>
                {allGens.map((gen, index) => {
                    const isPresent = cameraGens.includes(gen);
                    let fillColor = isPresent ? 'grey' : 'transparent';
                    if (isPresent) {
                        switch (gen) {
                            case 1:
                                fillColor = 'orangered';
                                break;
                            case 2:
                                fillColor = 'orange';
                                break;
                            case 3:
                                fillColor = 'yellowgreen';
                                break;
                            case 4:
                                fillColor = 'lawngreen';
                                break;
                        }
                    }
                    return (
                        <div key={gen} style={{ marginRight: index < allGens.length - 1 ? gap : 0 }}>
                            <svg width={dotSize} height={dotSize}>
                                <circle cx={dotSize / 2} cy={dotSize / 2} r={dotSize / 2} fill={fillColor} />
                                {isPresent && (
                                    <text
                                        x="50%"
                                        y={dotSize / 2 + 5}
                                        textAnchor="middle"
                                        fontSize={dotSize / 1.2}
                                        fill="black"
                                        fontFamily="Inter, sans-serif"
                                        fontWeight="bold"
                                    >
                                        {gen}
                                    </text>
                                )}
                            </svg>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderLetters = (letters: string[], countryId: string) => {
        const text = letters.join(' ');
        if (countryId === 'be') {
            return <ScrollingText text={text} />;
        } else {
            return <span className="letters">{text}</span>;
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current && stickyHeaderRef.current && tableRef.current) {
                const tableTop = tableRef.current.getBoundingClientRect().top;
                if (tableTop <= 0) {
                    stickyHeaderRef.current.style.display = 'block';
                    stickyHeaderRef.current.style.position = 'fixed';
                    stickyHeaderRef.current.style.top = '0';
                    stickyHeaderRef.current.style.zIndex = '1000';
                    stickyHeaderRef.current.style.width = `${tableRef.current.clientWidth}px`;

                    const tableHeaders = headerRef.current.querySelectorAll('th');
                    const stickyHeaders = stickyHeaderRef.current.querySelectorAll('th');

                    tableHeaders.forEach((th, index) => {
                        stickyHeaders[index].style.width = `${th.clientWidth}px`;
                    });
                } else {
                    stickyHeaderRef.current.style.display = 'none';
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleDragStart = (event: React.DragEvent<HTMLTableHeaderCellElement>, columnKey: string) => {
        event.dataTransfer.setData('text/plain', columnKey);
        setDraggingColumn(columnKey);
    };
    const handleDrop = (event: React.DragEvent<HTMLTableHeaderCellElement>, targetColumnKey: string) => {
        event.preventDefault();
        const draggedColumnKey = event.dataTransfer.getData('text/plain');
        if (draggedColumnKey !== targetColumnKey) {
            const newOrder = [...columnOrder];
            const draggedIndex = newOrder.indexOf(draggedColumnKey);
            const targetIndex = newOrder.indexOf(targetColumnKey);
            newOrder.splice(draggedIndex, 1);
            newOrder.splice(targetIndex, 0, draggedColumnKey);
            setColumnOrder(newOrder);
            setDraggingColumn(null);
        }
    };
    const handleDragEnter = (event: React.DragEvent<HTMLTableHeaderCellElement>, targetColumnKey: string) => {
        event.preventDefault();
        const draggedColumnKey = draggingColumn;

        if (draggedColumnKey && draggedColumnKey !== targetColumnKey) {
            const newOrder = [...columnOrder];
            const draggedIndex = newOrder.indexOf(draggedColumnKey);
            const targetIndex = newOrder.indexOf(targetColumnKey);

            newOrder.splice(draggedIndex, 1);
            newOrder.splice(targetIndex, 0, draggedColumnKey);

            setColumnOrder(newOrder);
        }
    };
    const handleDragOver = (event: React.DragEvent<HTMLTableHeaderCellElement>) => {
        event.preventDefault();
    };
    const toggleGroupBy = (group: string | null) => {
        setGroupBy(group);
    };
    const groupCountries = (countries: CountryData[], groupBy: string | null) => {
        if (!groupBy) return countries;
        const grouped = countries.reduce((acc, country) => {
            const key = (country as any)[groupBy];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(country);
            return acc;
        }, {} as Record<string, CountryData[]>);
        return Object.values(grouped).flat();
    };
    return (
        <div className="min-h-screen pl-0 pr-0 pb-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100">
            <div className="flex justify-center mt-2">
                <div className="relative w-full max-w-sm mx-4 md:mx-10 lg:mx-auto mb-3">
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400"
                    />
                    <input
                        type="text"
                        className="w-full pl-10 pr-8 py-2 rounded-full bg-gray-100 dark:bg-[#2a2a2a] text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Search countries..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        autoFocus={isLargeScreen()}
                    />
                    {searchQuery && (
                        <button
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400"
                            onClick={() => setSearchQuery('')}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                </div>
            </div>
            <div className="mb-3 flex justify-center">
                <div className="block lg:hidden text-gray-600 dark:text-gray-400 flex items-center gap-2">
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} className="text-[15px]" />
                    <span className="font-inter text-[13px] ">Scroll vertically and horizontally to explore country details</span>
                </div>
            </div>
            <div className="flex">
                <div className="sticky-sidebar-container">
                    <SideNav className="bg-gray-100 dark:bg-[#1e1e1e] text-gray-900 dark:text-gray-100 p-[0px] flex-grow overflow-y-auto" style={{ width: '320px' }}>
                        <SideNavItem>
                            <span className="options-label mt-1 text-left text-black dark:text-white">Continents</span>
                            <div className="flex flex-wrap">
                                {continents.map((continent, index) => (
                                    <button
                                        key={index}
                                        onClick={() => toggleContinent(continent.name)}
                                        className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                            selectedContinents.includes(continent.name)
                                                ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                                : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                        }`}
                                    >
                                        {continent.name}
                                    </button>
                                ))}
                            </div>
                        </SideNavItem>
                        <SideNavItem className="pr-[0px]">
                            <h4 className="options-label text-left text-black dark:text-white">Columns</h4>
                            <div className="flex flex-wrap">
                                {allColumns.map((column, index) => (
                                    <button
                                        key={index}
                                        onClick={() => toggleColumn(column.key)}
                                        className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                            selectedColumns.includes(column.key)
                                                ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                                : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                        }`}
                                    >
                                        {column.l}
                                    </button>
                                ))}
                            </div>
                        </SideNavItem>
                        <SideNavItem>
                            <h4 className="options-label text-left text-black dark:text-white">Resources</h4>
                            <div className="flex flex-wrap">
                                <button
                                    onClick={!isDefaultActive() ? toggleDefaultColumns : undefined}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        isDefaultActive()
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white pointer-events-none'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Show Default
                                </button>
                                <button
                                    onClick={!isMetaActive() ? toggleMetaColumns : undefined}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        isMetaActive()
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white pointer-events-none'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Show Meta
                                </button>
                                <button
                                    onClick={!isHideMetaActive() ? toggleHideMetaColumns : undefined}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        isHideMetaActive()
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white pointer-events-none'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Hide Meta
                                </button>
                            </div>
                        </SideNavItem>
                        <SideNavItem>
                            <h4 className="options-label text-left text-black dark:text-white">GROUP BY</h4>
                            <div className="flex flex-wrap">
                                <button
                                    onClick={() => toggleGroupBy(null)}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === null
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    None
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('d')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 'd'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Driving side
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('cg')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 'cg'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Camera gen
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('cl')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 'cl'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Car
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('rl')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 'rl'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Roads
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('s')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 's'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Stop sign
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('c')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 'c'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Game modes
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('l')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 'l'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Language
                                </button>
                                <button
                                    onClick={() => toggleGroupBy('lg')}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        groupBy === 'lg'
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Language group
                                </button>
                            </div>
                        </SideNavItem>
                        <SideNavItem>
                            <h4 className="options-label text-left text-black dark:text-white">Options</h4>
                            <div className="flex flex-wrap">
                                <button
                                    onClick={() => setMovableColumns(!movableColumns)}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        movableColumns
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Enable moving columns
                                </button>

                                <button
                                    onClick={reportDataIssue}
                                    className="m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white"
                                >
                                    Report data issue
                                </button>

                                <button
                                    onClick={requestNewFeature}
                                    className="m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white"
                                >
                                    Request new feature
                                </button>

                                <button
                                    onClick={contributeNewData}
                                    className="m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white"
                                >
                                    Contribute new data
                                </button>
                                <button
                                    onClick={() => setBlurPlates(!blurPlates)}
                                    className={`m-1 text-[13px] mr-0 mb-0 font-inter text-center transition-colors duration-300 px-1 py-0 rounded-3xl border-2 border-solid ${
                                        blurPlates
                                            ? 'bg-[#0f172a] text-white dark:bg-white dark:text-black border-[#0f172a] dark:border-white'
                                            : 'bg-transparent text-black dark:text-white border-transparent hover:border-[#0f172a] dark:hover:border-white'
                                    }`}
                                >
                                    Blur license plates
                                </button>
                            </div>

                        </SideNavItem>
                    </SideNav>
                    <SideNav className="bg-gray-100 dark:bg-[#1e1e1e] text-gray-900 dark:text-gray-100 p-[0px] sticky bottom-0" style={{ width: '320px' }}>
                        <SideNavItem>
                            <div className="flex flex-wrap ml-1">
                                <ul className="text-black dark:text-white text-[12px]">
                                    <li className="flex items-center mb-[2px]">
                                        <FontAwesomeIcon icon={faMousePointer} className="mr-[4px] ml-[1px] text-[10px]" />
                                        Click a country's row to expand for more details
                                    </li>
                                    <li className="flex items-center mb-[2px]">
                                        <FontAwesomeIcon icon={faGripLinesVertical} className="mr-[5px] ml-[2px] text-[10px]" />
                                        Hover over a bollard to enlarge
                                    </li>

                                    <li className="flex items-center mb-[6px]">
                                        <FontAwesomeIcon icon={faTriangleExclamation} className="mr-[3px] ml-[-1px] text-[10px]" />
                                        Some data might be incorrect or outdated
                                    </li>
                                </ul>
                            </div>
                        </SideNavItem>
                    </SideNav>
                </div>
                <div className="w-full overflow-x-auto">
                    <div className="hidden md:block">
                    <div
                        className="sticky-header bg-white/60 dark:bg-[#1a1a1a]/60 text-gray-900 dark:text-gray-100 backdrop-blur-sm"
                        ref={stickyHeaderRef}
                        style={{ display: 'none', top: 0, position: 'sticky', zIndex: 3000 }}
                    >
                        <table className="w-full border-collapse">
                            <thead>
                            <tr>
                                <th className="p-2 max-w-[160px] w-[160px] whitespace-nowrap overflow-hidden text-left sticky left-0 z-[5000]">Country</th>                                {orderedSelectedColumns.map(columnKey => (
                                    <th
                                        key={columnKey}
                                        draggable={movableColumns}
                                        onDragStart={(event) => handleDragStart(event, columnKey)}
                                        onDragEnter={(event) => handleDragEnter(event, columnKey)}
                                        onDrop={(event) => handleDrop(event, columnKey)}
                                        onDragOver={movableColumns ? handleDragOver : undefined}
                                        className="p-2 text-left sticky top-0 whitespace-nowrap "
                                        style={{
                                            maxWidth: columnWidths[columnKey] || 'auto',
                                            width: columnWidths[columnKey] || 'auto'
                                        }}
                                    >
                                        {columns.find(col => col.key === columnKey)?.s}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                        </table>
                    </div></div>

                    <table className="w-full bg-white dark:bg-[#1a1a1a] text-gray-900 dark:text-gray-100 border-collapse" ref={tableRef}>
                        <thead ref={headerRef}>
                        <tr>
                            <th className="p-2 text-left max-w-[160px] w-[160px] whitespace-nowrap overflow-hidden left-0 bg-white dark:bg-[#1a1a1a]">Country</th>
                            {orderedSelectedColumns.map((columnKey) => (
                                <th
                                    key={columnKey}
                                    draggable={movableColumns}
                                    onDragStart={(event) => handleDragStart(event, columnKey)}
                                    onDragEnter={(event) => handleDragEnter(event, columnKey)}
                                    onDrop={(event) => handleDrop(event, columnKey)}
                                    onDragOver={movableColumns ? handleDragOver : undefined}
                                    className="p-2 text-left sticky top-0 bg-white dark:bg-[#1a1a1a] whitespace-nowrap"
                                    style={{
                                        maxWidth: columnWidths[columnKey] || 'auto',
                                        width: columnWidths[columnKey] || 'auto'
                                    }}
                                >
                                    {columns.find(col => col.key === columnKey)?.s}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {groupCountries(filteredCountries, groupBy).map((country, countryIndex) => (
                            <tr key={countryIndex} className="hover:bg-gray-100 dark:hover:bg-[#222222] group">
                                <td className="p-2 border-b border-gray-300 dark:border-[#2e2e2e] max-w-[160px] w-[160px] sticky left-0 bg-white dark:bg-[#1a1a1a] group-hover:bg-gray-100 dark:group-hover:bg-[#222222]" style={{ zIndex: 900 }}>
                                    <div className="flex items-center">
                                        {country.f && (
                                            <img
                                                src={country.f}
                                                className="w-8 h-auto rounded-md mr-2"
                                                alt="flag"
                                            />
                                        )}
                                        <span className="letters whitespace-nowrap overflow-hidden">{country.n}</span></div>
                                </td>
                                {orderedSelectedColumns.map((columnKey) => (
                                    <td
                                        key={columnKey}
                                        className="p-2 border-b border-gray-300 dark:border-[#2e2e2e]"
                                        style={{
                                            maxWidth: columnWidths[columnKey] || "auto",
                                            width: columnWidths[columnKey] || "auto",
                                        }}
                                    >
                                        {columnKey === "d" ? (
                                            getDrivingSideBadge(country.d)
                                        ) : columnKey === "rl" ? (
                                            <CarouselColumn images={country.rl || []} blurPlates={false} />
                                        ) : columnKey === 'svc' ? (
                                                country.svc === 'y' ? (
                                                    <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                                                ) : country.svc === 't' ? (
                                                    <FontAwesomeIcon icon={faT} className="text-yellow-500" />
                                                ) : (
                                                    <FontAwesomeIcon icon={faXmark} className="text-red-500" />
                                                )
                                        ) : columnKey === "p" ? (
                                            <div style={{ maxWidth: columnWidths["p"] || "auto", overflow: "hidden" }}>
                                                <CarouselColumn images={country.p || []} blurPlates={blurPlates} />
                                            </div>
                                        ) : columnKey === "s" ? (
                                            <CarouselColumn images={country.s || []} blurPlates={false} />
                                        ) : columnKey === "am" && country.am ? (
                                            renderMetaTags(country.am)
                                        ) : columnKey === "cw" && country.cw ? (
                                            <img
                                                src={country.cw}
                                                className="h-10 w-auto rounded-md"
                                                alt="crosswalk sign"
                                            />
                                        ) : columnKey === "b" && country.b && country.b.length > 0 ? (
                                            <div className="flex flex-wrap gap-1">
                                                {country.b.map((url, i) => (
                                                    <div
                                                        key={i}
                                                        className="relative"
                                                        onMouseEnter={() => handleMouseEnter(url)}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <img
                                                            src={url}
                                                            className="h-10 w-auto rounded-md"
                                                            alt="bollard"
                                                        />
                                                        {hoveredBollard === url && (
                                                            <div className="absolute bottom-[2px] left-1/4 transform -translate-x-1/4 w-32 pointer-events-none">
                                                                <img
                                                                    src={url}
                                                                    className="h-32 w-auto rounded-md"
                                                                    alt="bollard large"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : columnKey === "cu" ? (
                                            renderCurrency(country.cu)
                                        ) : columnKey === "cl" && country.cl ? (
                                            renderCarTypes(country.cl)
                                        ) : columnKey === "cg" && country.cg && country.cg.length > 0 ? (
                                            renderCameraGens(country.cg)
                                        ) : columnKey === "c" && country.c ? (
                                            renderGameModes(country.c)
                                        ) : columnKey === "sl" && country.sl && country.sl.length > 0 ? (
                                            <ScrollingText text={country.sl.join(" ")} className="letters" />
                                        ) : columnKey === "ul" && country.ul && country.ul.length > 0 ? (
                                            renderLetters(country.ul, country.i)
                                        ) : columnKey === "le" ? (
                                            renderLanguageExample(country.i)
                                        ) : columnKey === "cc" && country.cc ? (
                                            <TextCarousel items={country.cc} className="capital-text" />
                                        ) : columnKey === "lg" && country.lg ? (
                                            <TextCarousel items={country.lg} className="language-group-text" colorMap={languageGroupColors} />
                                        ) : columnKey === "l" && country.l ? (
                                            <TextCarousel items={country.l} className="language-text" colorMap={languageColors} />
                                        ) : (
                                            country[columnKey as keyof CountryData] || ""
                                        )}
                                    </td>
                                ))}

                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {dialogOpen && (
                <div style={{ height: '0px', overflow: 'hidden' }}>
                    <Suspense fallback={<div style={{ height: '0px' }}></div>}>
                        <LazyFeedbackDialog isOpen={dialogOpen} onClose={handleCloseDialog} selectedFeedbackType={feedbackType} />
                    </Suspense>
                </div>
            )}
        </div>
    );
};

export default Home;
