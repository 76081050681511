import React, { useEffect } from 'react';
import data from '../data.json';
import {clearMapElements} from "../Map";

const MapPlates: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.p && countryData.p.length > 0) {
                    let plateWidth = 60;
                    let plateHeight = 20;
                    if (countryData.o === 'Europe') {
                        plateWidth = 40;
                        plateHeight = 6.67;
                    }
                    const totalHeight = plateHeight * countryData.p.length;
                    countryData.p.forEach((plate, index) => {
                        const plateImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                        plateImg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', plate);
                        plateImg.setAttribute('width', plateWidth.toString());
                        plateImg.setAttribute('height', plateHeight.toString());
                        const xPosition = path.getBBox().x + path.getBBox().width / 2 - plateWidth / 2;
                        const yPosition = path.getBBox().y + path.getBBox().height / 2 - totalHeight / 2 + index * plateHeight;
                        plateImg.setAttribute('x', xPosition.toString());
                        plateImg.setAttribute('y', yPosition.toString());
                        svg.appendChild(plateImg);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapPlates;
