import React, { useEffect } from 'react';
import data from '../data.json';
import { clearMapElements } from '../Map';

const MapCameraGen: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.cg && countryData.cg.length > 0) {
                    const isEurope = countryData.o === 'Europe';
                    const dotSize = isEurope ? 8 : 16;
                    const gap = isEurope ? 2 : 3;

                    const totalWidth = dotSize * countryData.cg.length + gap * (countryData.cg.length - 1);
                    countryData.cg.forEach((gen, index) => {
                        const xPosition = path.getBBox().x + path.getBBox().width / 2 - totalWidth / 2 + index * (dotSize + gap);
                        const yPosition = path.getBBox().y + path.getBBox().height / 2;

                        const dot = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                        dot.classList.add('camera-gen');
                        dot.setAttribute('cx', xPosition.toString());
                        dot.setAttribute('cy', yPosition.toString());
                        dot.setAttribute('r', (dotSize / 2).toString());

                        let fillColor = 'grey';
                        switch (gen) {
                            case 1:
                                fillColor = 'orangered';
                                break;
                            case 2:
                                fillColor = 'orange';
                                break;
                            case 3:
                                fillColor = 'yellowgreen';
                                break;
                            case 4:
                                fillColor = 'lawngreen';
                                break;
                            default:
                                break;
                        }

                        dot.setAttribute('fill', fillColor);
                        svg.appendChild(dot);

                        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                        text.classList.add('camera-gen');
                        text.setAttribute('x', xPosition.toString());
                        text.setAttribute('y', (yPosition + dotSize / 4).toString());
                        text.setAttribute('text-anchor', 'middle');
                        text.setAttribute('font-size', (dotSize / 1.5).toString());
                        text.setAttribute('fill', 'black');
                        text.textContent = gen.toString();
                        svg.appendChild(text);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapCameraGen;
