import React, { useEffect } from 'react';
import data from '../data.json';
import { clearMapElements } from '../Map';

const MapCrosswalkSigns: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.cw) {
                    const crosswalkImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                    crosswalkImg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', countryData.cw);

                    let crosswalkWidth = 20;
                    let crosswalkHeight = 20;

                    if (countryData.o === 'Europe') {
                        crosswalkWidth = 15;
                        crosswalkHeight = 15;
                    }

                    crosswalkImg.setAttribute('width', crosswalkWidth.toString());
                    crosswalkImg.setAttribute('height', crosswalkHeight.toString());
                    crosswalkImg.setAttribute('x', (path.getBBox().x + path.getBBox().width / 2 - crosswalkWidth / 2).toString());
                    crosswalkImg.setAttribute('y', (path.getBBox().y + path.getBBox().height / 2 - crosswalkHeight / 2).toString());
                    svg.appendChild(crosswalkImg);
                }
            });
        }
    }, []);

    return null;
};

export default MapCrosswalkSigns;
