import React, { useEffect } from 'react';
import data from '../data.json';
import './MapLanguages.css';
import {clearMapElements} from "../Map";
const languageColors: Record<string, { background: string; color: string }> = {
    English: { background: 'rgba(65, 105, 225, 0.5)', color: 'black' },
    Spanish: { background: 'rgba(255, 99, 71, 0.5)', color: 'black' },
    Portuguese: { background: 'rgba(60, 179, 113, 0.5)', color: 'black' },
    French: { background: 'rgba(255, 215, 0, 0.5)', color: 'black' },
};
const MapLanguages: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();
            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.l && countryData.l.length > 0) {
                    const isEurope = countryData.o === 'Europe';
                    const fontSize = isEurope ? 6 : 10;
                    const badgeHeight = fontSize * 1.5;
                    const gap = fontSize * 0.5;

                    const xPosition = path.getBBox().x + path.getBBox().width / 2;
                    const yPosition = path.getBBox().y + path.getBBox().height / 2;

                    let yOffset = 0;

                    if (isEurope) {
                        // Text for European countries
                        const lineHeight = fontSize * 1.2;
                        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                        text.classList.add('languages');
                        text.setAttribute('x', xPosition.toString());
                        text.setAttribute('y', yPosition.toString());
                        text.setAttribute('text-anchor', 'middle');
                        text.setAttribute('font-size', fontSize.toString());
                        text.style.fill = 'red';
                        text.style.fontFamily = 'Inter, sans-serif';

                        countryData.l.forEach((language, index) => {
                            const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
                            tspan.setAttribute('x', xPosition.toString());
                            tspan.setAttribute('dy', index === 0 ? '0' : lineHeight.toString());
                            tspan.textContent = language;
                            text.appendChild(tspan);
                        });

                        svg.appendChild(text);
                    } else {
                        countryData.l.forEach((language, index) => {
                            if (languageColors[language]) {
                                const colors = languageColors[language];
                                const badgeWidth = language.length * fontSize * 0.6 + fontSize;

                                const badge = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                                badge.classList.add('languages-badge');
                                badge.setAttribute('x', (xPosition - badgeWidth / 2).toString());
                                badge.setAttribute('y', (yPosition - badgeHeight / 2 + yOffset).toString());
                                badge.setAttribute('width', badgeWidth.toString());
                                badge.setAttribute('height', badgeHeight.toString());
                                badge.setAttribute('rx', (badgeHeight / 2).toString());
                                badge.setAttribute('ry', (badgeHeight / 2).toString());
                                badge.setAttribute('fill', colors.background);

                                const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                                text.classList.add('languages-badge-text');
                                text.setAttribute('x', xPosition.toString());
                                text.setAttribute('y', (yPosition + fontSize / 2 + yOffset).toString());
                                text.setAttribute('text-anchor', 'middle');
                                text.setAttribute('font-size', fontSize.toString());
                                text.textContent = language;

                                svg.appendChild(badge);
                                svg.appendChild(text);

                                yOffset += badgeHeight + gap;
                            }
                        });
                        const remainingLanguages = countryData.l.filter(language => !languageColors[language]);
                        remainingLanguages.forEach((language, index) => {
                            const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                            text.classList.add('languages');
                            text.setAttribute('x', xPosition.toString());
                            text.setAttribute('y', (yPosition + yOffset + index * (fontSize * 1.2)).toString());
                            text.setAttribute('text-anchor', 'middle');
                            text.setAttribute('font-size', fontSize.toString());
                            text.style.fill = 'red';
                            text.style.fontFamily = 'Inter, sans-serif';
                            text.textContent = language;
                            svg.appendChild(text);
                        });
                    }
                }
            });
        }
    }, []);

    return null;
};

export default MapLanguages;
