import React, { useEffect } from 'react';
import data from '../data.json';
import { clearMapElements } from '../Map';

const MapBollards: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.b && countryData.b.length > 0) {
                    let bollardWidth = 15;
                    let bollardHeight = 50;
                    let gap = 5;
                    if (countryData.o === 'Europe') {
                        bollardWidth = 5;
                        bollardHeight = 22;
                        gap = 3;
                    }

                    countryData.b.forEach((bollard, index) => {
                        const bollardImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                        bollardImg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', bollard);
                        bollardImg.setAttribute('width', bollardWidth.toString());
                        bollardImg.setAttribute('height', bollardHeight.toString());
                        const xPosition = path.getBBox().x + path.getBBox().width / 2 - (bollardWidth * countryData.b.length + gap * (countryData.b.length - 1)) / 2 + index * (bollardWidth + gap);
                        const yPosition = path.getBBox().y + path.getBBox().height / 2 - bollardHeight / 2;
                        bollardImg.setAttribute('x', xPosition.toString());
                        bollardImg.setAttribute('y', yPosition.toString());
                        svg.appendChild(bollardImg);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapBollards;
