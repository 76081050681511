import React, { useEffect } from 'react';
import data from '../data.json';
import './MapMetaTags.css';
import { clearMapElements } from '../Map';

const metaTagConfig: { [key: string]: { background: string; color: string; text: string } } = {
    bt: { background: '#2c3e50', color: '#ecf0f1', text: 'Black tape' },
    sn: { background: '#8e44ad', color: '#ecf0f1', text: 'Snorkel' },
    fcn: { background: '#c0392b', color: '#ecf0f1', text: 'Follow car' },
    sr: { background: '#00BFFF', color: '#ecf0f1', text: 'Sky rifts' },
    fct: { background: '#c0392b', color: '#ecf0f1', text: 'Follow car' },
    av: { background: '#d35400', color: '#ecf0f1', text: 'Antenna' },
    lc: { background: '#f39c12', color: '#ecf0f1', text: 'Low cam' },
    fpa: { background: '#16a085', color: '#ecf0f1', text: 'Pink anomaly' },
    bb: { background: '#7f8c8d', color: '#ecf0f1', text: 'Blurriness' },
    na: { background: '#2c3e50', color: '#ecf0f1', text: 'No antenna' },
    lav: { background: '#8e44ad', color: '#ecf0f1', text: 'Long anten' },
    wbc: { background: '#f2f3f5', color: '#2b363a', text: 'White back' },
    other: { background: '#34495e', color: '#ecf0f1', text: 'Other' }
};

const MapMetaTags: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();

            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.am && countryData.am.length > 0) {
                    const isEurope = countryData.o === 'Europe';
                    const rectWidth = isEurope ? 44 : 85;
                    const rectHeight = isEurope ? 12 : 18;

                    countryData.am.forEach((metaTag, index) => {
                        const config = metaTagConfig[metaTag] || metaTagConfig['other'];
                        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                        const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
                        const div = document.createElement('div');

                        const totalWidth = rectWidth * countryData.am.length + 2 * (countryData.am.length - 1);
                        const rectX = path.getBBox().x + path.getBBox().width / 2 - totalWidth / 2 + index * (rectWidth + 2);
                        const rectY = path.getBBox().y + path.getBBox().height / 2 - rectHeight / 2;

                        rect.classList.add('meta-tag-rect');
                        rect.setAttribute('x', rectX.toString());
                        rect.setAttribute('y', rectY.toString());
                        rect.setAttribute('width', rectWidth.toString());
                        rect.setAttribute('height', rectHeight.toString());
                        rect.setAttribute('rx', (rectHeight / 2).toString());
                        rect.setAttribute('ry', (rectHeight / 2).toString());
                        rect.setAttribute('fill', config.background);

                        foreignObject.setAttribute('x', rectX.toString());
                        foreignObject.setAttribute('y', rectY.toString());
                        foreignObject.setAttribute('width', rectWidth.toString());
                        foreignObject.setAttribute('height', rectHeight.toString());
                        foreignObject.classList.add('meta-tag-foreignObject');

                        div.style.display = 'flex';
                        div.style.alignItems = 'center';
                        div.style.justifyContent = 'center';
                        div.style.width = '100%';
                        div.style.height = '100%';
                        div.style.color = config.color;
                        div.style.fontSize = isEurope ? '8px' : '14px';
                        div.style.fontFamily = 'Arial, sans-serif';
                        div.style.textAlign = 'center';
                        div.style.overflow = 'hidden';
                        div.style.whiteSpace = 'pre-wrap';
                        div.style.lineHeight = '1';
                        div.classList.add('meta-tag-div');

                        div.textContent = config.text;

                        foreignObject.appendChild(div);
                        svg.appendChild(rect);
                        svg.appendChild(foreignObject);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapMetaTags;
