import { useEffect, useState, useContext, useCallback } from 'react';
import tmi from 'tmi.js';
import { TwitchContext } from './TwitchAuthManager';

interface TwitchChatProps {
    onMessageReceived: (messageData: { username: string; message: string; messageId: string; questionNumber: number }) => void;
    currentQuestion: number;
    isChatLocked: boolean;  // New prop to control message processing
}

const TwitchChatListener: React.FC<TwitchChatProps> = ({ onMessageReceived, currentQuestion, isChatLocked }) => {
    const { twitchUser } = useContext(TwitchContext);
    const [client, setClient] = useState<tmi.Client | null>(null);
    const [processedMessages, setProcessedMessages] = useState<Map<string, number>>(new Map());

    const debounceMessage = useCallback((messageId: string, username: string, message: string) => {
        if (isChatLocked) return;  // Prevent processing if chat is locked

        const now = Date.now();
        const lastProcessed = processedMessages.get(messageId);

        if (!lastProcessed || now - lastProcessed > 1000) {
            processedMessages.set(messageId, now);
            setProcessedMessages(new Map(processedMessages));

            if (message.length === 2) {
                onMessageReceived({ username, message, messageId, questionNumber: currentQuestion });
            }
        }
    }, [processedMessages, onMessageReceived, currentQuestion, isChatLocked]);

    useEffect(() => {
        if (!twitchUser) return;

        const client = new tmi.Client({
            channels: [twitchUser],
        });

        client.connect().catch(console.error);

        client.on('message', (channel, tags, message, self) => {
            if (self || isChatLocked) return;  // Prevent message handling if chat is locked

            const messageId = `${tags.id}-${tags['tmi-sent-ts']}`;
            const username = tags['display-name'] || tags['username'];
            if (username && message) {
                debounceMessage(messageId, username, message);
            }
        });

        setClient(client);

        return () => {
            if (client) {
                client.disconnect().catch(console.error);
            }
        };
    }, [twitchUser, debounceMessage, isChatLocked]);

    return null;
};

export default TwitchChatListener;
