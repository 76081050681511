import React, { useEffect } from 'react';
import data from '../data.json';
import './MapCommon.css';
import {clearMapElements} from "../Map";

const languageGroupColors: { [key: string]: { background: string; color: string } } = {
    Latin: { background: 'rgba(255, 99, 132, 0.5)', color: '#ff6384' },
    Cyrillic: { background: 'rgba(54, 162, 235, 0.5)', color: '#36a2eb' },
    Hebrew: { background: 'rgba(255, 206, 86, 0.5)', color: '#ffce56' },
    Arabic: { background: 'rgba(75, 192, 192, 0.5)', color: '#4bc0c0' },
    Kana: { background: 'rgba(153, 102, 255, 0.5)', color: '#9966ff' },
    Kanji: { background: 'rgba(255, 159, 64, 0.5)', color: '#ff9f40' },
    Thai: { background: 'rgba(255, 205, 86, 0.5)', color: '#ffcd56' },
    Khmer: { background: 'rgba(201, 203, 207, 0.5)', color: '#c9cbcf' },
    Sinhala: { background: 'rgba(231, 76, 60, 0.5)', color: '#e74c3c' },
    Tamil: { background: 'rgba(52, 152, 219, 0.5)', color: '#3498db' },
    Bengali: { background: 'rgba(46, 204, 113, 0.5)', color: '#2ecc71' },
    Tibetan: { background: 'rgba(155, 89, 182, 0.5)', color: '#9b59b6' },
    Greek: { background: 'rgba(241, 196, 15, 0.5)', color: '#f1c40f' },
    other: { background: 'rgba(127, 140, 141, 0.5)', color: '#7f8c8d' },
};
const MapLanguageGroup: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();
            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.lg) {
                    const isEurope = countryData.o === 'Europe';
                    const badgeWidth = isEurope ? 23 : 45;
                    const badgeHeight = isEurope ? 8 : 16;
                    const fontSize = isEurope ? 6 : 12;
                    const languageGroups = Array.isArray(countryData.lg) ? countryData.lg : countryData.lg.split(',');
                    const xPosition = path.getBBox().x + path.getBBox().width / 2 - badgeWidth / 2;
                    const yPosition = path.getBBox().y + path.getBBox().height / 2 - badgeHeight / 2;
                    languageGroups.forEach((lg, index) => {
                        const colors = languageGroupColors[lg.trim()] || languageGroupColors['other'];
                        const badge = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

                        const badgeX = xPosition;
                        const badgeY = yPosition + index * (badgeHeight + 4);

                        badge.classList.add('language-group-badge');
                        badge.setAttribute('x', badgeX.toString());
                        badge.setAttribute('y', badgeY.toString());
                        badge.setAttribute('width', badgeWidth.toString());
                        badge.setAttribute('height', badgeHeight.toString());
                        badge.setAttribute('rx', (badgeHeight / 2).toString());
                        badge.setAttribute('ry', (badgeHeight / 2).toString());
                        badge.setAttribute('fill', colors.background);

                        text.classList.add('language-group-text');
                        text.setAttribute('x', (badgeX + badgeWidth / 2).toString());
                        text.setAttribute('y', (badgeY + badgeHeight / 2 + (isEurope ? 2 : 5)).toString());
                        text.setAttribute('text-anchor', 'middle');
                        text.setAttribute('font-size', fontSize.toString());
                        text.setAttribute('fill', colors.color);
                        text.style.fontFamily = 'Inter, sans-serif';

                        text.textContent = lg.trim();

                        svg.appendChild(badge);
                        svg.appendChild(text);
                    });
                }
            });
        }
    }, []);

    return null;
};

export default MapLanguageGroup;
