import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faTimes,
    faPlay,
    faGlobe,
    faMap,
    faArrowLeft,
    faUser,
    faGripLinesVertical, faCertificate,
} from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import QuizWorldMapTwitch from '../QuizWorldMapTwitch';
import bollardsData from '../data.json';
import '../QuizTwitch.css';
import { TwitchContext } from '../TwitchAuthManager';
import TwitchChatListener from '../TwitchChatListener';
import { Dialog } from '@hilla/react-components/Dialog.js';
import { Button } from '@hilla/react-components/Button.js';

const QuizTwitchBollards: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const twitchPanelRef = useRef<HTMLDivElement>(null);

    const quizType = searchParams.get('type');
    const TOTAL_QUESTIONS = quizType === 'complete' ? bollardsData.reduce((acc, country) => acc + country.b.length, 0) : 20;

    const [quizStarted, setQuizStarted] = useState(false);
    const [currentRound, setCurrentRound] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [answers, setAnswers] = useState<Array<{ country: string | null, bollard: string | null, streamerName: string | null, correctCountry: string | null }>>([]);
    const [currentBollard, setCurrentBollard] = useState<string | null>(null);
    const [currentCountry, setCurrentCountry] = useState<string | null>(null);
    const [quizFinished, setQuizFinished] = useState(false);
    const [chatTimeLeft, setChatTimeLeft] = useState(30);  // Chat Timer State
    const [userTimeLeft, setUserTimeLeft] = useState(20);  // User Timer State
    const [bollardSequence, setBollardSequence] = useState<string[]>([]);
    const [animationKey, setAnimationKey] = useState(0);
    const [isAnswering, setIsAnswering] = useState(false);
    const [isChatLocked, setIsChatLocked] = useState(false);

    const [messageLog, setMessageLog] = useState<Array<{ username: string; message: string; messageId: string; questionNumber: number }>>([]);
    const [correctAnswersPerQuestion, setCorrectAnswersPerQuestion] = useState<number[]>([]);
    const [incorrectAnswersPerQuestion, setIncorrectAnswersPerQuestion] = useState<number[]>([]);
    const [correctCounter, setCorrectCounter] = useState(0);
    const [incorrectCounter, setIncorrectCounter] = useState(0);

    const { twitchUser } = useContext(TwitchContext);

    const [dialogOpened, setDialogOpened] = useState(false);
    const [leaderboardData, setLeaderboardData] = useState<Array<{ username: string, correct: number, incorrect: number }>>([]);

    const debouncedOpen = useCallback(() => {
        setDialogOpened(true);
    }, []);
    const debouncedClose = useCallback(() => {
        setDialogOpened(false);
    }, []);

    const getCountryDataById = (id: string) => {
        return bollardsData.find(country => country.i.toLowerCase() === id.toLowerCase());
    };

    useEffect(() => {
        document.title = "Bollards Twitch Quiz | GeographyHelp";
    }, []);

    const handleTwitchMessage = useCallback((messageData: { username: string; message: string; messageId: string; questionNumber: number }) => {
        const { username, message, messageId, questionNumber } = messageData;

        if (!isChatLocked && questionNumber === currentRound) {
            const existingMessageIndex = messageLog.findIndex(
                (msg) => msg.username === username && msg.questionNumber === currentRound
            );

            if (existingMessageIndex === -1) {
                setMessageLog((prevLog) => [...prevLog, { username, message, messageId, questionNumber }]);
            }
        }
    }, [messageLog, currentRound, isChatLocked]);


    useEffect(() => {
        if (!quizStarted) {
            setQuizStarted(true);
        }
    }, []);

    useEffect(() => {
        if (quizStarted && currentRound < TOTAL_QUESTIONS) {
            startNewRound();
        } else if (quizStarted && currentRound >= TOTAL_QUESTIONS) {
            setQuizFinished(true);
            submitQuizResult();
            calculateFinalLeaderboard();
        }
    }, [quizStarted, currentRound]);

    useEffect(() => {
        if (chatTimeLeft > 0) {
            const chatTimerId = setInterval(() => {
                setChatTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(chatTimerId);
        } else if (!isChatLocked) {
            handleLockChatAnswers();
        }
    }, [chatTimeLeft, isChatLocked]);

    useEffect(() => {
        if (userTimeLeft > 0 && isChatLocked) {
            const userTimerId = setInterval(() => {
                setUserTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(userTimerId);
        } else if (userTimeLeft === 0 && isChatLocked) {
            handleAutoAnswer();
        }
    }, [userTimeLeft, isChatLocked]);

    useEffect(() => {
        if (twitchPanelRef.current) {
            twitchPanelRef.current.scrollTop = twitchPanelRef.current.scrollHeight;
        }
    }, [answers, correctAnswersPerQuestion, incorrectAnswersPerQuestion]);

    useEffect(() => {
        const updateCounters = () => {
            let correctCount = 0;
            let incorrectCount = 0;

            messageLog.forEach((msg) => {
                if (msg.questionNumber === currentRound) {
                    if (msg.message.toUpperCase() === currentCountry) {
                        correctCount++;
                    } else {
                        incorrectCount++;
                    }
                }
            });

            setCorrectCounter(correctCount);
            setIncorrectCounter(incorrectCount);
        };

        if (!isChatLocked) {
            const intervalId = setInterval(updateCounters, 2000);
            return () => clearInterval(intervalId);
        }
    }, [messageLog, currentRound, currentCountry, isChatLocked]);

    const startNewRound = () => {
        if (currentRound < TOTAL_QUESTIONS) {
            selectRandomBollard();
            setChatTimeLeft(30);
            setUserTimeLeft(20);
            setAnimationKey((prevKey) => prevKey + 1);
            setIsChatLocked(false);
            setSelectedCountry(null);
        }
    };

    const selectRandomBollard = () => {
        let bollardFound = false;
        const usedBollards = new Set(bollardSequence);
        const allBollards = bollardsData.flatMap(country => country.b.map(bollard => ({ bollard, country: country.i.toUpperCase() })));

        while (!bollardFound) {
            const randomIndex = Math.floor(Math.random() * allBollards.length);
            const selectedBollardData = allBollards[randomIndex];

            if (!usedBollards.has(selectedBollardData.bollard)) {
                setCurrentBollard(selectedBollardData.bollard);
                setCurrentCountry(selectedBollardData.country);
                setBollardSequence((prev) => [...prev, selectedBollardData.bollard]);
                bollardFound = true;
            }
        }
    };

    const handleLockChatAnswers = () => {
        if (!quizFinished) {
            setIsChatLocked(true);
            setUserTimeLeft(20);
            setAnimationKey((prevKey) => prevKey + 1);
        }
    };

    const handleAutoAnswer = () => {
        if (!isAnswering && !quizFinished) {
            setIsAnswering(true);

            const newAnswer = {
                country: selectedCountry || null,
                bollard: currentBollard,
                streamerName: twitchUser || 'Unknown',
                correctCountry: currentCountry,
            };

            setAnswers((prevAnswers) => {
                const updatedAnswers = [...prevAnswers];
                updatedAnswers[currentRound] = newAnswer;
                return updatedAnswers;
            });

            setCorrectAnswersPerQuestion((prev) => [...prev, correctCounter]);
            setIncorrectAnswersPerQuestion((prev) => [...prev, incorrectCounter]);

            setCorrectCounter(0);
            setIncorrectCounter(0);

            if (currentRound < TOTAL_QUESTIONS - 1) {
                setCurrentRound((prevRound) => prevRound + 1);
            } else {
                setQuizFinished(true);
                submitQuizResult();
                calculateFinalLeaderboard();
            }

            setSelectedCountry(null);
            setIsAnswering(false);
        }
    };

    const correctAnswersCount = answers.filter(answer => answer.country === answer.correctCountry).length;

    const totalCorrectTwitch = correctAnswersPerQuestion.reduce((sum, val) => sum + val, 0);
    const totalIncorrectTwitch = incorrectAnswersPerQuestion.reduce((sum, val) => sum + val, 0);
    const totalTwitchGuesses = totalCorrectTwitch + totalIncorrectTwitch;
    const twitchSuccessRate = totalTwitchGuesses > 0 ? (totalCorrectTwitch / totalTwitchGuesses) * 100 : 0;

    const userSuccessRate = TOTAL_QUESTIONS > 0 ? (correctAnswersCount / TOTAL_QUESTIONS) * 100 : 0;

    const generateColor = (percentage: number) => {
        const red = percentage < 50 ? 255 : Math.round(255 - (percentage * 2 - 100) * 2.55);
        const green = percentage > 50 ? 255 : Math.round((percentage * 2) * 2.55);
        return `rgb(${red}, ${green}, 0)`;
    };

    const userColor = generateColor(userSuccessRate);
    const twitchColor = generateColor(twitchSuccessRate);

    const circleSize = 'w-4 h-4';
    const fontSize = 'text-[10px] mt-[-1px]';
    const gridCols = `grid-cols-${Math.min(TOTAL_QUESTIONS, 10)}`;

    const submitQuizResult = () => {
        const quizTypeParam = quizType === 'complete' ? 'cTwitchBollards' : 'sTwitchBollards';
        const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSc1F2JkaDkPNaTCC4kEs_JCq-aTNgijz7QXZgrCa6QFnYcUiA/formResponse?entry.392090693=${quizTypeParam}&entry.132931942=${correctAnswersCount}&entry.1410989314=${totalCorrectTwitch}&entry.683922648=${totalIncorrectTwitch}`;

        fetch(formUrl, {
            method: 'POST',
            mode: 'no-cors',
        }).then(() => {
            console.log('Quiz results submitted successfully');
        }).catch((error) => {
            console.error('Error submitting quiz results:', error);
        });
    };

    // Calculate the final leaderboard after the quiz finishes
    const calculateFinalLeaderboard = () => {
        const finalLeaderboard = new Map<string, { correct: number, incorrect: number }>();

        messageLog.forEach((msg) => {
            const userStats = finalLeaderboard.get(msg.username) || { correct: 0, incorrect: 0 };

            // Find the correct country for the current question
            const correctCountry = answers[msg.questionNumber]?.correctCountry;

            if (correctCountry && msg.message.toUpperCase() === correctCountry) {
                userStats.correct += 1;
            } else {
                userStats.incorrect += 1;
            }

            finalLeaderboard.set(msg.username, userStats);
        });

        const sortedLeaderboard = Array.from(finalLeaderboard.entries())
            .map(([username, stats]) => ({ username, ...stats }))
            .sort((a, b) => b.correct - a.correct);

        setLeaderboardData(sortedLeaderboard.slice(0, 10));
    };

    return (
        <>
            {quizStarted && !quizFinished && (
                <div className="quiz-container min-h-[50vh] md:min-h-screen pl-2 pr-2 pt-4 pb-4 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex items-center justify-center">
                    <TwitchChatListener onMessageReceived={handleTwitchMessage} currentQuestion={currentRound} isChatLocked={isChatLocked} />
                    <div className="quiz-content w-full max-w-screen pl-[2px] pr-[2px] mx-auto flex flex-col md:flex-row justify-between items-center md:items-start md:min-h-[90vh] relative">
                        <div className="quiz-map flex-grow p-2 h-full">
                            <QuizWorldMapTwitch onSelectCountry={setSelectedCountry} selectedCountry={selectedCountry} />
                        </div>
                        <div className="quiz-panel-user hidden md:flex flex-shrink-0 w-full md:w-[200px] p-2 flex-col items-start justify-center">
                            {currentBollard && (
                                <img
                                    src={process.env.PUBLIC_URL + currentBollard}
                                    alt="Bollard"
                                    className="w-full rounded-lg mb-4"
                                    style={{ maxHeight: '300px', objectFit: 'contain' }}
                                />
                            )}
                            <div className="w-full mb-1 mt-1 text-left h-[80px]">
                                {selectedCountry ? (
                                    <>
                                        <p className="text-lg">Selected country:</p>
                                        <p className="text-3xl font-bold">{getCountryDataById(selectedCountry)?.n}</p>
                                    </>
                                ) : (
                                    <>
                                        <p className="text-lg">Selected country:</p>
                                        <p className="text-3xl font-bold">-</p>
                                    </>
                                )}
                            </div>
                            <div className="flex justify-between space-x-4 w-full">
                                <button
                                    className={`flex-grow text-black dark:text-white font-bold py-2 md:py-3 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center ${isAnswering ? 'cursor-default opacity-50' : 'hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black'}`}
                                    onClick={isChatLocked ? handleAutoAnswer : handleLockChatAnswers}
                                    disabled={isAnswering || (isChatLocked && !selectedCountry)}
                                >
                                    {isChatLocked ? (
                                        <>
                                            <FontAwesomeIcon icon={faCheck} className="mr-1" /> {currentRound < TOTAL_QUESTIONS - 1 ? 'Answer' : 'End Quiz'}
                                        </>
                                    ) : (
                                        'Lock Chat Answers'
                                    )}
                                </button>
                            </div>
                            <div className="w-full pt-2 mt-1 text-center text-xl font-bold relative flex items-center justify-center">
                                <svg key={animationKey} className="absolute inset-0 w-full h-16">
                                    <rect
                                        className={`${
                                            isChatLocked ? 'timer-rect-user' : 'timer-rect-chat'
                                        } ${isChatLocked && userTimeLeft <= 10 ? 'animate-pulse-red-user' : (!isChatLocked && chatTimeLeft <= 10 ? 'animate-pulse-red-chat' : 'default-stroke')}`}
                                        x="2"
                                        y="6"
                                        width="180"
                                        height="30"
                                        rx="15"
                                        ry="15"
                                        pathLength={isChatLocked ? "283" : "425"}
                                        strokeDasharray={isChatLocked ? "283" : "425"}
                                        strokeDashoffset="50"
                                    />
                                </svg>
                                <span>{`00:${(isChatLocked ? userTimeLeft : chatTimeLeft).toString().padStart(2, '0')}`}</span>
                            </div>
                            <div className={`w-full mt-3 grid ${gridCols} gap-x-2 gap-y-1`}>
                                {Array.from({ length: TOTAL_QUESTIONS }, (_, i) => (
                                    <div
                                        key={i}
                                        className={`${circleSize} rounded-full border-2 flex items-center justify-center ${fontSize} ${i === currentRound ? 'animate-pulse' : ''} ${i < currentRound ? (answers[i]?.country ? 'border-[#0f172a] dark:border-white text-[#0f172a] dark:text-white' : 'border-gray-400 text-gray-400') : (i === currentRound ? 'border-[#0f172a] dark:border-white text-[#0f172a] dark:text-white animate-pulse' : 'border-gray-400 text-gray-400')}`}
                                    >
                                        {i + 1}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="quiz-panel-twitch font-inter hidden md:flex flex-shrink-0 w-full md:w-[400px] p-2 flex-col items-start justify-center">
                            <div className="flex justify-between items-center w-full mb-4">
                                <h2 className="text-md font-bold">Bollards Twitch Quiz</h2>
                                <div className="ml-auto flex items-center" style={{ minWidth: '120px' }}>
                                    <h2 className="text-md  text-right w-full  animate-pulse">{twitchUser || 'Unknown User'}</h2>
                                    <span className="ml-2 h-2 w-2 bg-red-500 rounded-full animate-pulse"></span>
                                </div>
                            </div>

                            <div className="flex items-start mt-1 mb-4">
                                <FontAwesomeIcon
                                    icon={faTwitch}
                                    className={`text-gray-500 mt-[-1px] text-[32px] mr-1 ${
                                        !isChatLocked ? 'animate-pulse text-green-500' : ''
                                    }`}
                                />
                                <span className="ml-[3px] text-sm font-inter text-gray-500 mt-[-6px]">
                                    Enter a country’s abbreviation to submit your guess. Only the first guess per question will be counted.
                                </span>
                            </div>
                            <div
                                ref={twitchPanelRef}
                                className="w-full overflow-y-auto"
                                style={{ maxHeight: '500px' }}
                            >
                                <table className="table-auto w-full text-center border-collapse">
                                    <thead>
                                    <tr>
                                        <th className="border-b  border-gray-300 dark:border-gray-700  p-2">
                                            <FontAwesomeIcon icon={faGripLinesVertical} className="text-lg" />
                                        </th>
                                        <th className="border-b  border-gray-300 dark:border-gray-700  p-2">
                                            <FontAwesomeIcon icon={faCheck} className="text-lg text-green-500" />
                                        </th>
                                        <th className="border-b  border-gray-300 dark:border-gray-700  p-2">
                                            <FontAwesomeIcon icon={faTimes} className="text-lg text-red-500" />
                                        </th>
                                        <th className="border-b  border-gray-300 dark:border-gray-700  p-2">
                                            <FontAwesomeIcon icon={faUser} className="text-lg" />
                                        </th>
                                        <th className="border-b  border-gray-300 dark:border-gray-700  p-2">
                                            <FontAwesomeIcon icon={faGlobe} className="text-lg" />
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Array.from({ length: correctAnswersPerQuestion.length }, (_, i) => (
                                        <tr key={i}>
                                            <td className="border-b  border-gray-300 dark:border-gray-700  items-center text-center p-[2px]">
                                                {answers[i]?.bollard ? (
                                                    <div className="flex justify-center items-center">
                                                        <img
                                                            src={process.env.PUBLIC_URL + answers[i].bollard}
                                                            alt="Bollard"
                                                            style={{ width: '15px', height: '34px', objectFit: 'contain' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <span>{i + 1}</span>
                                                )}
                                            </td>

                                            <td className="border-b  border-gray-300 dark:border-gray-700  p-2 text-green-500">
                                                {correctAnswersPerQuestion[i] || 0}
                                            </td>
                                            <td className="border-b  border-gray-300 dark:border-gray-700  p-2 text-red-500">
                                                {incorrectAnswersPerQuestion[i] || 0}
                                            </td>
                                            <td className="border-b border-gray-300 dark:border-gray-700 p-1 whitespace-nowrap">
                                                {answers[i]?.country && (
                                                    <div className="flex items-center justify-center space-x-1 max-w-[125px] overflow-hidden">
                                                        <img
                                                            src={getCountryDataById(answers[i].country || '')?.f || ''}
                                                            alt="Flag"
                                                            className="rounded"
                                                            style={{ width: '15px', height: 'auto', maxHeight: '15px' }}
                                                        />
                                                        <span className="tracking-tight text-sm whitespace-nowrap overflow-hidden text-ellipsis">
        {getCountryDataById(answers[i].country || '')?.n || ''}
    </span>
                                                        <FontAwesomeIcon
                                                            icon={answers[i].country === answers[i].correctCountry ? faCheck : faTimes}
                                                            className={answers[i].country === answers[i].correctCountry ? 'text-green-500' : 'text-red-500'}
                                                        />
                                                    </div>

                                                )}
                                            </td>
                                            <td className="border-b border-gray-300 dark:border-gray-700 p-1 whitespace-nowrap">
                                                {answers[i]?.correctCountry && (
                                                    <div className="flex items-center justify-center space-x-1 max-w-[125px] overflow-hidden">
                                                        <img
                                                            src={getCountryDataById(answers[i].correctCountry || '')?.f || ''}
                                                            alt="Flag"
                                                            className="rounded"
                                                            style={{ width: '15px', height: 'auto', maxHeight: '15px' }}
                                                        />
                                                        <span className="tracking-tight text-sm whitespace-nowrap overflow-hidden">
        {getCountryDataById(answers[i].correctCountry || '')?.n || ''}
    </span>
                                                    </div>

                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    {!quizFinished && (
                                        <tr>
                                            <td className="border-b border-gray-300 dark:border-gray-700 items-center text-center  p-[2px]">
                                                {currentBollard ? (
                                                    <div className="flex justify-center items-center">
                                                        <img
                                                            src={process.env.PUBLIC_URL + currentBollard}
                                                            alt="Bollard"
                                                            style={{ width: '15px', height: '34px', objectFit: 'contain' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <span>{currentRound + 1}</span>
                                                )}
                                            </td>

                                            <td className="border-b  border-gray-300 dark:border-gray-700  p-2 text-green-500">{correctCounter}</td>
                                            <td className="border-b  border-gray-300 dark:border-gray-700  p-2 text-red-500">{incorrectCounter}</td>
                                            <td className="border-b border-gray-300 dark:border-gray-700 p-2 overflow-hidden text-ellipsis whitespace-nowrap"></td>
                                            <td className="border-b border-gray-300 dark:border-gray-700 p-2 overflow-hidden text-ellipsis whitespace-nowrap"></td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {quizFinished && (
                <div className="results-container min-h-[86vh] p-8 bg-white dark:bg-[#121212] text-gray-900 dark:text-gray-100 flex flex-col items-center justify-center">
                    <div className="w-full max-w-screen-lg mx-auto">
                        <div className="flex items-center justify-between mb-6">
                            <h1 className="text-3xl font-bold font-inter text-left">
                                {quizType === 'complete' ? 'Complete Quiz Results' : 'Results'}
                            </h1>
                            <div className="flex space-x-6">
                                <div className="flex items-center space-x-2 text-3xl font-bold font-inter" style={{ color: userColor }}>
                                    <FontAwesomeIcon icon={faUser} className="text-black dark:text-white" />
                                    <span>{correctAnswersCount} / {TOTAL_QUESTIONS} ({userSuccessRate.toFixed(0)}%)</span>
                                </div>
                                <div className="flex items-center space-x-2 text-3xl font-bold font-inter" style={{ color: twitchColor }}>
                                    <FontAwesomeIcon icon={faTwitch} className="text-black dark:text-white" />
                                    <span>{totalCorrectTwitch} / {totalTwitchGuesses} ({twitchSuccessRate.toFixed(0)}%)</span>
                                </div>
                                <button
                                    className="ml-1 bg-gray-100 font-inter dark:bg-gray-900 hover:bg-gray-300 dark:hover:bg-gray-700 text-black dark:text-white py-1 px-3 rounded-full"
                                    onClick={debouncedOpen}
                                >
                                    <FontAwesomeIcon icon={faCertificate} className="text-black dark:text-white mr-[5px]" />
                                    Leaderboard
                                </button>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                            {answers.slice(0, TOTAL_QUESTIONS).map((answer, index) => {
                                const isCorrect = answer.country === answer.correctCountry;
                                const correctCountryData = getCountryDataById(answer.correctCountry || '');
                                const userCountryData = getCountryDataById(answer.country || '');

                                return (
                                    <div key={index} className={`p-2 rounded-3xl border ${isCorrect ? 'bg-green-200' : 'bg-red-200'} ${isCorrect ? 'border-green-400 dark:border-green-600' : 'border-red-400 dark:border-red-600'} bg-opacity-20`}>
                                        {answer.bollard && (
                                            <img
                                                src={process.env.PUBLIC_URL + answer.bollard}
                                                alt="Bollard"
                                                className="w-full rounded-lg mb-2"
                                                style={{ maxHeight: '100px', objectFit: 'contain' }}
                                            />
                                        )}
                                        <div className="flex items-center justify-center">
                                            {correctCountryData?.f && (
                                                <img
                                                    src={correctCountryData.f}
                                                    alt="Flag"
                                                    className="mr-1 rounded"
                                                    style={{ width: '18px', height: 'auto', maxHeight: '18px' }}
                                                />
                                            )}
                                            <p className="text-center font-bold">{correctCountryData?.n}</p>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            {userCountryData?.f ? (
                                                <img
                                                    src={userCountryData.f}
                                                    alt="Flag"
                                                    className="mr-1 rounded"
                                                    style={{ width: '18px', height: 'auto', maxHeight: '18px' }}
                                                />
                                            ) : (
                                                <p className="mr-1"></p>
                                            )}
                                            <p className="text-center">
                                                {userCountryData?.n ? (
                                                    <>
                                                        {userCountryData.n} {/* Display full country name */}
                                                        {isCorrect ? <FontAwesomeIcon className="text-green-500" icon={faCheck} /> : <FontAwesomeIcon className="text-red-500" icon={faTimes} />}
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon className="text-red-500" icon={faTimes} /> -
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <FontAwesomeIcon icon={faTwitch} className="mr-1 text-green-500" />
                                            <p className="text-center">{correctAnswersPerQuestion[index]}</p>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <FontAwesomeIcon icon={faTwitch} className="mr-1 text-red-500" />
                                            <p className="text-center">{incorrectAnswersPerQuestion[index]}</p>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-[#0f172a] text-white dark:bg-white dark:text-black hover:bg-transparent hover:text-black dark:hover:bg-transparent dark:hover:text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    window.location.reload();
                                }}
                            >
                                <FontAwesomeIcon icon={faPlay} className="mr-1" /> Start New Quiz
                            </button>
                            {quizType === 'standard' ? (
                                <a
                                    href="/quiz/bollards/twitch?type=complete"
                                    className="text-black dark:text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                >
                                    <FontAwesomeIcon icon={faMap} className="mr-1" /> Start Complete Quiz
                                </a>
                            ) : (
                                <button
                                    className="text-black dark:text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full transition-colors duration-300 text-sm md:text-base border-2 border-solid border-[#0f172a] dark:border-white text-center hover:bg-[#0f172a] dark:hover:bg-white hover:text-white dark:hover:text-black"
                                    onClick={() => navigate('/quiz')}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> Explore Quiz Library
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <Dialog
                opened={dialogOpened}
                onOpenedChanged={(event) => {
                    setDialogOpened(event.detail.value);
                }}
                footerRenderer={() => (
                    <div className="flex items-right text-right  w-full">
                        <Button theme="error tertiary" onClick={debouncedClose} className="mr-auto">
                            Close
                        </Button>
                    </div>
                )}
            >
                <div className="mt-auto mb-4 flex min-w-[400px] min-h-[400px] flex-col">
                    <p className="text-bold font-inter text-xl">Chat Leaderboard</p>
                    <p className="font-inter text-md mt-1 max-w-[380px] word-wrap break-words">
                        The chat leaderboard may be inaccurate, but the overall results of the Twitch chat-integrated quiz are accurate.
                    </p>
                    <div className="mt-4 font-inter overflow-y-auto" style={{ maxHeight: '300px' }}>
                        <table className="table-auto w-full text-center border-collapse">
                            <thead>
                            <tr className="border-b border-gray-200 dark:border-gray-800">
                                <th className="p-2">Nr</th>
                                <th className="p-2">Chatter</th>
                                <th className="p-2">
                                    <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                                </th>
                                <th className="p-2">
                                    <FontAwesomeIcon icon={faTimes} className="text-red-500" />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {leaderboardData.map((data, index) => (
                                <tr key={index} className="border-b border-gray-200 dark:border-gray-800">
                                    <td className="p-2">{index + 1}</td>
                                    <td className="p-2">{data.username}</td>
                                    <td className="p-2 text-green-500">{data.correct}</td>
                                    <td className="p-2 text-red-500">{data.incorrect}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default QuizTwitchBollards;
