import React, { useEffect } from 'react';
import data from '../data.json';
import './MapDrivingSide.css';
import { clearMapElements } from '../Map';

const MapDrivingSide: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.d) {
                    const isEurope = countryData.o === 'Europe';
                    const badgeWidth = isEurope ? 20 : 40;
                    const badgeHeight = isEurope ? 10 : 20;

                    const xPosition = path.getBBox().x + path.getBBox().width / 2 - badgeWidth / 2;
                    const yPosition = path.getBBox().y + path.getBBox().height / 2 - badgeHeight / 2;

                    const badge = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                    badge.classList.add('driving-side');
                    badge.setAttribute('x', xPosition.toString());
                    badge.setAttribute('y', yPosition.toString());
                    badge.setAttribute('width', badgeWidth.toString());
                    badge.setAttribute('height', badgeHeight.toString());
                    badge.setAttribute('rx', (badgeHeight / 2).toString()); // Rounded corners
                    badge.setAttribute('ry', (badgeHeight / 2).toString()); // Rounded corners
                    badge.setAttribute('fill', countryData.d === 'r' ? 'rgba(62, 151, 255, 0.5)' : 'rgba(241, 65, 108, 0.5)'); // Color based on driving side with opacity

                    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                    text.classList.add('driving-side');
                    text.setAttribute('x', (xPosition + badgeWidth / 2).toString());
                    text.setAttribute('y', (yPosition + badgeHeight / 2 + (isEurope ? 2 : 5)).toString());
                    text.setAttribute('text-anchor', 'middle');
                    text.setAttribute('font-size', (isEurope ? 7 : 12).toString());
                    text.setAttribute('fill', countryData.d === 'r' ? '#3e97ff' : '#f1416c');
                    text.textContent = countryData.d === 'r' ? 'Right' : 'Left';

                    svg.appendChild(badge);
                    svg.appendChild(text);
                }
            });
        }
    }, []);

    return null;
};

export default MapDrivingSide;
