import React, { useEffect } from 'react';

const TermsOfService: React.FC = () => {
    useEffect(() => {
        document.title = "Terms of Service | GeographyHelp";
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8 font-inter">
                <div className="relative mx-auto max-w-[37.5rem] pt-5 text-center pb-10 font-inter">
                    <h1 className="text-4xl font-extrabold tracking-tight text-slate-900 dark:text-white sm:text-5xl">
                        Terms of Service
                    </h1>
                    <p className="mt-4 text-base leading-7 text-slate-600 dark:text-slate-400 font-inter">
                        Last updated on September 8, 2024
                    </p>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8 font-inter pb-10">
                <div className="mx-auto max-w-[40rem] prose-sm prose prose-slate dark:prose-invert prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600 font-inter">
                    <p className="font-inter">
                        By accessing or using Geography Help ("App", "Website"), you agree to be bound by the following terms and conditions ("Terms").
                        These Terms govern your use of the website, resources, quizzes, and any other features provided by Geography Help.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Use of the App</h2>
                    <p className="font-inter">
                        Geography Help offers a collection of resources, quizzes, and tools designed to help users improve their geographical knowledge.
                        While many of the images and data are publicly available, we have invested significant effort in collecting, organizing, and presenting this information in a clear and accessible way.
                        You may use the app for personal educational purposes, but redistribution or use of the data in other projects without proper credit is not permitted.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Streaming and Broadcasting</h2>
                    <p className="font-inter">
                        You are encouraged to stream Geography Help’s Twitch-integrated quizzes on platforms like Twitch, YouTube, and others.
                        Connecting your Twitch account allows you to display interactive chat guesses in real-time during the quizzes. Involve your audience, stream quizzes, and enjoy the interactive features.
                        While streaming, please give appropriate credit to Geography Help and do not repurpose the app’s structure or resources for commercial use without permission.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Contributing and Reporting Data Issues</h2>
                    <p className="font-inter">
                        The most important aspect of Geography Help is the accuracy and comprehensiveness of the data. We encourage users to help improve the app by reporting any incorrect data or contributing new information.
                        You can report errors or suggest new features directly within the app through dedicated buttons.
                    </p>
                    <p className="font-inter">
                        Additionally, we invite users to join our Discord community to stay updated, contribute ideas, and help make this project more accurate and better over time.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Respecting the Effort</h2>
                    <p className="font-inter">
                        The content on Geography Help, including the organization of quizzes, data tables, and other materials, represents significant time and effort.
                        You are free to use the resources for personal learning, but please do not copy or repurpose the app’s structure, quizzes, or resources for public or commercial use without acknowledging the work behind it.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Personal Use License</h2>
                    <p className="font-inter">
                        Geography Help grants you a limited, non-transferable, non-exclusive license to access and use the app solely for personal use. You may use the resources for educational purposes, but redistribution or publication of quizzes, data sets, or resources is prohibited without permission.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Prohibited Activities</h2>
                    <p className="font-inter">
                        You may not reverse-engineer, decompile, or attempt to extract the source code of the app. You are not allowed to use the app’s structured content (such as quizzes or resource collections) in other projects, apps, or services without credit.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Disclaimer of Warranties</h2>
                    <p className="font-inter">
                        Geography Help is provided "as is" and "as available." We do not guarantee that the app will be error-free or that all data is completely accurate. We reserve the right to modify, suspend, or discontinue any feature without notice.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Limitation of Liability</h2>
                    <p className="font-inter">
                        Geography Help shall not be held liable for any damages resulting from the use or inability to use the app, including but not limited to direct, indirect, incidental, or consequential damages.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Modifications to the Terms</h2>
                    <p className="font-inter">
                        We reserve the right to modify these Terms at any time. Continued use of the app after any changes indicates your acceptance of the updated Terms.
                    </p>

                    <h2 className="mb-1 mt-5 text-xl font-bold font-inter">Contact Us</h2>
                    <p className="font-inter">
                        If you have any questions about these Terms, please contact us at info@geography.help.
                    </p>
                </div>
            </div>
        </>
    );
};

export default TermsOfService;
