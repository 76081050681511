import React, { useEffect } from 'react';
import data from '../data.json';
import './MapSpecialLetters.css';
import {clearMapElements} from "../Map";

const MapSpecialLetters: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();


            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();
                const countryData = data.find(country => country.i.toUpperCase() === countryId);
                if (countryData && countryData.sl && countryData.sl.length > 0) {
                    const isEurope = countryData.o === 'Europe';
                    const fontSize = isEurope ? 5 : 10;
                    const lineLimit = 5;
                    const lineHeight = fontSize * 1.1;
                    const letterSpacing = -0.5;

                    const xPosition = path.getBBox().x + path.getBBox().width / 2;
                    const yPosition = path.getBBox().y + path.getBBox().height / 2;

                    let textContent = '';
                    for (let i = 0; i < countryData.sl.length; i++) {
                        if (i > 0 && i % lineLimit === 0) {
                            textContent += '\n';
                        }
                        textContent += countryData.sl[i];
                        if (i < countryData.sl.length - 1 && (i + 1) % lineLimit !== 0) {
                            textContent += ' ';
                        }
                    }

                    const lines = textContent.split('\n');
                    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                    text.classList.add('special-letter');
                    text.setAttribute('x', xPosition.toString());
                    text.setAttribute('y', (yPosition - (lines.length - 1) * lineHeight / 2).toString());
                    text.setAttribute('text-anchor', 'middle');
                    text.setAttribute('font-size', fontSize.toString());
                    text.setAttribute('fill', 'red');
                    text.style.fontFamily = 'Inter, sans-serif';
                    text.style.letterSpacing = `${letterSpacing}px`;

                    lines.forEach((line, index) => {
                        const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
                        tspan.setAttribute('x', xPosition.toString());
                        tspan.setAttribute('dy', index === 0 ? '0' : lineHeight.toString());
                        tspan.textContent = line;
                        text.appendChild(tspan);
                    });

                    svg.appendChild(text);
                }
            });
        }
    }, []);

    return null;
};

export default MapSpecialLetters;
