import React, { useEffect } from 'react';
import languageExamples from '../le.json';
import data from '../data.json';
import './MapLanguageUseExamples.css';
import { clearMapElements } from '../Map';

const MapLanguageUseExamples: React.FC = () => {
    useEffect(() => {
        const svg = document.getElementById('world-map');
        if (svg) {
            clearMapElements();

            const paths = svg.getElementsByTagName('path');
            Array.from(paths).forEach(path => {
                const countryId = path.id.toUpperCase();

                // Get language example from le.json
                const countryDataLE = languageExamples.find(country => country.i.toUpperCase() === countryId);

                const countryDataContinent = data.find(country => country.i.toUpperCase() === countryId);

                if (countryDataLE && countryDataLE.le && countryDataContinent) {
                    const isEurope = countryDataContinent.o === 'Europe';

                    // Set smaller dimensions for European countries
                    const rectWidth = isEurope ? 40 : 72;
                    const rectHeight = isEurope ? 18 : 34;
                    const fontSize = isEurope ? 6 : 10;

                    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
                    const div = document.createElement('div');

                    const rectX = path.getBBox().x + path.getBBox().width / 2 - rectWidth / 2;
                    const rectY = path.getBBox().y + path.getBBox().height / 2 - rectHeight / 2;

                    rect.classList.add('language-example-rect');
                    rect.setAttribute('x', rectX.toString());
                    rect.setAttribute('y', rectY.toString());
                    rect.setAttribute('width', rectWidth.toString());
                    rect.setAttribute('height', rectHeight.toString());

                    // Set rx and ry for rounded-md instead of rounded-full
                    rect.setAttribute('rx', '4');  // moderate corner rounding
                    rect.setAttribute('ry', '4');  // moderate corner rounding
                    rect.setAttribute('fill', '#ce6241');

                    foreignObject.setAttribute('x', rectX.toString());
                    foreignObject.setAttribute('y', rectY.toString());
                    foreignObject.setAttribute('width', rectWidth.toString());
                    foreignObject.setAttribute('height', rectHeight.toString());
                    foreignObject.classList.add('language-example-foreignObject');

                    div.style.display = 'flex';
                    div.style.alignItems = 'center';
                    div.style.justifyContent = 'center';
                    div.style.width = '100%';
                    div.style.height = '100%';
                    div.style.color = '#ffffff';
                    div.style.fontSize = `${fontSize}px`;
                    div.style.fontFamily = 'Arial, sans-serif';
                    div.style.textAlign = 'center';
                    div.style.overflow = 'hidden';
                    div.style.whiteSpace = 'pre-wrap';
                    div.style.lineHeight = '1';
                    div.classList.add('language-example-div');

                    div.textContent = countryDataLE.le;

                    foreignObject.appendChild(div);
                    svg.appendChild(rect);
                    svg.appendChild(foreignObject);
                }
            });
        }
    }, []);

    return null;
};

export default MapLanguageUseExamples;
