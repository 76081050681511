import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

// Twitch Context Definition
interface TwitchContextProps {
    twitchUser: string | null;
    setTwitchUser: (user: string | null) => void;
    disconnectTwitch: () => void;
    twitchConnected: boolean;
    setTwitchConnected: (connected: boolean) => void;
    setLoggingIn: (logging: boolean) => void;
}

export const TwitchContext = createContext<TwitchContextProps>({
    twitchUser: null,
    setTwitchUser: () => {},
    disconnectTwitch: () => {},
    twitchConnected: false,
    setTwitchConnected: () => {},
    setLoggingIn: () => {},
});

export const TwitchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [twitchUser, setTwitchUser] = useState<string | null>(null);
    const [twitchConnected, setTwitchConnected] = useState<boolean>(false);
    const [loggingIn, setLoggingIn] = useState<boolean>(false);

    useEffect(() => {
        const storedUser = localStorage.getItem('twitchUser');
        if (storedUser) {
            setTwitchUser(storedUser);
            setTwitchConnected(true);
        }
    }, []);

    const disconnectTwitch = () => {
        setTwitchUser(null);
        localStorage.removeItem('twitchUser');
        localStorage.removeItem('twitchAccessToken');
        setTwitchConnected(false);
    };

    return (
        <TwitchContext.Provider value={{ twitchUser, setTwitchUser, disconnectTwitch, twitchConnected, setTwitchConnected, setLoggingIn }}>
            {children}
        </TwitchContext.Provider>
    );
};
// TwitchCallback Component for Implicit Flow
export const TwitchCallback: React.FC = () => {
    const navigate = useNavigate();
    const { setTwitchUser, setTwitchConnected, setLoggingIn } = useContext(TwitchContext);

    useEffect(() => {
        // Extract the access token from the URL fragment
        const hashParams = new URLSearchParams(window.location.hash.replace('#', ''));
        const accessToken = hashParams.get('access_token');

        if (accessToken) {
            setLoggingIn(true);
            localStorage.setItem('twitchAccessToken', accessToken);
            fetch('https://id.twitch.tv/oauth2/userinfo', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => res.json())
                .then((userData) => {
                    localStorage.setItem('twitchUser', userData.preferred_username);
                    setTwitchUser(userData.preferred_username);
                    setTwitchConnected(true);
                    setLoggingIn(false);

                    // Get the redirect path from localStorage
                    const redirectTo = localStorage.getItem('twitchRedirect') || '/';
                    localStorage.removeItem('twitchRedirect'); // Clean up
                    navigate(redirectTo); // Redirect to the saved path
                })
                .catch((error) => {
                    console.error('Error during authentication:', error);
                    setLoggingIn(false);
                    navigate('/login-error');
                });
        }
    }, [navigate, setTwitchUser, setTwitchConnected, setLoggingIn]);

    return null;
};


export const TwitchUserPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const { twitchUser, disconnectTwitch } = useContext(TwitchContext);

    return (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg z-50">
            <div className="p-4 text-sm">
                <p>Logged in as:</p>
                <p className="font-bold">{twitchUser}</p>
                <button
                    className="mt-2 text-red-600 dark:text-red-400 flex items-center"
                    onClick={() => {
                        disconnectTwitch();
                        onClose();
                    }}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    Disconnect
                </button>
            </div>
        </div>
    );
};
